<template>
  <div>
    <v-menu v-model="showNotifications" offset-y left rounded="xl" class="notification-menu" nudge-bottom="10" max-width="350px">
      <template v-slot:activator="{ on }">
        <button @click="toggleNotifications" class="notification-button cursor-pointer">
          <v-badge v-if="unreadCount > 0" :content="unreadCount" color="red" overlap>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else>mdi-bell</v-icon>
        </button>
      </template>
      <div class="fixed-buttons-container">
        <v-tabs grow>
          <v-tab :disabled="unreadCount === 0" @click.stop="filterNotifications('unread')">
            Unread
          </v-tab>
          <v-tab @click.stop="filterNotifications('read')">
            Read
          </v-tab>
        </v-tabs>
      </div>
      <v-list class="notification-list" @click.stop>
        <div v-if="sortedNotifications.length === 0" class="text-center pa-4">
          No notifications to show
        </div>
        <v-list-item v-for="notification in sortedNotifications" :key="notification.id"
          :class="{ unread: notification.status === 'unread' }" @click.stop>
          <v-list-item-content @click="markAsRead(notification.id, notification.url)"
            :class="{ 'cursor-pointer': notification.status === 'unread' }">
            <v-list-item-title>{{ notification.title }}</v-list-item-title>
            <v-list-item-subtitle class="message" :class="{ expanded: isExpanded(notification.id) }">
              <div class="message-content">
                {{ notification.message }}
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="date">{{
              formatRelativeTime(notification.date)
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action @click="toggleExpand(notification.id)">
            <v-icon>{{ isExpanded(notification.id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div class="fixed-bottom-button">
        <v-btn v-if="unreadCount > 0 && filter === 'unread'" @click.stop="markAllAsRead" class="mark-all-button">
          Mark All as Read
        </v-btn>
      </div>
    </v-menu>

    <!-- Temporary Notifications -->
    <transition-group name="fade" tag="div" class="temporary-notifications">
      <div v-for="notification in tempNotifications" :key="notification.id" class="temp-notification">
        <v-alert type="info" dismissible>{{ notification.message }}</v-alert>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { db, messaging, vapidKey } from "@/utils/firebase.utils";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { formatDistanceToNow } from "date-fns";
import { getToken } from "firebase/messaging";

export default {
  data() {
    return {
      showNotifications: false,
      notifications: [],
      tempNotifications: [], // Array for temporary notifications
      filter: "unread",
      lastNotificationCount: 0, // To track the last notification count
      expandedNotifications: [], // Track expanded notifications
    };
  },
  mounted() {
    this.fetchNotifications();
  },
  computed: {
    unreadCount() {
      return this.notifications.filter(
        (notification) => notification.status === "unread"
      ).length;
    },
    readCount() {
      return this.notifications.filter(
        (notification) => notification.status === "read"
      ).length;
    },
    hasBothTypes() {
      return this.unreadCount > 0 && this.readCount > 0;
    },
    sortedNotifications() {
      return this.notifications.filter((notification) =>
        this.unreadCount === 0 ? true : notification.status === this.filter
      ).sort((a, b) => new Date(b.date) - new Date(a.date));
    },
  },
  methods: {
    async requestPermission() {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        try {
          const currentToken = await getToken(messaging, { vapidKey });
          if (currentToken) {
            console.log("FCM Token:", currentToken);
            await this.saveTokenToFirestore(currentToken);
          } else {
            console.log("No registration token available. Request permission to generate one.");
          }
        } catch (err) {
          console.log("Unable to get token ", err);
        }
      } else {
        console.log("Unable to get permission to notify.");
      }
    },
    async saveTokenToFirestore(token) {
      const uid = this.currentOriginalUID || this.uid;
      const tokenRef = doc(db, `users/${uid}/deviceTokens`, token);
      await setDoc(tokenRef, {
        token: token,
        date: new Date(),
      });
    },
    fetchNotifications() {
      this.requestPermission();
      const notificationsRef = collection(
        db,
        "users",
        this.currentOriginalUID || this.uid,
        "notifications"
      );
      onSnapshot(notificationsRef, (snapshot) => {
        const newNotifications = snapshot.docs.map((doc) => {
          const data = doc.data();
          if (data.date && data.date.seconds) {
            data.date = new Date(data.date.seconds * 1000);
          } else if (data.date) {
            data.date = new Date(data.date);
          }
          return {
            id: doc.id,
            ...data,
          };
        });

        this.notifications = newNotifications;

        const newUnreadNotifications = newNotifications.filter(
          (notification) => notification.status === "unread"
        );

        if (newUnreadNotifications.length > this.lastNotificationCount) {
          const latestNotification = this.sortedNotifications[0];
          this.addTemporaryNotification(latestNotification);
        }

        this.lastNotificationCount = newUnreadNotifications.length;
      });
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
    },
    markAsRead(notificationId, url) {
      const notificationRef = doc(
        db,
        `users/${this.currentOriginalUID || this.uid}/notifications/${notificationId}`
      );
      updateDoc(notificationRef, {
        status: "read",
      }).then(() => {
        if (url) {
          window.location.href = url;
        }
      });
    },
    filterNotifications(status) {
      this.filter = status;
    },
    formatRelativeTime(date) {
      if (!date || isNaN(date)) {
        return "Invalid date";
      }
      return formatDistanceToNow(new Date(date), { addSuffix: true });
    },
    addTemporaryNotification(notification) {
      this.tempNotifications = [notification];
      setTimeout(() => {
        this.tempNotifications.shift();
      }, 3000);
    },
    markAllAsRead() {
      const unreadNotifications = this.notifications.filter(
        (notification) => notification.status === "unread"
      );
      unreadNotifications.forEach((notification) => {
        this.markAsRead(notification.id);
      });
    },
    toggleExpand(notificationId) {
      if (this.isExpanded(notificationId)) {
        this.expandedNotifications = this.expandedNotifications.filter(id => id !== notificationId);
      } else {
        this.expandedNotifications.push(notificationId);
      }
    },
    isExpanded(notificationId) {
      return this.expandedNotifications.includes(notificationId);
    },
  },
};
</script>

<style scoped>
.notification-button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
}

.notification-button .v-icon {
  font-size: 24px;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 5px;
  border-radius: 50%;
  min-width: 20px;
  text-align: center;
}

.notification-card {
  position: absolute;
  top: 100%;
  left: 0;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  overflow-y: auto;
}

.notification-list {
  list-style-type: none;
  padding: 0;
  max-height: 24rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.notification-list li {
  margin-bottom: 10px;
}

.unread {
  font-weight: bold;
}

.fixed-buttons-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.filter-buttons-container {
  top: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.filter-button {
  flex: 1;
  max-width: 100px;
  margin: 0 0.5rem;
}

.text-center {
  text-align: center;
}

.pa-4 {
  padding: 16px;
}

.date {
  font-size: 10px;
  color: gray;
}

.message {
  margin-top: 8px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: max-height 0.3s ease-in-out;
}

.message.expanded {
  max-height: none;
  white-space: normal;
}

.message-content {
  word-break: break-word;
}

.temporary-notifications {
  position: fixed;
  margin-top: 1rem;
  right: 20px;
  background: rgba(139, 92, 246, var(0, 0, 0, 0.1)) !important;
  z-index: 1000;
}

.temp-notification {
  color: white;
  padding: 0.3rem;
  border-radius: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fixed-bottom-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1;
  padding: 1rem;
}
</style>
