<template>
  <div style="width: 50%">
    <v-dialog
      persistent
      v-model="dialog"
      :max-width="isMobile ? '80%' : '60%'"
      :fullscreen="isMobile"
      max-height
      scrollable
      v-if="dialog"
    >
      <dialog-card :isSubmit="false" @close="dialog = false" :closable="false">
        <div slot="title" style="width: 100%">
          <div class="d-flex justify-space-between" style="width: 100%">
            <div class="d-flex flex-column flex-md-row flex-1">
              <div class="text-center text-body-1 d-flex flex-1">
                <span class="d-none d-md-block mt-2 mr-2"> Aspect Ratio: </span>
                <v-btn-toggle
                  @change="changeAspectRatio"
                  v-model="aspectRatio"
                  class="btn-group"
                  mandatory
                  active-class="primary"
                >
                  <v-btn
                    :value="item.value"
                    :key="index"
                    height="40px"
                    v-for="(item, index) in aspectRatios"
                  >
                    <v-icon
                      :class="{ 'white--text': item.value == aspectRatio }"
                      >{{ item.icon }}</v-icon
                    >
                  </v-btn>
                </v-btn-toggle>
              </div>
              <div
                class="text-body-1 d-flex flex-1 align-md-center flex-column flex-md-row mt-2 mt-md-0"
              >
                <div class="text-body-1 pt-md-1">Image Fit:</div>
                <radio-group
                  v-model="fit"
                  class="ml-1"
                  hide-details
                  :row="true"
                  @input="initImage()"
                  :items="fitOptions"
                />
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn
                outlined
                :icon="isMobile"
                class="error white--text mr-3"
                @click="clear()"
                :disabled="loading"
              >
                <template v-if="!isMobile"> Cancel</template>
                <v-icon v-else> mdi-close </v-icon>
              </v-btn>
              <v-btn
                outlined
                :icon="isMobile"
                class="primary white--text"
                @click="next()"
                :disabled="loading"
              >
                <template v-if="!isMobile"> Done </template>
                <v-icon v-else> mdi-check </v-icon>
              </v-btn>
            </div>
          </div>
          <div
            class="text-caption grey--text d-none d-md-block text-center my-2"
          >
            All images must be in same aspect ratio
          </div>
        </div>
        <please-wait v-if="isLoading" class="mb-5"></please-wait>
        <v-row v-else>
          <v-col cols="12" md="4" v-for="(image, i) of outputs" :key="i"
            >
            <v-img :src="image.transformedUrl">
              <template v-slot:placeholder>
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"

                ></v-progress-circular>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </dialog-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogCard from "../ui/DialogCard.vue";
import "@/components/pintura/pintura-image/pintura.css";
import {
  getEditorDefaults,
  colorStringToColorArray,
} from "@/components/pintura/pintura-image/pintura";
import { colGallery, storage } from "@/utils/firebase.utils";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { getDate } from "@/utils/common.utils";
import { doc, updateDoc } from "firebase/firestore";
import {
  compressImage,
  MAX_IMAGE_SIZE,
  OVERRIDE_MAX_IMAGE_SIZE,
  compressOverrideImage,
} from "@/utils/image.utils";
import PleaseWait from "../ui/PleaseWait.vue";
import RadioGroup from "../ui/form/RadioGroup.vue";
import { dataURLToBlob } from "../../utils/image.utils";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: { DialogCard, PleaseWait, RadioGroup },
  data() {
    return {
      isLoading: false,
      dialog: false,
      outputs: [],
      overrideOutput: [],
      list: [],
      override_list: [],
      loading: false,
      editorProps: {
        ...getEditorDefaults(),
        utils: ["crop"],
        enableToolbar: false,
        cropActiveTransformTool: "zoom",
      },
      aspectRatio: {
        width: 1,
        height: 1,
      },
      fit: "fill",
      fitOptions: [
        {
          name: "Contain",
          value: "fit",
        },
        {
          name: "Cover",
          value: "fill",
        },
      ],
      currentIndex: 0,
      aspectRatios: [
        {
          title: "Square",
          value: {
            width: 1,
            height: 1,
          },
          icon: "mdi-crop-square",
        },
        {
          title: "Portrait",
          value: {
            width: 3,
            height: 4,
          },
          icon: "mdi-crop-portrait",
        },
        {
          title: "Landscape",
          value: {
            width: 4,
            height: 3,
          },
          icon: "mdi-crop-landscape",
        },
      ],
      changes: {},
    };
  },
  computed: {
    editerStyle() {
      let height = window.innerHeight - 90 + "px";
      return { height };
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.initImage();
      }
    },
  },
  methods: {
    changeAspectRatio(value) {
      this.aspectRatio = value;
      this.$set(this.aspectRatio, value);
      this.initImage();
    },
    cleanImageUrl(url) {
      const baseRegex = /upload\/(?:.*?\/)?(users\/.+)/;
      const cleanMatch = url.match(baseRegex);
      return cleanMatch
        ? `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${cleanMatch[1]}`
        : url;
    },
    async initImage() {
      this.isLoading = true;
      this.outputs = [];
      try {
        // console.log('his.items:', this.items)
        // Using Promise.all to handle all transformations concurrently
        const cleanedItems = this.items.map((item) => this.cleanImageUrl(item));
        const transformPromises = cleanedItems.map((item) =>
          this.transformImage(item, false)
        );
        const overrideTransformPromises = cleanedItems.map((item) =>
          this.transformImage(item, true)
        );

        // Resolving all transformation promises concurrently
        const results = await Promise.all(transformPromises);
        const overrideResults = await Promise.all(overrideTransformPromises);
        // results.forEach((result, index) => {
        // if (this.outputs[index]) { // Check if the output exists
        //   this.outputs[index].transformedUrl = result.url;
        //   this.outputs[index].loading = false; // Stop loading this specific image
        // } });
        this.outputs = results.map((url, index) => ({
          transformedUrl: url,
        }));
        this.overrideOutputs = overrideResults.map((url, index) => ({
          transformedUrl: url,
        }));
        this.list = this.outputs.map((item) => item.transformedUrl);
        this.override_list = this.overrideOutputs.map(
          (item) => item.transformedUrl
        );
      } catch (error) {
        console.error("Error processing images:", error);
      } finally {
        this.isLoading = false;
      }
    },

    // Newly added method to handle the transformation logic
    async transformImage(url, overrideQuality, index) {
      return new Promise((resolve, reject) => {
        try {
          const transformedUrl = this.transformImageUrl(url, overrideQuality);
          resolve(transformedUrl);
        } catch (error) {
          reject(error);
        }
      });
    },

    transformImageUrl(url, overrideQuality = false) {
      // console.log(" url:", url);
      this.isLoading = true;
      const formattedAspectRatio = `${this.aspectRatio.width}:${this.aspectRatio.height}`;
      const cloudinaryBaseUrl = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/`;
      let transformations =
        this.fit === "fit"
          ? `ar_${formattedAspectRatio},c_pad,b_auto`
          : `ar_${formattedAspectRatio},c_${this.fit}`;
      if (overrideQuality) {
        transformations += ",q_100";
      }
      transformations += ",w_auto:320:1200";
      this.isLoading = false;
      return `${cloudinaryBaseUrl}${transformations}/${this.extractPublicId(
        url
      )}`;
    },

    extractPublicId(url) {
      // console.log("ext url:", url);
      const regex = /upload\/(?:v\d+\/)?(.+)(\.\w+)$/;
      const match = url.match(regex);
      // console.log("match:", match);
      return match ? match[1] + (match[2] ? match[2] : "") : "";
      // return match ? match[1] : ""; // Extract the full path without the extension
    },

    clear() {
      const urls = this.outputs.map(output => output.transformedUrl);
      this.$confirm.show({
        message: "Are you sure you want to discard all the changes?",
        onConfirm: () => {
          this.currentIndex = 0;
          this.changes = {};
          this.dialog = false;
          this.$emit("discard", urls);
        },
      });
    },
    async next() {
      try {
        this.loading = true;
        this.$loader.show();
        console.log("list loength:", this.list.length);
        this.$emit("onImageCropped", this.list);
        // console.log("override_list:", this.override_list);
        this.$emit("overrideImagesCropped", this.override_list);

      } catch (error) {
        this.$loader.hide();
        this.handleError(error);
      } finally {
        this.loading = false;
        this.dialog = false;
        this.$loader.hide();
      }
    },

  },
  mounted() {
    // this.dialog = true;
  },
};
</script>

<style>
.btn-group .v-btn {
  border-radius: unset !important;
}
.v-image__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
