<template>
  <div></div>
</template>

<script>
// Import the necessary dependencies
import { eventBus, getDate } from "@/utils/common.utils";
import { colGallery, storage } from "@/utils/firebase.utils";
import "firebase/firestore";
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";

import { MAX_IMAGE_SIZE } from "@/utils/image.utils";


export const filterMentions = async (searchText, uid, wid, platform) => {
  // console.log("in filterMentions", searchText, uid, wid);
  let data = {
    usernames: searchText,
    uid: uid,
    wid: wid,
    platform: platform,
  };
  // console.log('data:', data)
  if (searchText) {
    let searchFollowers = httpsCallable(functions, "fetchFollowerss");
    const response = await searchFollowers(data);

    eventBus.$emit("mentionDataLoading", true);
    const mentionsPromises = response.data?.result.map(async (res) => {
      let url = res.profile_pic;
      let data;
      let image;
      if (platform != "twitter") {
        let corsImageProxy = httpsCallable(functions, "corsImageProxy");
        data = { url };
        let rr = await corsImageProxy(data);
        image = rr.data.data || "";
      }

      if (image) {
        let blob = await fetch(image).then((res) => res.blob());
        if (blob.size > MAX_IMAGE_SIZE) {
          let { base64, size } = await this.compressImage(image);
          image = base64;
        }
        let id = new Date().getTime().toString();
        let imgData = {
          created_at: getDate(),
          updated_at: getDate(),
          type: "image",
          size: blob.size,
          uid,
          name: uid + "/" + id,
          json: {},
        };
        let imageRef = ref(
          storage,
          `gallery/${uid}/${platform}_search/${id}.png`
        );
        await uploadString(imageRef, image, "data_url");
        imgData.url = await getDownloadURL(imageRef);
        imgData.thumbnail = imgData.url;
        await setDoc(doc(colGallery, id), imgData);
        image = imgData.url;
      }

      return {
        avatar: image || url,
        name: res.follower,
      };
    });

    const mentionData = await Promise.all(mentionsPromises);
    eventBus.$emit("mentionDataLoading", false);
    // console.log('mentionData::', mentionData);
    return mentionData;
  }
};
// Define the mention data array
export default {
  props: {
    searchText: String,
  },
  created() {
    eventBus.$on("mentionsSearchText", this.filterMentions);
  },
};
</script>

<style scoped></style>
