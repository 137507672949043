<template>
  <div
    class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
  >
    <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
      <div class="w-full pb-20 bg-[#F2F6FA] h-full">
        <div class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
  <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex w-full">
    <div class="flex items-center gap-2">
      <img src="@/assets/img/content_v.svg" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
      <p class="text-base md:text-[22px] font-bold flex-grow">
        Content Insights
      </p>
    </div>
    <!-- Refresh Icon aligned to the right -->
    <div>
    <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/refresh--v1.png" alt="refresh--v1" class="cursor-pointer"  @click="refreshInsights"/>
 
  </div>
  </div>
</div>

<div class="flex items-center justify-start mb-5 ml-[38px] md:mb-0 px-2 md:px-0 w-full xl:w-[1285px]">
  <div class="flex items-center justify-between w-full">
    <div class="items-center gap-2 px-4 -ml-2">
      <p class="text-xs md:text-[18px] text-gray-600">
        In-depth analytics of your content across channels
      </p>
    </div>
    <!-- New div for the 'Last Refreshed At' text -->
    <div class="text-xs md:text-[17px] text-gray-600 mr-10">
      Last Refreshed at : {{ displayRelativeTime() }}
    </div>
  </div>
</div>

        <div class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl">
        <div class="flex flex-col items-center justify-center">
          <div
            class="bg-gray-50 border-b border-gray-200 sticky top-0 z-20 px-8 mb-2 mt-4 flex gap-[580px] justify-between max-w-[1400px] w-[98%]"
          >
            <!-- Dropdown for profiles -->
            <div class="relative flex items-center gap-2 grow">
              <button
                @click="toggleProfileDropdown($event)"
                class="flex items-center gap-2 p-2 border border-gray-300 rounded"
              >
                All Profiles
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <div
                ref="profileDropdown"
                v-if="showProfileDropdown"
                class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 max-w-[800px] overflow-y-auto px-5"
              >
                <div class="relative">
                  <button
                    @click="applyProfiles"
                    class="absolute font-semibold right-1 mt-2 p-1 bg-blue-500 text-white rounded text-xs"
                  >
                    Apply
                  </button>
                </div>
                <div
                  v-for="profile in allProfiles"
                  :key="profile.platform"
                  class="platform p-2"
                >
                  <div class="flex items-center gap-2">
                    <input
                      type="checkbox"
                      v-model="profile.checked"
                      @change="logChange(profile)"
                    />
                    <img :src="profile.logo_url" alt="Logo" class="w-5 h-5" />
                    <span class="font-medium">{{
                      capitalizeFirstLetter(profile.platform)
                    }}</span>
                  </div>
                  <ul class="pl-6 mt-1">
  <li
    v-for="p in profile.profiles"
    :key="p.id"
    class="flex items-center gap-2 relative group justify-between"
  >
    <div class="flex items-center gap-2">
      <img
        v-if="p.picture"
        :src="p.picture"
        alt="Logo"
        class="w-5 h-5 rounded-full"
      />
      <img
        v-else
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi1p8_WaFcY_wt5qn3Vd0_VpkTtyo9AFeSB0PT3iVz1A&s"
        alt="profile"
        class="w-5 h-5"
      />
      <input
        type="checkbox"
        v-model="p.checked"
        @change="logSubProfileChange(profile, p)"
      />
      <span class="truncate">{{ p.name }}</span>
    </div>
    <a
      href="#"
      class="text-blue-500 text-xs opacity-0 group-hover:opacity-100 transition-opacity ml-auto"
      @click.prevent="retainOnlyThisProfile(profile, p)"
    >
      Only
    </a>
  </li>
</ul>


                </div>
              </div>
            </div>
            <!-- Date range picker -->
            <div class="ml-auto flex items-center gap-2 relative">
              <v-select
                :items="dateRanges"
                v-model="selectedDateRange"
                prepend-inner-icon="mdi-calendar"
                hide-details
                @change="selectDateRange"
                class="max-w-[200px] mb-4"
              ></v-select>
            </div>
          </div>
          <PlatformStats :stats="aggregatedResult" :loading="isLoading" />
          <TotalMetrics :metricsData="platformprofile" :loading.sync="isLoading" />
          <FetchPosts :postData="platformposts" />
        </div>
    </div>
      </div>
    </section>
    <div v-if="!dismissed" class="onboarding-popup">
      <div @click="toggleOnboardingVisibility" class="flex items-center justify-between cursor-pointer">
        <h2 class="font-bold">Onboarding Steps</h2>
        <v-icon :name="isOnboardingVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="white"></v-icon>
        <v-icon v-if="isOnboardingVisible" color="white">mdi-chevron-down</v-icon>
        <v-icon color="white" v-if="!isOnboardingVisible">mdi-chevron-up</v-icon>
      </div>
      <div v-if="isOnboardingVisible">
        <div class="progress-text">{{ completedSteps }} of {{ totalSteps }} steps completed</div>
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="{ width: progressPercentage + '%' }"></div>
        </div>
        <div v-for="(item, index) in onboardingSteps" :key="index" class="checkbox-item flex justify-between">
          <input type="checkbox" :id="`step-${index}`" class="custom-checkbox" v-model="item.completed" />
          <label :for="`step-${index}`">
            <router-link :to="item.route" :style="{ color: 'white' }" :class="{ strikethrough: item.completed }">{{ item.name }}</router-link>
          </label>
          <router-link :to="item.route">
    <v-icon color="white cursor-pointer">mdi-chevron-right</v-icon>
  </router-link>
        </div>
        <div class="text-center bg-slate-800 mt-2 p-2 rounded-sm text-xs">
          <a href="https://calendly.com/fly-social/30min" target="_blank" class="need-help-link">Need help? Connect with a member of our team</a>
        </div>
        <button @click="dismissOnboarding" class="dismiss-button text-sm">Dismiss</button>
      </div>
    </div>
  </div>
</template>

<script>
import ToolTip from "../../components/ui/ToolTip.vue";
import VideoAutoPlay from "../../components/public/VideoAutoPlay.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";

import { auth, db, colUsers } from "@/utils/firebase.utils";
import { onSnapshot, doc, updateDoc, setDoc } from "firebase/firestore";
import { trackGTM } from "../../utils/common.utils";
import PlatformStats from "../fly/PlatformStats.vue";
import TotalMetrics from "../fly/TotalMetrics.vue";
import FetchPosts from "../fly/FetchPosts.vue";
import { collection, getDocs, getDoc} from "firebase/firestore";

export default {
  components: {
    TotalMetrics,
    FetchPosts,
    PlatformStats,
    VideoAutoPlay,
    ToolTip,
  },
  data() {
    return {
      platformposts: [],
      platformStats: [],
      platformprofile: [],
      aggregatedResult: {},
      platforms: [],
      isLoading: true,
      allProfiles: [],
      lastRefreshed: '',
      showProfileDropdown: false,
      showDateDropdown: false,
      selectedProfile: null,
      selectedDateRange: "Last 28 days",
      dateRanges: ["Last 7 days", "Last 28 days", "Last 60 days"],
      publishedPosts: Math.floor(Math.random() * 100),
      totalEngagement: Math.floor(Math.random() * 500),
      totalReactions: Math.floor(Math.random() * 500),
      totalComments: Math.floor(Math.random() * 100),
      totalShares: Math.floor(Math.random() * 200),
      selectedView: localStorage.getItem("selectedView") || "grid",
      viewOptions: [
        { text: "Grid View", value: "grid" },
        { text: "List View", value: "list" },
      ],
   
      metrics: [],
      processedData: [],
      recentPosts: [],
      isLoading: false,
      recentPostsNodata: false,
      connecTedApps: false,
      isOnboardingVisible: true,
      dismissed: true,
      onboardingSteps: [
        // {
        //   name: "Complete  profile details",
        //   completed: false,
        //   route: "/edit-profile",
        //   key: "is_profile_completed",
        // },
        {
          name: "Connect your social handles",
          completed: false,
          route: "/connected-apps",
          key: "is_connected",
        },
        {
          name: "Publish your first post",
          completed: false,
          route: "/create",
          key: "is_posted",
        },
        {
          name: "Generate content ideas",
          completed: false,
          route: "/content-ai",
          key: "is_content_ai_generated",
        },
        // {
        //   name: "Generate content ideas using Fly",
        //   completed: false,
        //   route: "/create",
        //   key: "is_idea_generated",
        // },
      ],
    };
  },
  watch: {
    onboardingSteps: {
      handler(newSteps) {
        this.updateOnboardingStepsInFirestore(newSteps);
      },
      deep: true,
    },
    allProfiles: {
      handler(newVal) {
        const checkedProfiles = newVal.filter(
          (profile) =>
            profile.checked && profile.profiles.some((p) => p.checked)
        );
        if (checkedProfiles.length > 0) {
          this.isLoading = true; 
          this.fetchAllStats(checkedProfiles);
          this.fetchPlatformMetrics(checkedProfiles);
          this.fetchPosts(checkedProfiles); // Trigger fetchPosts with checked profiles
        } else {
          this.isLoading = false;
          console.log("No profiles are checked.");
          this.aggregatedResult = {}; // Reset or handle data when no profiles are checked
          this.platformStats = [];
          this.platformposts = []; // Reset posts data
        }
      },
      deep: true, // Ensure deep watching for nested changes
      immediate: false, // Do not trigger on initialization
    },
    // Other watchers...
  },
  computed: {
    completedSteps() {
      return this.onboardingSteps.filter((step) => step.completed).length;
    },
    totalSteps() {
      return this.onboardingSteps.length;
    },
    progressPercentage() {
      return (this.completedSteps / this.totalSteps) * 100;
    },
    dateRange() {
      let endDate = new Date();
      let startDate = new Date();

      switch (this.selectedDateRange) {
        case "Last 7 days":
          startDate.setDate(endDate.getDate() - 7);
          break;
        case "Last 28 days":
          startDate.setDate(endDate.getDate() - 28);
          break;
        case "Last 60 days":
          startDate.setDate(endDate.getDate() - 60);
          break;
      }

      return { startDate, endDate };
    },
  },
  methods: {
    fetchLastRefreshed() {
    const uid = this.currentUID;
    const wid = this.WID;
    const docRef = doc(db, "users", uid, "fly_workspaces", wid);

    // Using onSnapshot to listen to real-time updates
    onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        if (data.last_refreshed_content) {
          this.lastRefreshed = data.last_refreshed_content.toDate(); // Convert Firestore Timestamp to JavaScript Date object
          console.log('Last Refreshed:', this.lastRefreshed);
        } else {
          console.log('No last_refreshed_content field found.');
        }
      } else {
        console.log("No such document!");
      }
    }, (error) => {
      console.error("Error fetching real-time updates:", error);
    });
  },
displayRelativeTime() {
  if (!this.lastRefreshed) return "Not refreshed yet";

  const now = new Date();
  const diffMs = now - this.lastRefreshed; // difference in milliseconds
  const diffMins = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMins / 60);

  if (diffMins < 1) {
    return "Just now";
  } else if (diffMins < 60) {
    return `${diffMins} minutes ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hours ago`;
  } else {
    const diffDays = Math.floor(diffHours / 24);
    return `${diffDays} days ago`;
  }
},
    async refreshInsights() {
      const uid = this.currentUID; // Ensure this data is available in your component's data
      const wid = this.WID; // Ensure this data is available in your component's data
      const payload = {
        uid: uid,
        wid: wid,
      };

      try {
        const workspaceDocRef = doc(db, "users", uid, "fly_workspaces", wid);
        await updateDoc(workspaceDocRef, {
          last_refreshed_content: new Date() // Firestore Timestamp
        });
        const refreshInsightsFunction = httpsCallable(functions, "refreshInsightsFunction");
        const response = await refreshInsightsFunction(payload);
        console.log('Refreshed Insights:', response.data);

        // Update the last_refreshed_content in Firestore
       
        console.log('Last refreshed content date updated successfully');
      } catch (error) {
        console.error('Error refreshing insights or updating Firestore:', error);
      }
    },
    retainOnlyThisProfile(profile, selectedProfile) {
    // Uncheck all other profiles for this platform
    profile.profiles.forEach((p) => {
      p.checked = p.id === selectedProfile.id;
    });

    // Uncheck all other platforms
    this.allProfiles.forEach((p) => {
      if (p.platform !== profile.platform) {
        p.checked = false;
        p.profiles.forEach((sp) => {
          sp.checked = false;
        });
      }
    });

    // Reapply the filters and fetch the updated data
    this.applyProfiles();
  },
    handleClickOutside(event) {
      const profileDropdown = this.$refs.profileDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    logChange(profile) {
      profile.profiles.forEach((p) => {
        p.checked = profile.checked;
      });
      console.log(`Platform: ${profile.platform}, Checked: ${profile.checked}`);
    },
    logSubProfileChange(profile, subProfile) {
      console.log(
        `Platform: ${profile.platform}, Sub-Profile: ${subProfile.id}, Checked: ${subProfile.checked}`
      );
    },
    async fetchAllPlatforms() {
      const platformsRef = collection(db, "fly_platforms");
      const snapshot = await getDocs(platformsRef);
      let platforms = [];
      snapshot.forEach((doc) => {
        platforms.push({ id: doc.id, ...doc.data() });
      });
      return platforms;
    },
    async fetchAllProfiles() {
      try {
        const platforms = await this.fetchAllPlatforms();
        const uid = this.currentUID;
        const workspaceId = this.WID;
        const appsRef = collection(
          db,
          "users",
          uid,
          "fly_workspaces",
          workspaceId,
          "connected-apps"
        );
        const querySnapshot = await getDocs(appsRef);
        let allProfiles = [];

        querySnapshot.forEach((doc) => {
          if (doc.id === "reddit" || doc.id === "google_business_profile") {
            return; // Skip the rest of the loop for these platforms
          }
          const data = doc.data();
          let platformData = platforms.find((p) => p.alias === doc.id);
          let logoUrl = platformData ? platformData.logo_url : "";

          let profiles = [];
          if (data.pages) {
            profiles = data.pages.map((page) => ({
              id: page.id,
              name: page.name,
              picture: page.picture || "",
              checked: true,
            }));
          } else if (data.boards) {
            profiles = data.boards.map((board) => ({
              id: board.id,
              name: board.name,
              picture: board.picture || "",
              checked: true,
            }));
          } else if (data.blogs) {
            profiles = data.blogs.map((blog) => ({
              id: blog.name,
              name: blog.name,
              picture: blog.picture || "",
              checked: true,
            }));
          } else if (data.users) {
            profiles = data.users.map((user) => ({
              id: user.user_id,
              name: user.name,
              picture: user.picture || "",
              checked: true,
            }));
          } else {
            let id = data.user_id || data.name;
            profiles.push({
              id: id,
              name: data.name,
              picture: data.picture || "",
              checked: true,
            });
          }

          allProfiles.push({
            platform: doc.id,
            name: data.name,
            user_id: data.user_id || "N/A",
            profiles: profiles,
            logo_url: logoUrl,
            checked: true, // Check state for the platform
          });
        });

        this.allProfiles = allProfiles;
        this.fetchAllStats();
      } catch (error) {
        console.error("Failed to fetch connected apps: ", error);
      }
    },
    toggleProfileDropdown(event) {
      this.showProfileDropdown = !this.showProfileDropdown;
      event.stopPropagation(); // Prevent the document click handler from firing immediately
    },
    closeProfileDropdown() {
      if (this.showProfileDropdown) {
        this.showProfileDropdown = false;
      }
    },
    handleClickOutside(event) {
      if (this.showProfileDropdown) {
        const profileDropdown = this.$refs.profileDropdown;
        if (profileDropdown && !profileDropdown.contains(event.target)) {
          this.closeProfileDropdown();
        }
      }
    },
    toggleDateDropdown() {
      this.showDateDropdown = !this.showDateDropdown;
    },
    selectProfile(profile) {
      this.selectedProfile = profile;
      this.toggleProfileDropdown();
    },
    selectDateRange(range) {
  this.selectedDateRange = range;
  this.showDateDropdown = false;

  // Show loader
  this.$loader.show('Fetching content data for the new date range.');

  const checkedProfiles = this.allProfiles.filter(
    profile => profile.checked && profile.profiles.some(p => p.checked)
  );

  // Fetch updated data and ensure the loader is hidden after operations
  this.fetchAllStats(checkedProfiles)
    .then(() => {
      this.fetchPlatformMetrics(checkedProfiles);
      this.fetchPosts(checkedProfiles);
    })
    .finally(() => {
      this.$loader.hide(); // Hide loader once all fetching is complete
    });
},

    getTooltipMessage(platformName) {
      const totalPlatforms = ["X / Twitter", "Pinterest", "Instagram"];
      if (totalPlatforms.includes(platformName)) {
        return "Total count of audience for your channel";
      } else {
        return "Total count of audience for your channel in the past 30 days";
      }
    },
    setView(view) {
      this.selectedView = view;
      localStorage.setItem("selectedView", view);
    },
    toggleOnboardingVisibility() {
      this.isOnboardingVisible = !this.isOnboardingVisible;
    },
    moveUp(index) {
      if (index > 0) {
        const item = this.processedData[index];
        this.processedData.splice(index, 1);
        this.processedData.splice(index - 1, 0, item);
        this.saveOrder();
      }
    },
    moveDown(index) {
      if (index < this.processedData.length - 1) {
        const item = this.processedData[index];
        this.processedData.splice(index, 1);
        this.processedData.splice(index + 1, 0, item);
        this.saveOrder();
      }
    },
    saveOrder() {
      const order = this.processedData.map(
        (item) => item.id || item.metrics[0].name
      );
      localStorage.setItem("channelOrder", JSON.stringify(order));
    },
    loadOrder() {
      const order = JSON.parse(localStorage.getItem("channelOrder"));
      if (order) {
        const orderedData = [];
        order.forEach((id) => {
          const item = this.processedData.find(
            (item) => item.id === id || item.metrics[0].name === id
          );
          if (item) {
            orderedData.push(item);
          }
        });
        this.processedData.forEach((item) => {
          if (!orderedData.includes(item)) {
            orderedData.push(item);
          }
        });
        this.processedData = orderedData;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    async toggleVisibility(item) {
      item.isVisible = !item.isVisible;
      const itemId = item.id || item.metrics[0].name;
      const docRef = doc(
        db,
        "users",
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "channels",
        itemId
      );

      try {
        await setDoc(
          docRef,
          {
            isVisible: item.isVisible,
            platform: item.platform,
            name: item.name,
          },
          { merge: true }
        );
        console.log("Item visibility updated or created successfully");
      } catch (error) {
        console.error("Error updating or creating item visibility: ", error);
      }
    },
    async fetchChannels() {
      const userId = this.currentUID;
      const channelsRef = collection(
        db,
        "users",
        userId,
        "fly_workspaces",
        this.WID,
        "channels"
      );

      onSnapshot(channelsRef, (querySnapshot) => {
        let channels = [];
        querySnapshot.forEach(
          (doc) => {
            let channelData = doc.data();
            channelData.id = doc.id;
            channels.push(channelData);
            this.processedData.forEach((data) => {
              if (data.name === channelData.name) {
                data.isVisible = channelData.isVisible;
              }
            });
          },
          (error) => {
            console.error("Failed to fetch channels: ", error);
          }
        );
      });
    },
    formatNumber(value) {
      return value >= 1000 ? `${(value / 1000).toFixed(1)}K` : value;
    },
    async fetchOnboardingSteps() {
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.onboarding_steps) {
            const stepsData = data.onboarding_steps;
            this.onboardingSteps = this.onboardingSteps.map((step) => ({
              ...step,
              completed: stepsData[step.key] || false,
            }));
          }
          this.dismissed =
            data.dismissed !== undefined ? data.dismissed : false;
        } else {
          console.log("No user data found in Firestore, using defaults.");
        }
      } catch (error) {
        console.error("Error fetching onboarding data from Firestore: ", error);
      }
    },
    async dismissOnboarding() {
      this.dismissed = true;
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { dismissed: this.dismissed });
        console.log("Dismiss state updated in Firestore!");
      } catch (error) {
        console.error("Error updating dismiss state in Firestore: ", error);
      }
    },
    async initializeOnboardingSteps() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || !docSnap.data().onboarding_steps) {
        await setDoc(docRef, { onboarding_steps: stepsData }, { merge: true });
        console.log("Onboarding steps initialized in Firestore.");
      } else {
        console.log("Firestore already has onboarding steps.");
      }
    },
    async updateOnboardingStepsInFirestore() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { onboarding_steps: stepsData });
        console.log("Onboarding steps updated in Firestore successfully!");
      } catch (error) {
        console.error("Error updating onboarding steps in Firestore: ", error);
      }
    },

    async fetchAllStats(profiles = this.allProfiles) {
  console.log("Fetch Stats Started");

  if (!profiles.length) {
    console.log("No profiles available to fetch stats for.");
    return;
  }

  const { startDate, endDate } = this.dateRange;

  try {
    this.isLoading = true; // Start loading

    const statsPromises = profiles.map(async (profile) => {
      const payload = {
        uid: this.currentUID,
        wid: this.WID,
        startDate: startDate,
        endDate: endDate,
        operation: "fetchAllAggregatedStats",
        platform: profile.platform,
        profiles: profile.profiles
          .filter((p) => p.checked)
          .map((p) => p.id),
      };

      const fetchStats = httpsCallable(
        functions,
        "fetchPerformanceMetrics",
        {
          timeout: 540000,
        }
      );
      return fetchStats(payload);
    });

    const results = await Promise.all(statsPromises);
    const aggregatedStats = results.map((result) => result.data);
    console.log("Aggregated", aggregatedStats);

    const aggregateStats = (statsArray) => {
      return statsArray.reduce(
        (acc, curr) => {
          if (typeof curr === "object" && curr !== null) {
            acc.total_posts += curr.total_posts || 0;
            acc.total_likes += curr.total_likes || 0;
            acc.total_comments += curr.total_comments || 0;
            acc.total_shares += curr.total_shares || 0;
            acc.total_views += curr.total_views || 0;
          }
          return acc;
        },
        {
          total_posts: 0,
          total_likes: 0,
          total_comments: 0,
          total_shares: 0,
          total_views: 0,
        }
      );
    };

    this.aggregatedResult = aggregateStats(aggregatedStats);
    this.platformStats = aggregatedStats;

    // Add 2-second delay before setting isLoading to false
    setTimeout(() => {
      this.isLoading = false; // Stop loading after 2 seconds
    }, 2000);

  } catch (error) {
    console.error("Failed to fetch platform stats:", error);
    
    // Ensure the loading is stopped even in case of error
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }
},
    async loadData() {
      await this.fetchPlatformMetrics();
      setTimeout(() => {
    this.isLoading = false;
  }, 3000);
    },
    async fetchPlatformMetrics(profiles = this.allProfiles) {
  console.log("Fetch Platform Metrics Started");

  if (!profiles.length) {
    console.log("No checked profiles available to fetch metrics for.");
    this.isLoading = false; // Stop loading if there are no profiles
    return;
  }

  const { startDate, endDate } = this.dateRange;

  try {
    this.isLoading = true; // Ensure loading is true before starting the fetch

    const metricsPromises = profiles.map(async (profile) => {
      const platformPayload = {
        uid: this.currentUID,
        wid: this.WID,
        startDate: startDate,
        endDate: endDate,
        operation: "fetchPlatformStats",
        platform: profile.platform,
        profiles: profile.profiles
          .filter((p) => p.checked)
          .map((p) => p.id),
      };

      const fetchMetrics = httpsCallable(
        functions,
        "fetchPerformanceMetrics",
        {
          timeout: 540000,
        }
      );
      const result = await fetchMetrics(platformPayload);

      // Fetch profile images for each profile
      const profilesWithImagesPromises = result.data.profiles.map(
        async (profileData) => {
          const profilePicturePayload = {
            uid: this.currentUID,
            wid: this.WID,
            platform: profile.platform,
            profile: profileData.profile_id,
          };

          const fetchProfilePicture = httpsCallable(
            functions,
            "fetchProfilePicture"
          );
          const pictureResult = await fetchProfilePicture(profilePicturePayload);
          profileData.profile_image_url = pictureResult.data || "";
          if (typeof profileData.profile_image_url !== "string") {
            profileData.profile_image_url = "";
          }

          return profileData;
        }
      );

      result.data.profiles = await Promise.all(profilesWithImagesPromises);

      return result;
    });

    const results = await Promise.all(metricsPromises);
    this.platformprofile = results.map((result) => result.data);

    console.log("Platform Stats with Profile Images:", this.platformprofile);
  } catch (error) {
    console.error("Failed to fetch platform metrics:", error);
  } finally {
    // Add a 2-second delay before setting isLoading to false
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }
},
    async fetchPosts(profiles = this.allProfiles) {
      console.log("Fetch Posts Started");

      if (!profiles.length) {
        console.log("No checked profiles available to fetch posts for.");
        this.platformposts = [];
        return;
      }

      const { startDate, endDate } = this.dateRange;

      try {
        this.isLoading = true;

        const postsPromises = profiles.map(async (profile) => {
          const profilePayload = {
            uid: this.currentUID,
            wid: this.WID,
            startDate: startDate,
            endDate: endDate,
            operation: "fetchProfileStats",
            platform: profile.platform,
            profiles: profile.profiles
              .filter((p) => p.checked)
              .map((p) => p.id),
          };

          const fetchPosts = httpsCallable(
            functions,
            "fetchPerformanceMetrics",
            {
              timeout: 540000,
            }
          );
          return fetchPosts(profilePayload);
        });

        const results = await Promise.all(postsPromises);
        this.platformposts = results.map((result) => result.data);

        console.log("Posts Data:", this.platformposts);
        this.isLoading = false;
      } catch (error) {
        console.error("Failed to fetch posts:", error);
        this.isLoading = false;
      }
    },
    async fetchall() {
      let data = {
        uid: "j4QOTDtsm3QmC3s6XtNuvahpBSt2",
        wid: "DKO9ED1lUjcP2YIMM21j",
        platform: "facebook",
        profile: "147379945130119",
      };

      let aiActions = httpsCallable(functions, "fetchProfilePicture", {
        timeout: 540000,
      });
      let result = (await aiActions(data)).data;
      console.log("profile", result);
    },
    applyProfiles() {
      this.showProfileDropdown = false;
      this.fetchAllStats(); // Fetch stats when profiles are applied
      this.fetchPlatformMetrics(); // Fetch platform metrics when profiles are applied
      this.fetchPosts(); // Fetch posts when profiles are applied
    },
  },
  async mounted() {
    this.isLoading = true; // Set loading to true when component mounts

    this.initializeOnboardingSteps();
    this.fetchOnboardingSteps();
    this.fetchall();
    await this.fetchAllProfiles();
    trackGTM(
      "Dashboard Loaded",
      "app",
      "dashboard-loaded",
      "Dashboard Loaded",
      0
    );
    await this.fetchChannels();
    this.loadOrder();
    await this.loadData();
    this.fetchAllStats();
   
    this.fetchPosts();
    this.fetchLastRefreshed();
    document.addEventListener("click", this.handleClickOutside);
    this.isLoading = false;
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
/* Add your custom styles here */
li.flex.items-center.justify-between .truncate {
  max-width: calc(100% - 50px); /* Adjust this value based on your layout */
}

li.flex.items-center.justify-between a {
  min-width: 40px; /* Ensures the "Only" link has enough space */
  text-align: right;
}
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensures the div takes full width */
}

</style>
