var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.isAppHeader && _vm.$route.name != 'create-post')?_c('chat-widget',{staticClass:"chat-widget"}):_vm._e(),(
      _vm.isAppHeader &&
      _vm.$route.name != 'signup' &&
      _vm.$route.name != 'login' &&
      _vm.$route.name != 'complete-signup' &&
      _vm.$route.name != 'home-page' &&
      _vm.$route.name != 'login-phone' &&
      _vm.$route.name != 'login-email' &&
      _vm.$route.name != 'thank-you'
    )?_c('app-header'):_vm._e(),_c('v-main',[_c('router-view')],1),_c('Confirm'),_c('Alert'),_c('SnackBar'),_c('Loader')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }