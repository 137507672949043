import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style.css";
import AlertModal from "./components/ui/alert";
import ConfirmModel from "./components/ui/confirm";
import * as Sentry from "@sentry/vue";
import vuetify from "./plugins/vuetify";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import commonMixins from "./mixins/common.mixins";
import DatetimePicker from "vuetify-datetime-picker";
import SnackBar from "./components/ui/snackbar";
import ReadMore from "vue-read-more";
import LoaderModal from "./components/ui/loader";
import VueTellInputVuetify from "vue-tel-input-vuetify/lib";
import imagePreview from "image-preview-vue";
import "image-preview-vue/lib/imagepreviewvue.css";
import { VuePlugin } from "vuera";
import mixpanel from "mixpanel-browser";
import mixpanelPlugin from "./plugins/mixpanel";
import clarityPlugin from './plugins/clarityPlugin';
import VueGtm from '@gtm-support/vue2-gtm';
import pushNotification from "./components/notification/pushNotification";

const MAP_API_KEY = process.env.VUE_APP_MAP_API_KEY;


Vue.use(imagePreview);
Vue.use(VuePlugin);
Vue.use(mixpanelPlugin);

import "./registerServiceWorker";
import "./registerServiceWorker";
import { getUniqueId } from "./components/pintura/pintura-image/pintura";

mixpanel.init("0c0b9b9dd8bb2dfab0fdb32f2bc1a53b", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

// Set this to a unique identifier for the user performing the event.
// let id = getUniqueId();
// mixpanel.identify(id);
//mixpanel.track("Sign Up", {});

Sentry.init({
  Vue,
  dsn: "https://7aeb51c6cffb398bff1e347f3b148345@o4506795794104320.ingest.sentry.io/4506823181271040",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    //"http://localhost:8080/",
    //"https://dev3-fly.web.app/",
    //"https://dev.fly-social.com/",
    //"https://uat.fly-social.com/",
    "https://uat-app.fly-social.com/",
    "https://dev-app.fly-social.com/",
    "https://app.fly-social.com/",
    //"https://fly-social.com/",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
Vue.config.productionTip = false;
Vue.use(AlertModal);
Vue.use(pushNotification);

Vue.mixin(commonMixins);
Vue.use(DatetimePicker);
Vue.use(ConfirmModel);
Vue.use(ReadMore);
Vue.use(SnackBar);
Vue.use(LoaderModal);
Vue.use(clarityPlugin, { siteId: "ly14p8a0cb" });

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: MAP_API_KEY,
  installComponents: true,
  vueGoogleMapsCompatibility: false,
});

Vue.use(VueTellInputVuetify, {
  vuetify,
});

Vue.use(VueGtm, {
  id: "GTM-5DV6TX4G",
  vueRouter: router,
});

Vue.config.errorHandler = (err, vm, info) => {
  console.error("Error:", err);
  console.error("Vue component:", vm);
  console.error("Additional info:", info);
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
