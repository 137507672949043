<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        Google Business Profile Settings
      </div>
      <div class="px-3 py-2">
        <v-row>
          <v-col cols="12" md="6" class="pb-2">
            <autocomplete
              label="Choose locations"
              chips
              deletable-chips
              small-chips
              @input="$emit('input', form)"
              v-model="form.gmb_locations"
              :items="locations"
              multiple
            ></autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="py-2">
            <select-field
              @input="$emit('input', form)"
              label="Call to action"
              :items="callToAction"
              v-model="form.gmb_callToAction"
            />
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              v-if="form.gmb_callToAction && form.gmb_callToAction != 'CALL'"
              label="Call to action URL"
              outlined
              @input="$emit('input', form)"
              v-model="form.gmb_callToActionUrl"
              :rules="rules"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SelectField from "../../ui/form/SelectField.vue";
import Autocomplete from "@/components/ui/form/Autocomplete.vue";
import rulesConstants from "@/assets/constants/rules.constants";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    configuration: {
      type: Object,
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
    locations: Array,
    connectedApps: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SelectField,
    Autocomplete,
  },
  data() {
    return {
      rules: [rulesConstants.validUrl],
      form: {
        gmb_callToAction: "",
        gmb_locations: [],
        gmb_callToActionUrl: "",
      },
      callToAction: [
        {
          name: "Book",
          value: "BOOK",
        },
        {
          name: "Order",
          value: "ORDER",
        },
        {
          name: "Shop",
          value: "SHOP",
        },
        {
          name: "Learn More",
          value: "LEARN_MORE",
        },
        {
          name: "Sign Up",
          value: "SIGN_UP",
        },
        {
          name: "Call",
          value: "CALL",
        },
      ],
    };
  },
  async created() {
    if (this.isEditing || this.isRepost || this.isDraftPost) {
      this.$watch(
        () => this.connectedApps,
        (newVal) => {
          if (newVal && newVal.length > 0) {
            this.updateConfiguration();
          }
        }
      );
    }
  },
  watch: {
    isDraftPost: {
      handler(newVal) {
        if (newVal) {
          this.updateConfiguration();
        }
      },
      immediate: true,
    },
    isEditing: {
      handler(newVal) {
        if (newVal) {
          // If isEditing is true, update the form with configuration values
          if (this.isEditing) {
            this.updateConfiguration();
          }
        }
      },
      immediate: true,
    },
    isRepost: {
      handler(newVal) {
        if (newVal) {
          // If isEditing is true, update the form with configuration values
          if (this.isRepost) {
            this.updateConfiguration();
          }
        }
      },
      immediate: true,
    },
    value: {
      handler(val) {
        // console.log(val);
        Object.keys(this.form).forEach((key) => {
          if (val[key] != undefined) {
            this.form[key] = val[key];
          }
        });
      },
      deep: true,
    },
  },
  computed: {
    platform() {
      // console.log("loc:", this.locations);
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find(
        (i) => i.alias == "google_business_profile"
      );
      return platform || {};
    },
  },
  methods: {
    initForm() {
      console.log("loc:", this.locations);

      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });
    },
    updateConfiguration() {
      const isGMBConnected = this.connectedApps.find(
        (app) => app.id === "google_business_profile"
      );
      if (isGMBConnected) {
        const item =
          this.connectedApps.find(
            (item) => item.id === "google_business_profile"
          ) || [];

        let accounts = item.accounts;
        if (accounts && accounts.length > 0) {
          let allLocations = accounts.flatMap((account) => account.locations);

          // Filter the locations that match configuration locations
          let locations = allLocations.filter((location) =>
            this.configuration.gmb_locations?.some(
              (configLoc) => configLoc.name === location.name
            )
          );

          // Store the found locations and other data in the form
          this.form.gmb_locations =
            locations.length > 0
              ? locations
              : this.configuration.gmb_locations || "";
        } else {
          // If no accounts or locations are found, set the default configuration values
          this.form.gmb_locations = null;
        }
      } else {
        // If GMB is not connected, set the default configuration values
        this.form.gmb_locations = null;
      }
      this.form.gmb_callToAction = this.configuration.gmb_callToAction || "";
      this.form.gmb_callToActionUrl =
        this.configuration.gmb_callToActionUrl || "";
        // console.log('form:', this.form)
    },
  },
  beforeMount() {
    this.initForm();
  },
};
</script>

<style></style>
