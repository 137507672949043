import {
  isAuth,
  isNotAuth,
  isGuest,
  isAdminAuth,
  invitationAccepted,
  checkTeamMember,
  checkRole,
} from "@/assets/helper/auth.helper";
import ConnectedApps from "../pages/fly/ConnectedApps.vue";
import Gallery from "../pages/fly/Gallery.vue";
import CreatePost from "../pages/fly/CreatePost.vue";
import SSOLogin from "../pages/SSOLogin.vue";
import CompleteSignup from "../pages/CompleteSignup.vue";
import SignUp from "../pages/SignUp.vue";
import UpdateGalleryImage from "../pages/fly/UpdateGalleryImage.vue";
import UpdateGalleryVideo from "../pages/fly/UpdateGalleryVideo.vue";
import ConnectionSuccess from "../pages/fly/ConnectionSuccess.vue";
import Posts from "../pages/fly/Posts.vue";
import Dashboard from "../pages/fly/Dashboard.vue";
import Error from "../pages/fly/ErrorComponent.vue";
import Account from "../pages/fly/Account";
import Teams from "../pages/fly/Teams.vue";
import AssignWorkspace from "../pages/fly/AssignTeamWorkspace.vue";
import PostPreview from "../pages/fly/PostPreview.vue";
import Contactus from "@/pages/fly/Contactus";
import UpdateProfile from "@/pages/fly/UpdateProfile";
import HelpCms from "@/pages/admin/HelpCms.vue";
import UpdateHelpCms from "@/pages/admin/UpdateHelpCms.vue";
import Terms from "@/pages/website/Terms";
import PrivacyPolicy from "@/pages/website/PrivacyPolicy";
import Subscription from "@/components/ui/Subscription.vue";
import SignupOffer from "@/components/ui/SignupOffer.vue";
import Help from "@/pages/fly//help/Help";
import Facbookhelp from "@/pages/fly/help/Facbookhelp";
import Instagramhelp from "@/pages/fly/help/Instagramhelp";
import Youtubehelp from "@/pages/fly/help/Youtubehelp";
import Twitterhelp from "@/pages/fly/help/Twitterhelp";
import UniversalFeed from "@/components/ui/UniversalFeed";
import Resources from "@/pages/fly/help/Resources.vue";
import AddCampaign from "../pages/fly/AddCampaign.vue";
import TwittterConnect from "@/pages/fly//help/TwittterConnect";
import CreateCarListings from "@/pages/fly/CreateCarListings.vue";
import Brandinfo from "@/pages/fly/Brandinfo.vue";
import ProductService from "@/pages/fly/ProductService";
import Createai from "@/pages/fly/Createai.vue";
import Suggestai from "@/pages/fly/Suggestai.vue";
import CustomTokenLogin from "@/pages/CustomTokenLogin.vue";
import Thanks from "@/pages/fly/Thanks.vue";
import Calender from "@/pages/fly/Calender.vue";
import Createsell from "@/pages/fly/Createsell.vue";
import EmailLogin from "@/pages/EmailLogin.vue";
import InvitationAccepted from "@/components/teamFeature/InvitaionAccepted.vue";
import AcceptInvitation from "@/components/teamFeature/AcceptInvitaion.vue";
import GenerateImage from "../pages/fly/GenerateImage.vue";
import ChooseTemplate from "@/pages/fly/ChooseTemplate.vue";
import store from "@/store";
import ContentFeed from "@/components/contentFeed/ContentFeed.vue"
import ContentAi from "@/components/contentAI/ContentAi.vue"
import ContentInsights from '@/pages/fly/ContentInsights.vue'

let routes = [
  {
    path: "/home",
    // name: "home-page",
    // component: HomePage,

    redirect: { name: "calendar" },
    beforeEnter: isGuest,
  },

  {
    path: "/",
    // name: "home-page",
    // component: HomePage,

    redirect: { name: "calendar" },

    beforeEnter: isGuest,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: Contactus,
    beforeEnter: isGuest,
  },
  {
    path: "/posts",
    name: "fly",
    component: Posts,
    beforeEnter: isAuth,
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: isAuth,
  },
  {
    path: "/thank-you",
    name: "thank-you",
    component: Thanks,
    beforeEnter: isAuth,
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calender,
    beforeEnter: isAuth,
  },
  {
    path: "/content-insights",
    name: "content-insights",
    component: ContentInsights,
    beforeEnter: isAuth,
  },
  {
    path: "/brand-info",
    name: "brand-info",
    component: Brandinfo,
    beforeEnter: isAuth,
  },
  {
    path: "/product-service-catalogue",
    name: "product-service-catalogue",
    component: ProductService,
    beforeEnter: isAuth,
  },
  {
    path: "/create",
    name: "create",
    component: Createai,
    beforeEnter: isAuth,
  },
  {
    path: "/suggestai",
    name: "suggestai",
    component: Suggestai,
    beforeEnter: isAuth,
  },
  {
    path: "/content-ai",
    name: "contentai",
    component: ContentAi,
    beforeEnter: isAuth,
  },
  {
    path: "/edit-profile",
    name: "edit-profile",
    component: UpdateProfile,
    beforeEnter: isAuth,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: Contactus,
    beforeEnter: isGuest,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
    beforeEnter: isGuest,
  },
  {
    path: "/help/facebook",
    name: "help/facebook",
    component: Facbookhelp,
    beforeEnter: isGuest,
  },
  {
    path: "/help/instagram",
    name: "help/instagram",
    component: Instagramhelp,
    beforeEnter: isGuest,
  },
  {
    path: "/help/youtube",
    name: "help/youtube",
    component: Youtubehelp,
    beforeEnter: isGuest,
  },
  {
    path: "/help/twitter",
    name: "help/twitter",
    component: Twitterhelp,
    beforeEnter: isGuest,
  },
  {
    path: "/login",
    name: "login",
    component: SSOLogin,
    beforeEnter: isNotAuth,
  },
  {
    path: "/login-phone",
    name: "login-phone",
    component: SSOLogin,
    beforeEnter: isNotAuth,
  },
  {
    path: "/login-email",
    name: "login-email",
    component: EmailLogin,
    beforeEnter: isNotAuth,
  },
  {
    path: "/subscription",
    name: "subscription",
    component: Subscription,
    beforeEnter: isAuth,
  },
  {
    path: "/suo",
    name: "suo",
    component: SignupOffer,
    beforeEnter: isAuth,
  },

  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: Terms,
    beforeEnter: isGuest,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    beforeEnter: isGuest,
  },
  {
    path: "/complete-signup",
    name: "complete-signup",
    component: CompleteSignup,
    beforeEnter: isNotAuth,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    beforeEnter: isNotAuth,
  },
  {
    path: "/create-post",
    name: "create-post",
    component: CreatePost,
    beforeEnter: isAuth,
  },
  {
    path: "/repost/:postId",
    name: "repost",
    component: CreatePost,
    beforeEnter: isAuth,
    props: { isRepost: true },
  },
  {
    path: "/edit-post/:postId",
    name: "edit-post",
    component: CreatePost,
    beforeEnter: isAuth,
  },
  {
    path: "/draft-post/:postId",
    name: "draft-post",
    component: CreatePost,
    beforeEnter: isAuth,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: Gallery,
    beforeEnter: isAuth,
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    beforeEnter: isAuth,
  },
  {
    path: "/update-gallery-image/:id",
    name: "update-gallery-image",
    component: UpdateGalleryImage,
    beforeEnter: isAuth,
  },
  {
    path: "/update-gallery-video/:id",
    name: "update-gallery-video",
    component: UpdateGalleryVideo,
    beforeEnter: isAuth,
  },
  {
    path: "/connected-apps",
    name: "connected-apps",
    component: ConnectedApps,
    beforeEnter: isAuth,
  },
  {
    path: "/connection-success",
    name: "connection-success",
    component: ConnectionSuccess,
    beforeEnter: isAuth,
  },
  {
    path: "/error",
    name: "error",
    component: Error,
    props: (route) => ({
      error: route.query.error,
      stackTrace: route.query.stackTrace,
    }),
    beforeEnter: isGuest,
  },
  {
    path: "/team",
    name: "team",
    component: Teams,
    beforeEnter: isAuth,
  },
  {
    path: "/invitation-accepted",
    name: "InvitationAccepted",
    component: InvitationAccepted,
    beforeEnter: invitationAccepted,
  },
  {
    path: "/accept-invitation",
    name: "AcceptInvitation",
    component: AcceptInvitation,
    beforeEnter:isAuth,
  },
  {
    path: "/assign-workspace/:uid",
    name: "Assign-workspace",
    component: AssignWorkspace,
    beforeEnter: isAuth,
  },
  {
    path: "/content-feed",
    name: "contentfeed",
    component: ContentFeed,
    beforeEnter: isAuth,
  },
  {
    path: "/post/:uid/:wid/:id/",
    name: "post",
    component: PostPreview,
    beforeEnter: isAuth,
  },
  {
    path: "/power-users",
    name: "admin",
    component: AddCampaign,
    beforeEnter: isGuest,
  },

  {
    path: "/update-help-cms/:id",
    name: "update-help-cms",
    component: UpdateHelpCms,
    beforeEnter: isAdminAuth,
  },
  {
    path: "/universal-feed",
    name: "universal-feed",
    component: UniversalFeed,
    beforeEnter: isAuth,
  },
  {
    path: "/resources",
    name: "resources",
    component: Resources,
    beforeEnter: isGuest,
  },

  {
    path: "/power-users",
    name: "help-cms",
    component: HelpCms,
    beforeEnter: isAdminAuth,
  },

  {
    path: "/twitter-connect",
    name: "twitter-connect",
    component: TwittterConnect,
    beforeEnter: isAuth,
  },
  {
    path: "/create-car-listings",
    name: "create-car-listings",
    component: CreateCarListings,
    beforeEnter: isAuth,
  },
  {
    path: "/create-car-sell",
    name: "create-car-sell",
    component: Createsell,
    beforeEnter: isAuth,
  },
  {
    path: "/custom-token-login",
    name: "custom-token-login",
    component: CustomTokenLogin,
  },
  {
    path: "/choose-template/:id",
    name: "choose-template",
    component: ChooseTemplate,
  },
  {
    path: "/generate-image/:id",
    name: "generate-image",
    component: GenerateImage,
  },
];

let flyRoutes = routes.map((i) => {
  let meta = i.meta || {};
  meta.isFly = true;
  return { ...i, meta };
});

export default flyRoutes;
