<template>
    <div>
        <v-dialog v-model="showDialog" max-width="630px" persistent>
            <v-card class="dialog-card">
                <div class="flex justify-between">
                    <v-card-title>
                        {{ dialogTitle }}
                    </v-card-title>
                    <v-icon class="close_dialog mr-2 w-10 h-10" @click="cancelDialog">mdi-close</v-icon>
                </div>
                <v-card-text class="dialog-content">
                    <div class="flex pt-2">
                        <v-textarea label="Working on:" outlined v-model="postText" persistent-placeholder
                            class="pt-4 darkened-text wrap-generateText" :disabled="!editable" rows="3" />
                        <v-icon v-if="!editable" @click="editable = true" class="edit-icon">mdi-pencil</v-icon>
                        <v-icon v-if="editable" @click="editable = false" class="edit-icon">mdi-check</v-icon>
                    </div>
                    <div v-if="!generatingText">
                        <v-select multiple :items="socialNetworks" label="Target Social Networks (optional)"
                            v-model="selectedNetworks" chips deletable-chips append-outer-icon="mdi-check"
                            @click:append-outer="closeSelectDropdown" :menu-props="{ closeOnClick: true }"></v-select>
                    </div>
                    <div v-if="generatingText" class="mt-4">
                        <v-textarea class="generatedText" v-if="!textLoading" label="Generated Text"
                            v-model="generatedText" outlined></v-textarea>
                        <div v-if="textLoading" class="skeleton-loading"></div>
                        <div v-if="!textLoading && IdeaBasedtextGenerated" class="flex gap-2 my-2 flex-wrap">
                            <v-btn @click="shortenText">
                                <img src="@/assets/img/shorten.svg" alt="">
                                Shorten
                            </v-btn>
                            <v-btn @click="expandText">
                                <img src="@/assets/img/expand.svg" alt="">
                                Expand
                            </v-btn>
                            <v-menu v-model="toneMenu" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on">
                                        {{ toneUpdate || 'Change Tone' }}
                                        <v-icon right>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="toneOption in toneOfVoice" :key="toneOption"
                                        @click="changeTone(toneOption)">
                                        <v-list-item-title>{{ toneOption }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-menu v-model="typeMenu" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on">
                                        {{ typeUpdate || 'Language' }}
                                        <v-icon right>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="typeOption in language" :key="typeOption"
                                        @click="changeType(typeOption)">
                                        <v-list-item-title>{{ typeOption }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                    <v-row class="flex justify-between specify_btn">
                        <v-col cols="12" md="4">
                            <v-select class="custom-select" :items="language" v-if="!generatingText"
                                label="Select Language" v-model="lang" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select class="custom-select" :items="toneOfVoice" v-if="!generatingText"
                                label="Default Tone (Informative)" v-model="tone" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <div class="flex justify-between flex-wrap">
                    <v-menu offset-y v-if="!textLoading && IdeaBasedtextGenerated && generatingText">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="info self-start max-w-sm ml-5 mt-2">
                                Regenerate
                                <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="reGenerateText(toneUpdate, 'regeneratePostforIdea', generatedText, postText, generatedText.length, typeUpdate, null)">
                                <v-list-item-title>Auto Regenerate</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="regenerateSuggestionDialog = true">
                                <v-list-item-title>Regenerate with Suggestion</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-card-actions class="flex justify-end flex-wrap">
                        <v-btn class="primary" v-if="!generatingText" @click="generateText(postText, tone, lang)">
                            Generate Text
                        </v-btn>
                        <v-btn class="primary" v-else-if="!textLoading" @click="CreateWithPost(generatedText)">
                            Post with Fly
                        </v-btn>
                        <v-btn class="secondary" v-if="generatingText && !textLoading" @click="SaveGenerated()">
                            Save
                        </v-btn>
                        <v-btn v-if="generatingText" @click="goBack">Back</v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="regenerateSuggestionDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add Suggestions</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="regenerateSuggestionDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-textarea v-model="addedSuggestions" outlined label="Add your suggestions" rows="2"></v-textarea>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn class="primary"
                        @click="reGenerateText(toneUpdate, 'regeneratePostforIdea', generatedText, postText, generatedText.length, typeUpdate, addedSuggestions)">Regenerate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>



<script>
import { db, functions, auth } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, setDoc, addDoc, deleteDoc, collection, getDocs, query, where, updateDoc } from "firebase/firestore";
import openaiMixin from "@/mixins/openai.mixin";
export default {
    name: "GenerateContentDialog",
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dialogTitle: {
            type: String,
            default: "Write Idea-Based Content",
        },
        fetchedDescription: {
            type: String,
            default: "Write Idea-Based Content",
        },
        brandName: {
            type: String,
            default: "Write Idea-Based Content",
        },
        postText: {
            type: String,
            default: ""
        },
        socialNetworks: {
            type: Array,
            default: () => [],
        },
        toneOfVoice: {
            type: Array,
            default: () => [],
        },
        language: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            editable: false,
            generatingText: false,
            textLoading: false,
            IdeaBasedtextGenerated: false,
            toneMenu: false,
            typeMenu: false,
            ideaBeforeUpdate: '',
            currentTopicIdea: "",
            selectedNetworks: [],
            addedSuggestions: '',
            generatedText: "",
            toneUpdate: "",
            regenerateSuggestionDialog: false,
            typeUpdate: "",
            lang: "",
            tone: "",
            // useBusinessDescription: false,
            ideaToUpdate: null,
        };
    },
    mixins: [openaiMixin],
    methods: {
        async cancelDialog() {
            this.IdeaBasedtextGenerated = false;
            this.generatingText = false;
            this.selectedNetworks = [];
            this.lang = '';
            this.tone = '';
            this.generatedText = '';
            this.tone = '';
            this.toneUpdate = '';
            this.addedSuggestions = '';
            this.ideaToUpdate = null;
            this.$emit("cancel-dialog");
        },
        async updateIdea() {
            if (this.ideaToUpdate !== null) {
                let { topic, ideaIndex } = this.ideaToUpdate;
                topic.ideas[ideaIndex] = this.postText;
                let topicsRef = collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas');
                const topicDoc = doc(topicsRef, topic.id);
                await setDoc(topicDoc, topic);
            }
        },
        closeSelectDropdown() {

        },
        async shortenText() {
            // Emit event to shorten text
            let maxChar = Math.floor(this.generatedText.length * 0.7);
            if (maxChar < 50) maxChar = 50;
            await this.reGenerateText(null, 'ctShortening', this.generatedText, this.postText, maxChar, null, null);
        },
        async expandText() {
            let maxChar = Math.floor(this.generatedText.length * 1.3);
            if (maxChar > 500) maxChar = 500;
            await this.reGenerateText(null, 'ctExpanding', this.generatedText, this.postText, maxChar, null, null);

        },
        async changeTone(tone) {
            this.toneUpdate = tone;
            this.toneMenu = false;
        },
        async changeType(type) {
            this.typeUpdate = type;
            this.typeMenu = false;
        },
        async generateText(idea, tone, lang) {
            this.ideaBeforeUpdate = idea;
            if (this.briefDescription !== '') {
                idea = `${idea}, ${this.briefDescription}`
            }
            if (tone == '') {
                tone = 'Informative'
            }
            let mchar = 320;
            if (this.selectedNetworks.includes('Twitter')) {
                mchar = 280;
            }
            this.generatingText = true;
            this.textLoading = true;
            const payload = {
                uid: this.currentUID,
                wid: this.WID,
                operation: "generateRepurposePost",
                textContent: idea,
                tone: tone,
                businessDescription: this.fetchedDescription || "New Startup Business Idea",
                brandName: this.brandName||"Fly Social",
                targetSocialMedia: `${this.selectedNetworks.join(", ")}`,
                language: lang || "English(US)",
                maxChar: mchar,
            }
            const result = await this.getContentAiGenerate(payload);
            this.textLoading = false;
            this.generatedText = result;
            this.IdeaBasedtextGenerated = true;
        },
        async reGenerateText(tone, operation, text, idea, maxChar, language, suggestion) {
            let pp = {
                tone:tone,operation:operation,text:text,idea:idea,maxChar:maxChar,language:language,suggestion:suggestion
            }
            this.regenerateSuggestionDialog = false;
            this.generatingText = true;
            this.textLoading = true;
            let payload = {
                uid: this.currentUID,
                wid: this.WID,
                language: language||"English(US)",
                suggestions: suggestion,
                operation: operation,
                textContent: text,
                tone: tone,
                rephrase: true,
                maxChar: maxChar,
            }
            if (idea !== this.postText) {
                payload = {
                    uid: this.currentUID,
                    wid: this.WID,
                    operation: "generateRepurposePost",
                    textContent: idea,
                    tone: tone,
                    businessDescription: this.fetchedDescription || "New Startup Business Idea",
                    brandName: this.brandName||"Fly Social",
                    targetSocialMedia: `${this.selectedNetworks.join(", ")}`,
                    language: language || "English (US)",
                    maxChar: maxChar,
                }
                this.ideaBeforeUpdate = idea;
            }
            const result = await this.getContentAiGenerate(payload);
            this.textLoading = false;
            this.generatedText = result;
            this.IdeaBasedtextGenerated = true;
        },
        async SaveGenerated() {
            try {
                let socialPostsRef = collection(db, "users", this.currentUID, "fly_workspaces", this.WID, "social-posts");
                let connectedAppRef = collection(db, "users", this.currentUID, "fly_workspaces", this.WID, 'connected-apps');
                const connectedAppsSnapshot = await getDocs(connectedAppRef);
                let connectedApps = [];
                connectedAppsSnapshot.forEach((doc) => {
                    connectedApps.push(doc.id); // Push each connected app's document ID to the array
                });
                await addDoc(socialPostsRef, {
                    configuration: {
                        fb_post_type: "",
                        ig_post_type: "",
                        yt_post_type: ""
                    },
                    created_at: new Date(),
                    name: `Social Tracker Posts`,
                    images: [],
                    overrideImages: false,
                    post_status: "draft",
                    residential: "",
                    scheduled_at: "",
                    social_media: connectedApps,
                    text: this.generatedText,
                    uid: this.currentUID,
                    updated_at: new Date(),
                    when_to_post: "immediate",
                    wid: this.WID,
                });
                this.$snackbar.show("The post is saved in draft");
            } catch (error) {
                console.error("Error saving the idea", error);
            }
            this.cancelDialog();
        },
        async CreateWithPost(text) {
            if (Array.isArray(text)) {
                text = text[0];
            }
            if (text !== '') {
                this.$router.push({
                    name: "create-post", params: {
                        text: text
                    }
                })
            }
            this.cancelDialog();
        },
        goBack() {
            // Emit event to go back
            this.generatingText = !this.generatingText;
            return;
        },
    },
};
</script>

<style scoped>
.dialog-content {
    overflow-y: auto;
    max-height: 60vh;
}

.wrap-generateText {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.darkened-text {
    color: #333;
    font-weight: 500;
}

.darkened-text>>>.v-label {
    font-size: 1.1rem !important;
    font-weight: bold;
}

.edit-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-left: 8px;
}

.skeleton-loading {
    width: 100%;
    height: 96px;
    background: linear-gradient(90deg, rgba(224, 224, 224, 0.5) 25%, rgba(245, 245, 245, 0.5) 50%, rgba(224, 224, 224, 0.5) 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite ease-in-out;
    border-radius: 4px;
}

.specify_btn {
    display: flex;
    align-items: center;
}

@media (max-width: 480px) {
    .specify_btn {
        gap: 0.3rem;
    }
}

@keyframes skeleton-loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
</style>
