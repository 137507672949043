<template>
  <div>
    <div class="">
      <v-card class="pa-2" outlined>
        <div class="flex items-start justify-center">
          <div class="w-full h-auto bg-white rounded-3xl">
            <div class="flex flex-col items-center px-4 py-5 md:pl-0 lg:px-5">
              <div class="flex items-center justify-between w-full">
                <div class="w-full text-sm font-medium text-neutral-900">
                  {{ post.name }}
                </div>
                <div class="flex gap-[14px] items-center mr-4">
                  <div v-if="checkUID && (!post.post_status || (post.post_status && post.post_status !== 'deleted'))"
                    @click="deletePost(post)" class="bg-[#F2F6FA] rounded-md p-[7px] cursor-pointer">
                    <img src="../../assets/img/post-icons/deleteicon.svg" alt="delete" class="w-6" />
                  </div>
                  <div class="bg-[#F2F6FA] cursor-pointer rounded-md p-[7px]" @click="copyLink()">
                    <img src="../../assets/img/post-icons/copyicon.svg" alt="copy" class="w-6" />
                  </div>
                </div>
              </div>
              <div class="text-xs font-normal text-justify w-full px text-slate-500 mt-[10px]">
                {{ post.text }}
              </div>
              <div class="text-slate-500 mt-[14px] text-xs font-normal">
                {{ formatHashtags(post.hashtags) }}
              </div>
              <v-carousel hide-delimiters v-model="carousel" class="mt-1 rounded-lg" v-if="carousels.length"
                :show-arrows="carousels.length > 1" :height="isMobile ? '310px' : 'auto'">
                <v-carousel-item v-for="(item, index) in carousels" :key="index">
                  <div class="h-full d-flex align-center">
                    <v-img :src="item.src" v-if="item.type == 'image'" style="height: 345px; object-fit: cover" />
                    <video-auto-play v-if="item.type == 'video'" :autoplay="false" :videoSrc="item.src"
                      style="height: 345px; object-fit: cover" />
                  </div>
                </v-carousel-item>
              </v-carousel>

              <div class="flex flex-wrap items-center justify-between w-full mt-4 md:px-4">
                <div class="flex gap-[3px]">
                  <v-avatar tile size="24" class="" v-for="(socialMedia, index) in post.social_media" :key="index">
                    <v-img :src="socialMedia.logo_url" :alt="socialMedia.name"></v-img>
                  </v-avatar>
                </div>

                <div class="mt-2 ml-1 text-xs font-normal md:ml-0 text-slate-500 md:mt-0">
                  <p class="" v-if="!post.scheduled_at && post.created_at">
                    <span class="tooltip" data-tooltip="Sent"> </span>
                    {{ formatDate(post.created_at.seconds * 1000) }}
                  </p>
                  <p class="" v-if="post.when_to_post != 'immediate'">
                    <span class="tooltip" data-tooltip="Schedule"> </span>
                    {{
                      post.scheduled_at.seconds
                        ? formatDate(post.scheduled_at.seconds * 1000)
                        : formatDate(post.scheduled_at)
                    }}
                  </p>
                </div>
              </div>
              <div class="status-container mr-7">
                <span v-if="post.post_status === 'deleted'" class="status-deleted text-xs">Deleted</span>
                <span v-else-if="post.post_status === 'draft' && (post.when_to_post !== 'schedule')"
                  class="status-draft text-xs">Draft</span>
                <span v-else-if="post.when_to_post === 'schedule' && !isPostLive(post)"
                  class="status-scheduled text-xs">Scheduled</span>
                <span v-else-if="post.when_to_post === 'immediate' || (post.scheduled_at && isPostLive(post))"
                  class="status-published text-xs">Published</span>
              </div>
              <div class="flex items-center justify-center mt-6">
                <div class="w-[550px] flex gap-[20px] flex-col">
                  <div class="flex gap-10">
                    <button
                      class="w-[100px] flex items-center justify-center gap-2 xl:w-[124px] py-[11px] text-[#636B8C] font-semibold bg-slate-100 rounded-full"
                      @click="post.post_status === 'draft'
                        ? editDraft(post.id)
                        : post.when_to_post !== 'immediate' && !isPostLive(post)
                          ? editPost(post.id)
                          : repost(post.id)" :disabled="loading">
                      <img src="../../assets/img/post-icons/repost.svg" alt="" />
                      {{
                        post.post_status === "draft"
                          ? "Edit"
                          : post.when_to_post !== "immediate" && !isPostLive(post)
                            ? "Edit"
                            : "Repost"
                      }}
                    </button>

                    <button v-if="!post.post_status || (post.post_status && post.post_status !== 'deleted')"
                      class="w-[100px] flex items-center justify-center gap-2 xl:w-[124px] py-[11px] text-[#636B8C] font-semibold bg-slate-100 rounded-full"
                      @click="showOutput = !showOutput" :disabled="loading">
                      <img src="../../assets/img/post-icons/graph.svg" alt="" />
                      {{ "Stats" }}
                      <v-icon v-if="showOutput">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </button>
                  </div>
                  <div class="flex gap-10">
                    <button
                      v-if="(!post.post_status || (post.post_status && post.post_status !== 'deleted')) && post?.isCurrUser && checkUser && post?.approvedStatus"
                      :class="['w-[100px] flex items-center justify-center gap-2 xl:w-[184px] py-[11px] px-[10px] font-semibold rounded-full', loading ? 'bg-gray-400 text-gray-600' : 'bg-[#1773ea] text-[#e9e9e9]']"
                      @click="approve" :disabled="loading">
                      {{ approveButtonText }}
                    </button>
                    <button
                      v-if="(!post.post_status || (post.post_status && post.post_status !== 'deleted')) && post?.isCurrUser && checkUser && post?.approvedStatus"
                      :class="['w-[100px] flex items-center justify-center gap-2 xl:w-[184px] py-[11px] px-[10px] font-semibold rounded-full', loading ? 'bg-gray-400 text-gray-600' : 'bg-[#FF3E3E] text-[#e9e9e9]']"
                      @click="openRejectDialog = true" :disabled="loading">
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showOutput" class="mt-2">
              <post-output :scheduled_at="post.scheduled_at
                ? post.scheduled_at.seconds
                  ? formatDate(post.scheduled_at.seconds * 1000)
                  : post.scheduled_at
                : ''" :postUID="post.uid" :postId="post.id" :postwid="post.wid" :post="post" />
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <v-dialog v-model="openRejectDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Reject Comment</span>
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="rejectComment" label="Comment" rows="3" outlined></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="handleReject">Reject</v-btn>
          <v-btn class="primary" text @click="openRejectDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth, colUsers, colScheduledPostsDelete, colSocialPostsDelete, storage } from "@/utils/firebase.utils";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import VideoAutoPlay from "../public/VideoAutoPlay.vue";
import PostOutput from "./PostOutput.vue";
import { deleteDoc, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
export default {
  components: { VideoAutoPlay, PostOutput },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    openOutput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carousel: 0,
      showOutput: false,
      draftLoading: false,
      checkUser: false,
      loading: false,
      approveButtonText: "",
      openRejectDialog: false,
      rejectComment: "",
      postId: this.$route.params.id,
    };
  },

  computed: {
    checkUID() {
      if (auth.currentUser.uid == this.currentUID || (this.post.post_status == 'draft' && (this.post.postBy == auth.currentUser.uid))) return true;
      else return false;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        this.post.videos.forEach((item) => {
          list.push({
            src: item.url || item,
            type: "video",
          });
        });
      }
      if (this.post.images) {
        this.post.images.forEach((item) => {
          list.push({
            src: item.thumbnail || item.url || item,
            type: "image",
          });
        });
      }
      if (this.$route.params?.uid === this.currentOriginalUID) {
        this.checkUser = true;
        if (this.post.scheduled_at) this.approveButtonText = "Approve and Schedule"
        else this.approveButtonText = "Approve and Publish"
      }
      return list;
    },
    hasIg() {
      return (
        this.post.social_media?.includes("instagram") ||
        this.post.social_media?.some((social) => social.name === "instagram")
      );
    },
  },
  methods: {
    deleteUnnecessaryFields(data, social_media) {
      if (!social_media) return data;
      if (!social_media.includes("youtube")) {
        delete data.configuration.yt_thumb;
        delete data.configuration.yt_post_type;
        delete data.configuration.yt_tags;
        delete data.configuration.yt_category;
        delete data.configuration.yt_title;
        delete data.configuration.yt_license;
      }
      if (!social_media.includes("facebook")) {
        delete data.configuration.fb_post_type;
        delete data.configuration.fb_pages;
      }
      if (!social_media.includes("instagram")) {
        delete data.configuration.ig_post_type;
        delete data.configuration.ig_users;
      }
      if (!social_media.includes("tumblr")) {
        delete data.configuration.blog;
      }
      if (!social_media.includes("reddit")) {
        delete data.configuration.subreddit;
      }
      if (!social_media.includes("pinterest")) {
        delete data.configuration.board;
        delete data.configuration.cover_image_url;
      }
      return data;
    },
    async handleYoutubeThumbnail(thumbnail) {
      let vm = this;
      let thumbType = typeof thumbnail;
      let storageRef;
      let hasYoutube = vm.post.social_media.includes("youtube");
      if (
        hasYoutube &&
        thumbType === "object" &&
        thumbnail &&
        thumbnail instanceof File
      ) {
        if (vm.WID === "default" && this.currentOriginalUID === this.currentUID) {
          storageRef = ref(
            storage,
            `users/${vm.currentUID}/youtube_thumbs/${new Date().getTime()}.png`
          );
        } else {
          storageRef = ref(
            storage,
            `users/${vm.currentUID}/${vm.WID}/youtube_thumbs/${new Date().getTime()}.png`
          );
        }
        await uploadBytes(storageRef, thumbnail);
        thumbnail = await getDownloadURL(storageRef);
        return thumbnail;
      }
    },
    handleScheduleAt(scheduledAt) {
      if (typeof scheduledAt === 'string') {
        return scheduledAt.trim();
      } else if (typeof scheduledAt === 'object' && scheduledAt.seconds) {
        return new Date(scheduledAt.seconds * 1000).toISOString();
      } else if (typeof scheduledAt === 'number') {
        return new Date(scheduledAt).toISOString();
      }
      return '';
    },
    async approve() {
      this.$loader.show()
      this.loading = true;
      const vm = this;
      try {
        const data = { ...this.post, id: this.postId };
        const dataId = data.id
        const updatedImages = this.post.images?.map((element) => {
          if (typeof element === "object" && element.url) {
            return element.thumbnail;
          }
          return element;
        });
        data.images = updatedImages;
        const updatedVideos = this.post.videos?.map((element) => {
          if (typeof element === "object" && element.url) {
            return element.url;
          }
          return element;
        });
        data.videos = updatedVideos;
        if (data.when_to_post === "immediate") {
          data.scheduled_at = "";
        }

        let hasYoutube = data.social_media.includes("youtube");
        const thumbnail = await this.handleYoutubeThumbnail(data.configuration.yt_thumb);

        if (data.scheduled_at) {
          // console.log("this is before")
          data.scheduled_at = await this.handleScheduleAt(data.scheduled_at);
          if (!hasYoutube) {
            if (data.configuration.yt_thumb) {
              delete data.configuration.yt_thumb;
            }
            delete data.configuration.yt_title;
            delete data.configuration.yt_tags;
            delete data.configuration.yt_category;
          }
        }
        if (data.approvedStatus) {
          data.approvedStatus = false;
        }

        if (data?.post_status == 'draft') {
          if (data.when_to_post == 'immediate') data.post_status = 'published'
          else data.post_status = 'scheduled'
        }
        const modifiedData = this.deleteUnnecessaryFields(data, data.social_media);
        let addSocialPost = httpsCallable(functions, "addSocialPost");
        let { postId } = (await addSocialPost(modifiedData)).data;
        // console.log("this is after",postId)

        let draftPostRef = doc(colUsers, this.currentUID, "fly_workspaces", this.WID, "social-posts", dataId);
        let draftSnap = await getDoc(draftPostRef);
        if (draftSnap.exists()) {
          await deleteDoc(draftPostRef);
        }
        let nameRef = doc(colUsers, this.currentOriginalUID);
        let namedoc = await getDoc(nameRef);

        let postRef = doc(colUsers, this.currentUID, "fly_workspaces", this.WID, "social-posts", postId);
        let postData = (await getDoc(postRef)).data();

        this.$pushNotification({
          uid: this.post.postBy,
          wid: this.WID,
          payload: {
            reply: true,
            sendPostMail: true,
            recieverUID: this.post.postBy,
            name: namedoc.data().display_name,
            image: postData.images[0],
            message: `${namedoc.data().display_name} approved your request on a post`,
            title: "Post approved",
            token: this.post.postBy,
            url: `/post/${this.currentUID}/${this.WID}/${postId}`,
            isEmail: true,
            status: true,
            text: data.text,
          }
        });

        this.$router.push(`/post/${vm.currentUID}/${vm.WID}/${postId}`);
        this.$nextTick(() => {
          location.reload();
        });
        this.$loader.hide();
        vm.loading = false;
      } catch (error) {
        this.$router.push(`/`);
        vm.loading = false;
        vm.handleError(error);
      }
      // this.draftLoading = false;
    },
    async handleReject() {
      const data = { ...this.post, id: this.postId };
      const dataId = data.id

      let nameRef = doc(colUsers, this.currentOriginalUID);
      let namedoc = await getDoc(nameRef);
      this.openRejectDialog = false;

      this.$loader.show();

      this.openRejectDialog = false;

      let draftPostRef = doc(colUsers, this.currentUID, "fly_workspaces", this.WID, "social-posts", dataId);
      let draftSnap = await getDoc(draftPostRef);
      if (draftSnap.exists()) {
        this.$pushNotification({
          uid: this.post.postBy,
          wid: this.WID,
          payload: {
            reply: true,
            sendPostMail: true,
            recieverUID: this.post.postBy,
            name: namedoc.data().display_name,
            image: this.post.images[0],
            message: `${namedoc.data().display_name} Rejected approval on a post`,
            title: "Post Rejected",
            token: this.currentUID,
            url: `/post/${this.currentUID}/${this.WID}/${dataId}`,
            isEmail: true,
            comment: this.rejectComment,
            text: this.post.post_text,
          }
        });
      }
      this.$loader.hide();
      this.$router.push(`/`);

    },
    formatHashtags(tags) {
      if (tags) {
        return tags
          .map((i) => {
            if (i.toString().startsWith("#")) return i;
            return `#${i}`;
          })
          .map((i) => i.split(" ").join(""))
          .toString()
          .split(",")
          .join(" ");
      }
      return '';
    },
    copyLink() {
      let uid = this.post.uid || this.$route.params.uid;
      let wid = this.post.wid || this.$route.params.wid || this.WID;
      let id = this.post.id || this.$route.params.id;
      const route = this.$router.resolve({
        name: "post",
        params: {
          uid: uid,
          wid: wid,
          id: id,
        },
      });
      let url = `${location.origin}${route.href}`;
      navigator.clipboard.writeText(url);
      this.$snackbar.show("URL Copied.");
    },
    isPostLive(post) {
      const currentDate = new Date();
      const scheduledDate = new Date(post.scheduled_at);
      return scheduledDate.getTime() < currentDate.getTime();
    },
    editPost(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "edit-post",
        params: {
          postId: id,
        },
      });

    },
    getScheduledDate(scheduledDate) {
      if (typeof scheduledDate == "object" && scheduledDate.seconds) {
        return this.formatDate(scheduledDate.seconds * 1000);
      } else if (typeof scheduledDate === "string") {
        return this.formatDate(scheduledDate);
      } else {
        return scheduledDate;
      }
    },
    editDraft(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "draft-post",
        params: {
          postId: id,
        },
      });
    },
    repost(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "repost",
        params: {
          postId: id,
        },
      });
    },
    deletePost(post) {
      let postId = this.$route.params.id ?this.$route.params.id: post.id  ;
      let data = {
        uid: post.uid,
        wid: post.wid,
        postId: postId,
        deleted_at: new Date()
      };
      let message = '';
      let isPostPublished = this.isPostLive(post);
      try {
        if (this.hasIg && isPostPublished) {
          message = 'Instagram: Post needs to be deleted manually';
        }
        this.$confirm.show({
          message: `Are you sure you want to delete this post?`,
          note: message,
          onConfirm: async () => {
            const postRef = doc(colUsers, post.uid, "fly_workspaces", post.wid, "social-posts", postId);
            try {
              if (post.post_status === "draft") {
                this.$loader.show(`Deletion in progress`);
                updateDoc(postRef, {
                  post_status: 'deleted',
                });
                this.$snackbar.show("Post deleted successfully!");
                this.$emit("onPostDelete");
                this.$loader.hide();
                return;
              } else if (post.scheduled_at && !isPostPublished) {
                data.was_scheduled_for = new Date(post.scheduled_at);
                data.action = 'delete';
                this.$snackbar.show("Your scheduled posts have been marked for deletion.");
                await updateDoc(postRef, {
                  post_status: 'deleted',
                });
                await setDoc(doc(colScheduledPostsDelete, postId), data);
              } else {
                this.$snackbar.show("Your published posts have been marked for deletion.");
                await updateDoc(postRef, {
                  post_status: 'deleted',
                });
                await setDoc(doc(colSocialPostsDelete, postId), data);
              }
            } catch (error) {
              this.$loader.hide();
              console.error("Error calling Cloud Function:", error);
              this.$alert.show("Something went wrong");
            }
          },
        });
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    },
  },
  beforeMount() {
    this.showOutput = this.openOutput == true;
  },
};
</script>

<style>
.status-published {
  color: #8056DE;
}

.status-deleted {
  color: red;
}

.status-scheduled {
  color: green;
}

.status-draft {
  color: orange;
}

.status-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button-disabled {
  background-color: #BDBDBD !important;
  color: #757575 !important;
}
</style>