<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      scrollable
      class="relative z-[999999999]"
    >
      <template v-slot:activator="{ on }">
        <span>
          <tool-tip :message="btnText">
            <v-btn
              icon
              depressed
              v-if="item.alias != 'around'"
              :disabled="!isActiveDomain"
              color="secondary"
              v-on="on"
            >
              <img
                v-if="item.connection"
                src="@/assets/img/reconnect.svg"
                class="my-auto aspect-square w-[17px]"
                @click="trackMixPanelReconnect()"
              />

              <img
                v-else-if="isActiveDomain"
                src="@/assets/img/connect.svg"
                class="aspect-[1.05] w-[21px]"
                @click="trackMixPanelConnect()"
              />
              <img
                v-else
                loading="lazy"
                src="@/assets/img/comingsoon.svg"
                class="aspect-[1.05] w-[21px]"
              />
            </v-btn>
          </tool-tip>
        </span>
      </template>

      <div class="">
        <v-card class="pa-2 bg-slate-100" height="100%">
          <v-card-title class="pt-3 pb-3 primary--text subtitle-1">
            <v-avatar size="30" class="mr-3">
              <v-img :src="item.logo_url" /> </v-avatar
            ><span :class="connectTextClass"
              >Connect your {{ item.name }} Account</span
            >

            <v-spacer />

            <v-btn icon color="red" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="mx-auto bg-white pa-md-10 pa-3">
            <v-btn
              text
              large
              :loading="isLoading"
              @click="handleButtonClick(item, profile)"
              :block="isMobile"
              class="w-[139.30px] h-12 bg-violet-500 rounded-xl"
              color="white"
            >
              <span class="text-sm"> Connect Now </span>
            </v-btn>
            <div v-if="item.intro_video">
              <v-row>
                <div
                  class="lg:w-[462px] xl:w-[590px] lg:h-[340px] xl:h-[430px] mt-10 w-full lg:shadow-[0px_10px_13px_1px_rgba(0,0,0,0.25)] lg:rounded-[20px] overflow-hidden"
                >
                  <v-col
                    cols="12"
                    md="6"
                    class="plyr__video-embed over"
                    ref="player"
                  >
                    <iframe
                      class="mt-5"
                      width="100%"
                      v-if="dialog && item.intro_video"
                      :src="item.intro_video"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                      @load="initializePlayer"
                    ></iframe>
                  </v-col>
                </div>

                <v-col cols="12" md="6">
                  <div
                    class="mt-1 ql-editor"
                    v-if="item.intro_content"
                    v-html="item.intro_content"
                  ></div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-model="showTermsDialog" persistent max-width="700px">
      <v-card>
        <v-card-title class="text-h5"
          >Terms of Service for <br class="d-sm-none" />{{
            item.name
          }}
          Integration</v-card-title
        >
        <v-card-text class="scrollable-content">
          <div>
            <p><strong>1. Account Credentials</strong></p>
            <p>
              To connect and post content to {{ item.name }} through our
              integration, you are required to provide your
              {{ item.name }} account credentials. Please be assured that your
              credentials are stored securely in an encrypted vault and are only
              used to access {{ item.name }} on your behalf.
            </p>

            <p><strong>2. Unofficial Integration</strong></p>
            <p>
              Please note that this is an unofficial integration with
              {{ item.name }}. We are not affiliated with, endorsed, or
              sponsored by {{ item.name }}. By choosing to use this integration,
              you accept and acknowledge that you are doing so at your own risk
              and liability. You agree to indemnify and hold harmless our
              service from any claims or damages related to your use of this
              integration.
            </p>

            <p><strong>3. Maintenance and Availability</strong></p>
            <p>
              Our integration with {{ item.name }} relies on the stability and
              availability of their web interfaces. Changes made to these
              interfaces by {{ item.name }} may affect the functionality or
              availability of our integration. We reserve the right to perform
              maintenance, which may result in temporary unavailability, and to
              modify or discontinue the integration, either temporarily or
              permanently, with or without notice to you. We will not be liable
              for any modification, suspension, or discontinuance of the
              service.
            </p>

            <p>
              <strong>4. Compliance with {{ item.name }} Requests</strong>
            </p>
            <p>
              If {{ item.name }} objects to or demands the cessation of this
              integration, we will comply with their request. In such an event,
              we will cease the integration and remove any related functionality
              from our service.
            </p>

            <p>
              By using this integration, you acknowledge and agree to these
              terms. Please ensure that you understand them fully before
              connecting your {{ item.name }} account.
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="fixed-action-buttons">
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="agreeTerms">I Agree</v-btn>
          <v-btn color="red" text @click="disagreeTerms">I Disagree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialog" persistent max-width="600px">
      <olx-connection
        v-if="item.name === 'Olx'"
        :item="item"
        :profile="profile"
        @close-dialog="showDialog = false"
      ></olx-connection>
      <quickr-connection
        v-else-if="item.name === 'Quickr'"
        :item="item"
        :profile="profile"
        @close-dialog="showDialog = false"
      ></quickr-connection>
      <mastodon-connection
        v-else-if="item.alias === 'mastodon'"
        :item="item"
        :profile="profile"
        @close-dialog="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import ToolTip from "../ui/ToolTip.vue";
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import "plyr/dist/plyr.css"; // Import the Plyr CSS
import Plyr from "plyr"; // Import the Plyr JS
import olxConnection from "./olx/olx.connection.vue";
import quickrConnection from "./quickr/quickr.connection.vue";
import MastodonConnection from "./mastodon/mastodon.connection.vue";
export default {
  components: { ToolTip, olxConnection, quickrConnection,MastodonConnection },
  mixins: [connectedAppsMixins],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: String,
      default: "personal",
    },
  },
  computed: {
    btnText() {
      // if (this.item.alias == "around") return "Connected";
      if (this.item.connection) return "Reconnect";
      if (this.isActiveDomain) return "Connect";
      return "Coming Soon";
    },
    isActiveDomain() {
      const currentHostname = window.location.hostname;
      return this.item.active_domains?.some(
        (domain) => new URL(domain).hostname === currentHostname
      );
    },
    connectTextClass() {
      return this.isSmallScreen ? "text-sm" : "";
    },
    isSmallScreen() {
      return window.innerWidth < 640;
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      connectDialog: false,
      showTermsDialog: false,
      loginDialog: false,
      termsAgreed: false,
      showDialog: false,
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (!this.player && this.$refs.player) {
            this.initializePlayer();
          }
        });
      } else if (this.player) {
        this.player = null;
      }
    },
  },
  methods: {
    initializePlayer() {
      if (this.$refs.player) {
        this.player = new Plyr(this.$refs.player, {
          controls: [
            "play-large",
            "play",
            "progress",
            "current-time",
            "mute",
            "volume",
            "fullscreen",
          ],
        });
        this.player.play();
      }
    },

    handleButtonClick(item, profile) {

      if (item.alias == "mastodon") {
        this.showDialog = true;
        this.termsAgreed = true;
        return;
      }

      if (item.type === "marketplace") {
        let termItems = ["Olx", "Quickr"];
        if (termItems.indexOf(item.name) != -1) {
          this.showTermsDialog = true; // This will show the terms dialog before proceeding
        }
      } else {
        this.initConnection(item.alias, profile); // Proceed with the normal flow for other types
      }
    },
    agreeTerms() {
      this.termsAgreed = true;
      this.showTermsDialog = false;
      this.showDialog = true;
    },
    disagreeTerms() {
      this.showTermsDialog = false;
    },
    handleLoginFormClose() {
      // Optional: handle actions when login form is closed
      this.termsAgreed = false;
    },

    trackMixPanelReconnect() {
      this.$mixpanel.track("Reconnect App Initiated");
    },
    trackMixPanelConnect() {
      this.$mixpanel.track("Connect App Initiated");
    },
  },

  mounted() {
    this.initializePlayer();
  },
};
</script>

<style scoped>
.scrollable-content {
  overflow-y: auto; /* Enable scrolling */
}

/* Smaller devices (tablets, large phones) */
@media (max-width: 1024px) {
  .scrollable-content {
    max-height: 70vh; /* Limit height to make it scrollable */
  }
}

/* Larger devices (laptops, desktops) */
@media (min-width: 1025px) {
  .scrollable-content {
    max-height: none; /* Remove max height to avoid unnecessary scrolling */
  }
}

.fixed-action-buttons {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px 0; /* Add padding to lift buttons off the bottom */
  width: 100%; /* Ensure it spans the width of the dialog */
}
.card-title {
  white-space: normal; /* Ensures text wraps */
}
</style>
