<template>
   <div
    class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
  >
    <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
    <div
          class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]"
        >
          <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
            <img
            src="@/assets/img/cal_img.svg"
              class="cursor-pointer w-6 md:w-[28px]"
              alt="post"
            />
            <div class="flex items-center gap-1">
              <header class="flex items-center px-6 py-4 lg:flex-none -ml-4">
      <div class="date-container">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          <time :datetime="currentDate.toISOString()" v-text="formatDate(currentDate)"></time>
        </h1>
      </div>
      <div class="flex items-center ghg">
        <div class="relative flex items-center rounded-md   md:items-stretch">
          <!-- Navigation Buttons -->
          <button type="button" class="rounded-l-md border border-r-0 py-2 px-3 text-gray-500 bg-slate-200 hover:bg-gray-100" @click="previousMonth">&lt;</button>
          <button type="button" class="py-2 px-3 text-sm font-semibold text-gray-900 bg-white  hover:bg-gray-100" @click="goToToday">Today</button>
          <button type="button" class="rounded-r-md border border-l-0 py-2 px-3 text-gray-500 bg- bg-slate-200 hover:bg-gray-100 mr-1" @click="nextMonth">&gt;</button>
          <!-- View Switch Buttons -->
          <div class="flex-grow">
            <div class="inline-flex rounded-full bg-gray-200 text-gray-500">
              <button class="rounded-l-full px-4 py-2" :class="{ 'bg-blue-500 text-white': currentView === 'month' }" @click="setView('month')">Month</button>
              <button class="rounded-r-full px-4 py-2" :class="{ 'bg-blue-500 text-white': currentView === 'week' }" @click="setView('week')">Week</button>
            </div>
          </div>
        </div>
      </div>
    </header>
            </div>
          </div>
        </div>
        <div class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl pt-[20px]">
  <div class="lg:flex lg:h-full lg:flex-col lg:block main-container" style="position: relative">
    <!-- Header and Navigation -->
  

    <!-- Days of Week Header -->
<div class="grid grid-cols-7">
  <div v-for="(dayName, index) in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" :key="dayName"
       :class="{
         'py-3 text-center font-semibold bg-[#3c50e0] text-white': true,
         'rounded-l-md': index === 0,
         'rounded-r-md': index === 6
       }">
    {{ dayName }}
  </div>
</div>

    <!-- Main Content Container -->
    <div class="main-content lg:flex">
      <!-- Calendar Container -->
      <div class="flex bg-gray-100 text-sm leading-6 text-gray-700 lg:flex-auto p-4">
        <div v-if="currentView === 'month'" class="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-2">
  <div
    v-for="day in monthDays"
    :key="day.toISOString()"
    class="relative p-4 bg-white rounded-lg shadow-sm date-cell cursor-pointer"
    :class="{
      'bg-white': day.getMonth() === currentDate.getMonth(),
      'bg-gray-50 text-gray-500': day.getMonth() !== currentDate.getMonth(),
      'border border-[#8050DE] rounded-lg': isToday(day),
      'date-passed': isDatePassed(day)
    }"
  >
    <div class="relative flex justify-between items-center">
      <time :datetime="day.toISOString()" :class="{ 'text-blue-600 font-bold': isToday(day) }" v-text="formatDay(day)"></time>
      <div class="plus-btn-container">
        <button v-if="isTodayOrAfter(day)" @click.stop="schedule(day)" class="plusbtn text-lg text-blue-600 mb-2">+</button>
      </div>
    </div>
    <div v-if="getHolidayName(day)" class="holiday-name text-black bg-pink-100 rounded-md px-2 mt-2 text-xs flex-wrap">
      {{ truncateText(getHolidayName(day), 16) }}
    </div>
    <div v-if="loadingStates[day.toISOString().split('T')[0]]" class="skeleton-loader mt-2 max-h-48 overflow-auto">
  <div class="skeleton-post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-200 rounded-md">
    <div class="skeleton-platform-logo w-2 h-2 bg-gray-300 rounded"></div>
    <div class="skeleton-text w-full bg-gray-300 rounded ml-2 h-2"></div>
  </div>
  <div class="skeleton-post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-200 rounded-md">
    <div class="skeleton-platform-logo w-2 h-2 bg-gray-300 rounded"></div>
    <div class="skeleton-text w-full bg-gray-300 rounded ml-2 h-2"></div>
  </div>
</div>

<div v-if="!loadingStates[day.toISOString().split('T')[0]] && posts && posts[day.toISOString().split('T')[0]]" class="mt-2 max-h-48 overflow-auto">
  <div
    v-for="(post, index) in displayedPosts(day).filter(post => post.post_status !== 'deleted')"
    :key="post.id"
    class="post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-50 rounded-md hover:bg-gray-200 transition duration-300"
    @click="selectedPost = post"
  >
    <div v-if="post.platformDetails && post.platformDetails?.length > 0" class="platform-logo relative">
      <img :src="post.platformDetails[0].logo_url" :alt="post.platformDetails[0].label" class="w-6 h-6" />
    </div>
    <p v-if="!post.scheduled_at && post.created_at">
      <span class="tooltip" data-tooltip="Created at"> </span>
      {{ formatDatepost(post.created_at) }}
    </p>
    <p v-if="post.when_to_post != 'immediate' && post.scheduled_at">
      {{ formatTime(post.scheduled_at) }}
    </p>
  </div>
  <button
    v-if="posts[day.toISOString().split('T')[0]] && posts[day.toISOString().split('T')[0]].filter(post => post.post_status !== 'deleted').length > 2"
    @click="showPostsInSidebar(day)"
    class="mt-2 text-blue-500"
  >
    {{
      expandedDay && expandedDay.toISOString() === day.toISOString() ? 'Show Less' : 'Show More'
    }}
  </button>
</div>



  </div>
</div>


      </div>

<!-- Sidebar for Selected Date -->
<div v-if="sidebarOpen && currentView === 'month' && !isMobile" class="sidebar" :style="{ transform: sidebarOpen ? 'translateX(0)' : 'translateX(100%)' }">
  <div class="flex justify-between items-center mb-4">
    <h2 class="text-lg font-semibold">Posts for {{ formatDate(selectedDay, 'sidebar') }}</h2>
    <button @click="closeSidebar" class="text-lg text-gray-600 mb-5">&#x2715;</button>
  </div>
  <div v-if="posts[selectedDay.toISOString().split('T')[0]]">
    <div v-for="post in posts[selectedDay.toISOString().split('T')[0]].filter(post => post.post_status !== 'deleted')" :key="post.id" class="post-container flex flex-col mt-2 cursor-pointer p-2 bg-gray-50 rounded-md hover:bg-gray-200 transition duration-300" @click="selectedPost = post">
      <div class="flex items-center mb-2">
  <div class="relative w-10 h-6 mr-2"> <!-- Adjust the width and height as needed -->
    <img v-if="post.platformDetails && post.platformDetails.length > 0" 
         :src="post.platformDetails[0].logo_url" 
         :alt="post.platformDetails[0].label" 
         class="absolute platform-logo w-6 h-6" />
    <img :src="getProfileImageUrl(post)" 
         alt="Profile Image" 
         class="absolute w-6 h-6 rounded-full" 
         @error="handleImageError" 
         style="left: 0.3rem;" />
  </div>
  <span class="text-xs text-gray-500">{{ formatTime(post.created_at) }}</span>
</div>

      <div class="flex items-center">
        <img v-if="post.images && post.images.length > 0" :src="post.images[0]" :alt="`Image for ${post.name}`" class="w-10 h-10 mr-2 rounded" @error="post.images[0] = 'https://via.placeholder.com/150'" />
        <img v-else-if="post.videos && post.videos.length > 0" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ63uWxSQ8n_tHow8Iymgy52T0rtBkDpyLa0BW8s54vRUwps2aHM441fbZsOyPrEAJUWWg&usqp=CAU" alt="Video thumbnail" class="w-10 h-10 mr-2 rounded" />
        <img v-else src="@/assets/img/redirect/defaultImage.png" alt="Default image" class="w-10 h-10 mr-2 rounded" />
        <p class="flex-grow">{{ truncateText(post.text, 18) }}</p>
      </div>
    </div>
  </div>
</div>

    </div>

    <!-- Calendar Days Grid -->
    <div class="flex bg-gray-100 text-sm leading-6 text-gray-700 lg:flex-auto">
      <div v-if="currentView === 'week'" class="w-full lg:grid lg:grid-cols-7 lg:gap-2">
  <div
    v-for="(day, index) in weekDays"
    :key="day.toISOString()"
    class="relative p-4 bg-white rounded-lg shadow-sm date-week"
    :class="{
      'bg-white': day.getMonth() === currentDate.getMonth(),
      'bg-gray-50 text-gray-500': day.getMonth() !== currentDate.getMonth(),
      'date-passed': isDatePassed(day),
      'border border-[#8050DE] rounded-lg': isToday(day)
    }"
  >
    <div class="flex justify-between items-center">
      <time :datetime="day.toISOString()" :class="{ 'text-blue-600 font-bold': isToday(day) }" v-text="day.getDate()"></time>
      <button v-if="isTodayOrAfter(day)" @click.stop="schedule(day)" class="plusbtn text-lg text-blue-600 mb-1">+</button>
    </div>
    <div v-if="getHolidayName(day)" class="holiday-name text-black bg-pink-100 rounded-md px-2 mt-2">
      {{ getHolidayName(day) }}
    </div>
    <div v-if="posts && posts[day.toISOString().split('T')[0]]" class="mt-2 max-h-48 overflow-auto">
      <div
        v-for="(post, index) in displayedWeeklyPosts(day)"
        :key="post.id"
        class="post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-50 rounded-md hover:bg-gray-200 transition duration-300"
        @click="selectedPost = post"
      >
        <div v-if="post.platformDetails && post.platformDetails.length > 0" class="platform-logo relative">
          <img :src="post.platformDetails[0].logo_url" :alt="post.platformDetails[0].label" class="w-6 h-6" />
        </div>
        <p v-if="!post.scheduled_at && post.created_at">
          <span class="tooltip" data-tooltip="Created at"> </span>
          {{ formatDatepost(post.created_at) }}
        </p>
        <p v-if="post.when_to_post != 'immediate' && post.scheduled_at">
          {{ formatTime(post.scheduled_at) }}
        </p>
      </div>
      <button
        v-if="posts[day.toISOString().split('T')[0]] && posts[day.toISOString().split('T')[0]].filter(post => post.post_status !== 'deleted').length > 10"
        @click="toggleWeeklyDay(day)"
        class="mt-2 text-blue-500"
      >
        {{
          expandedWeeklyDay && expandedWeeklyDay.toISOString() === day.toISOString() ? 'Show Less' : 'Show More'
        }}
      </button>
    </div>
  </div>
</div>

    </div>

    <div class="bg-gray-100 text-sm leading-6 text-gray-700 p-2 pb- rounded-b">
      <!-- Desktop Layout -->

      <!-- Mobile Layout -->
      <div v-if="isMobile" class="w-full grid grid-cols-1 gap-2">
        <div class="flex-grow bg-gray-100 text-sm leading-6 text-gray-700">
          <div v-if="currentView === 'month'" class="w-full grid grid-cols-7 gap-2">
            <div v-for="day in monthDays" :key="day.toISOString()" class="relative p-2 bg-white rounded-lg shadow-sm" :class="{
                'bg-white': day.getMonth() === currentDate.getMonth(),
                'bg-gray-50 text-gray-500': day.getMonth() !== currentDate.getMonth(),
                'border border-[#8050DE] rounded-lg': isToday(day),
              }">
              <div class="flex justify-between items-center">
                <time :datetime="day.toISOString()" :class="{ 'text-blue-600 font-bold': isToday(day) }" v-text="day.getDate()" @click="handleDayClick(day)"></time>
                <span v-if="shouldShowDot(day)" :class="dotClass(day)" @click="openDayDetails(day)" class="dot-indicator"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedPost" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full max-h-half overflow-y-auto relative post-card-container -ml-52 max-h-[600px]">
      <!-- Close Button -->
      <v-btn icon color="black" @click="selectedPost = null" class="absolute top-1 right-3">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="flex flex-col md:flex-row">
        <!-- Left Side: Post Content -->
        <div class="md:w-full pr-6 overflow-y-auto">
          <div class="flex items-center justify-between mb-4">
            <h3 class="text-2xl font-bold">{{ selectedPost.title }}</h3>
            <div class="flex space-x-2 justify-end">
              <v-btn v-if="selectedPost.when_to_post === 'schedule' && !isPostLive(selectedPost)" icon @click="editPost(selectedPost.id)">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="selectedPost.scheduled_at && !isPostLive(selectedPost)" icon @click="deletePost(selectedPost)">
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="selectedPost.media_type === 'video'">
  <iframe v-if="isYouTube(selectedPost.videos[0].src)" :src="getYoutubeEmbedUrl(selectedPost.videos[0].src)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video-container"></iframe>
  <video v-else-if="selectedPost.videos[0].src" controls class="video-container mb-4">
    <source :src="selectedPost.videos[0].src" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <img v-else-if="selectedPost.videos[0].thumbnail" :src="selectedPost.videos[0].thumbnail" alt="Video thumbnail" class="w-full h-auto max-h-80 rounded-md mb-4 object-contain">
</div>





          <img v-else-if="selectedPost.images && selectedPost.images.length > 0" :src="selectedPost.images[0]" :alt="`Image for ${selectedPost.title}`" class="w-full h-auto max-h-80 rounded-md mb-4 object-contain" />
          <p class="mb-4 whitespace-pre-line">{{ truncatedText }}</p>
          <button v-if="selectedPost.text.length > 200" @click="toggleTextExpansion" class="text-blue-500">
            {{ isTextExpanded ? "Show Less" : "Show More" }}
          </button>
          <div class="flex space-x-2">
            <div v-for="platform in selectedPost.platformDetails" :key="platform.name" class="platform-logo relative">
              <img :src="platform.logo_url" :alt="platform.label" class="w-8 h-8" />
            </div>
          </div>
        </div>
        <!-- Right Side: Post Stats and Post Info -->
        <div v-if="selectedPost.when_to_post !== 'schedule'" class="md:w-full mt-4 md:mt-0 pl-6 border-l flex flex-col space-y-4">
          <div class="bg-gray-100 p-4 rounded-lg shadow-md">
            <p class="font-bold text-lg mb-4">Engagement details</p>
            <div class="grid gap-4">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <v-icon color="blue">mdi-thumb-up</v-icon>Reactions
                </div>
                <span>{{ selectedPost.likes !== undefined ? (selectedPost.likes !== null ? selectedPost.likes : "-") : "-" }}</span>
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <v-icon color="orange">mdi-share</v-icon>Shares
                </div>
                <span class="text-black">{{ selectedPost.shares !== undefined ? (selectedPost.shares !== null ? selectedPost.shares : "-") : "-" }}</span>
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <v-icon color="green">mdi-comment</v-icon>Comments
                </div>
                <span class="text-black">{{ selectedPost.comment !== undefined ? (selectedPost.comment !== null ? selectedPost.comment : "-") : "-" }}</span>
              </div>
            </div>
          </div>
          <div class="bg-gray-100 p-4 rounded-lg shadow-md">
            <p class="font-bold text-lg mb-4">Post Info</p>
            <div class="grid gap-4">
              <div>
                <p class="font-medium">Published</p>
                <p>{{ formatselectpost(selectedPost.created_at) }}</p>
              </div>
              <div>
                <p class="font-medium">Post link</p>
                <a :href="selectedPost.post_url" target="_blank" class="text-blue-500 break-all">{{ selectedPost.post_url }}</a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedPost.when_to_post === 'schedule' && !isPostLive(selectedPost)" class="md:w-full mt-4 md:mt-0 pl-6 border-l flex flex-col space-y-4">
        <div class="bg-gray-100 p-4 rounded-lg shadow-md">
          <p class="font-bold text-lg mb-4">Scheduled Post Link</p>
          <div class="grid gap-4">
            <div>
              <p class="font-medium">Scheduled For</p>
              <p>{{ formatselectpost(selectedPost.scheduled_at) }}</p>
            </div>
            <div>
              <p class="font-medium cursor-pointer text-blue-600" @click="openPostDetaild(selectedPost)" >Open Post<v-icon color="primary">mdi-arrow-top-right</v-icon></p>
              <a :href="selectedPost.post_url" target="_blank" class="text-blue-500 break-all">{{ selectedPost.post_url }}</a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>

    <v-dialog v-model="scheduleDialog" persistent max-width="300px">
      <v-card>
        <v-card-title>Schedule for {{ selectedDate ? selectedDate.toDateString() : "Select Date" }}</v-card-title>
        <v-time-picker v-model="selectedTime"></v-time-picker>
        <v-card-actions>
          <v-btn color="primary" text @click="scheduleDialog = false">Close</v-btn>
          <v-btn color="primary" text @click="logSelectedDateTime">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailsDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-xs md:text-md">
          <span class="text-xs md:text-md">Details for {{ selectedDayDetails.date }}</span>
        </v-card-title>
        <v-card-text>
          <div v-if="selectedDayDetails.holidayName" class="holiday-name text-black bg-pink-100 rounded-md px-2">
            {{ selectedDayDetails.holidayName }}
          </div>
          <div v-for="post in selectedDayDetails.posts.filter(post => post.post_status !== 'deleted')" :key="post.id" class="post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-50 rounded-md hover:bg-gray-200 transition duration-300" @click="openPostDetails(post)">
            <p>{{ truncateName(post.name) }}</p>
            <img v-if="post.images && post.images.length > 0" :src="post.images[0]" :alt="`Image for ${post.name}`" class="aspect-square w-[23px] rounded-md" />
          </div>
          <div class="flex">
            <div v-if="selectedDayDetails.canSchedule" class="mt-2 mr-1">Schedule Post</div>
            <button v-if="selectedDayDetails.canSchedule" @click="schedule(selectedDayDetails.day)" class="plusbtn text-lg text-blue-600 my-1">+</button>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="detailsDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="!dismissed" class="onboarding-popup">
      <div @click="toggleOnboardingVisibility" class="flex items-center justify-between cursor-pointer">
        <h2 class="font-bold">Onboarding Steps</h2>
        <v-icon :name="isOnboardingVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="white"></v-icon>
        <v-icon v-if="isOnboardingVisible" color="white">mdi-chevron-down</v-icon>
        <v-icon color="white" v-if="!isOnboardingVisible">mdi-chevron-up</v-icon>
      </div>
      <div v-if="isOnboardingVisible">
        <div class="progress-text">{{ completedSteps }} of {{ totalSteps }} steps completed</div>
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="{ width: progressPercentage + '%' }"></div>
        </div>
        <div v-for="(item, index) in onboardingSteps" :key="index" class="checkbox-item flex justify-between">
          <input type="checkbox" :id="`step-${index}`" class="custom-checkbox" v-model="item.completed" />
          <label :for="`step-${index}`">
            <router-link :to="item.route" :style="{ color: 'white' }" :class="{ strikethrough: item.completed }">{{ item.name }}</router-link>
          </label>
          <router-link :to="item.route">
    <v-icon color="white cursor-pointer">mdi-chevron-right</v-icon>
  </router-link>
        </div>
        <div class="text-center bg-slate-800 mt-2 p-2 rounded-sm text-xs">
          <a href="https://calendly.com/fly-social/30min" target="_blank" class="need-help-link">Need help? Connect with a member of our team</a>
        </div>
        <button @click="dismissOnboarding" class="dismiss-button text-sm">Dismiss</button>
      </div>
    </div>
  </div>
  </div>
</section>
<v-dialog v-model="initialDialog" persistent max-width="470px">
  <v-card>
    <v-card-title class="headline">Thank you for signing up!</v-card-title>
    <v-card-text>
      To get started, we need to create a workspace for your brand.
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="openEditWorkspace">Create Workspace</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog v-model="afterDialog" persistent max-width="470px">
  <v-card>
    <v-card-title class="headline">Thank you for signing up!</v-card-title>
    <v-card-text>
      To get started, we need to create a workspace for your brand.
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="openWorkspace">Create Workspace</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog v-model="workspaceadd" persistent max-width="470px">
  <DialogWorkspaceAdd title="Add your workspace" @close="closeNameDialog" @submit="saveWorkspace">
        <!-- Workspace Name Field -->
        <v-card class="cursor-pointer">
          <v-text-field v-model="workspaceName" label="Workspace Name" prepend-icon="mdi-briefcase" class="ml-2 cursor-pointer"></v-text-field>
        </v-card>

        <!-- Website Field -->
        <v-card class="cursor-pointer mt-2">
          <v-text-field v-model="website" label="Website" prepend-icon="mdi-web" type="url" class="ml-2 cursor-pointer" :rules="[v => !!v || 'Website is required']" />
        </v-card>

        <!-- Business Description Field -->
        <v-card class="cursor-pointer mt-2">
  <v-textarea
    v-model="businessDescription"
    label="Business Description"
    prepend-icon="mdi-text"
    auto-grow
    :rules="[v => !!v || 'Business Description is required']"
  />
</v-card>


        <!-- Brand Logo Field -->
        <v-card class="cursor-pointer mt-2 mb-2">
              <v-file-input v-model="brandLogo" label="Upload Brand Logo" prepend-icon="mdi-upload" accept="image/*"
                class="ml-2 cursor-pointer"></v-file-input>
            </v-card>

        <!-- Country Field -->
        <v-card class="mt-2 p-2">
            <v-combobox
    v-model="countryCode"
    :items="countryOptions"
    label="Country"
    item-text="name"
    item-value="value"
    clearable
    return-object
    hide-selected
    dense
    :rules="[v => !!v || 'Country is required']"
  >
  </v-combobox>
</v-card>
      <v-card class="mt-2 p-2">
        <v-combobox
    v-model="timezone"
    :items="timezoneOptions"
    label="Timezone"
    item-text="label"
    item-value="value"
    prepend-icon="mdi-earth"
    clearable
    return-object
    hide-selected
    dense
    class="ml-2 cursor-pointer"
    :rules="[v => !!v || 'Time zone is required']"
  >
  </v-combobox>
</v-card>

        <!-- Primary Email Field -->
        <v-card class="cursor-pointer mt-2">
          <v-text-field
    v-model="primaryEmail"
    label="Primary Email"
    prepend-icon="mdi-email"
    type="email"
    class="ml-2 cursor-pointer"
    :rules="emailRules"
  ></v-text-field>
</v-card>
     

        <!-- Weekly Report Checkboxes -->
        <v-checkbox v-model="sendWeeklyReport" label="Send weekly social media performance report to primary email"></v-checkbox>
        <vue-tel-input-vuetify
  class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-14 rounded-[26px] mt-2 pb-3 mb-3"
  mode="international"
  v-model="phone_number"
  :rules="phoneRules"
  :validate-on-blur="true"
>
  <input
    type="text"
    class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
    placeholder="Phone Number"
  />
</vue-tel-input-vuetify>

        <v-checkbox v-model="sendWeeklyReportonphone" label="Send weekly social media performance report to primary phone number"></v-checkbox>
     
     </DialogWorkspaceAdd>
    </v-dialog>
    <v-dialog v-model="nameDialog" persistent max-width="470px">
  <DialogWorkspaceAdd title="Add your workspace" @close="closeNameDialog" @submit="editWorkspace">
        <!-- Workspace Name Field -->
        <v-card class="cursor-pointer">
          <v-text-field v-model="workspaceName" label="Workspace Name" prepend-icon="mdi-briefcase" class="ml-2 cursor-pointer"></v-text-field>
        </v-card>

        <!-- Website Field -->
        <v-card class="cursor-pointer mt-2">
          <v-text-field v-model="website" label="Website" prepend-icon="mdi-web" type="url" :rules="[v => !!v || 'Website is required']" class="ml-2 cursor-pointer" />
        </v-card>

        <!-- Business Description Field -->
        <v-card class="cursor-pointer mt-2">
  <v-textarea
    v-model="businessDescription"
    label="Business Description"
    prepend-icon="mdi-text"
    auto-grow
    :rules="[v => !!v || 'Business Description is required']"
  />
</v-card>


        <!-- Brand Logo Field -->
        <v-card class="cursor-pointer mt-2 mb-2">
              <v-file-input v-model="brandLogo" label="Upload Brand Logo" prepend-icon="mdi-upload" accept="image/*"
                class="ml-2 cursor-pointer"></v-file-input>
            </v-card>

        <!-- Country Field -->
        <v-card class="mt-2 p-2">
            <v-combobox
    v-model="countryCode"
    :items="countryOptions"
    label="Country"
    item-text="name"
    item-value="value"
    clearable
    return-object
    hide-selected
    dense
    :rules="[v => !!v || 'Country is required']"
  >
  </v-combobox>
</v-card>
      <v-card class="mt-2 p-2">
        <v-combobox
    v-model="timezone"
    :items="timezoneOptions"
    label="Timezone"
    item-text="label"
    item-value="value"
    prepend-icon="mdi-earth"
    clearable
    return-object
    hide-selected
    dense
    class="ml-2 cursor-pointer"
    :rules="[v => !!v || 'Time zone is required']"
  >
  </v-combobox>
</v-card>

        <!-- Primary Email Field -->
        <v-card class="cursor-pointer mt-2">
  <v-text-field
    v-model="primaryEmail"
    label="Primary Email"
    prepend-icon="mdi-email"
    type="email"
    class="ml-2 cursor-pointer"
    :rules="emailRules"
  ></v-text-field>
</v-card>

        <!-- Weekly Report Checkboxes -->
        <v-checkbox v-model="sendWeeklyReport" label="Send weekly social media performance report to primary email"></v-checkbox>
        <vue-tel-input-vuetify
  class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-14 rounded-[26px] mt-2 pb-3 mb-3"
  mode="international"
  v-model="phone_number"
  :rules="phoneRules"
  :validate-on-blur="true"
>
  <input
    type="text"
    class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
    placeholder="Phone Number"
  />
</vue-tel-input-vuetify>

        <v-checkbox v-model="sendWeeklyReportonphone" label="Send weekly social media performance report to primary phone number"></v-checkbox>
     
     </DialogWorkspaceAdd>
    </v-dialog>
</div>
</template>


<script>
import {
  doc,
  getDocs,
  collection,
  updateDoc,
  setDoc,
  getDoc
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  auth,
  colUsers,
  db,
  functions,
  colScheduledPostsDelete,
  colSocialPostsDelete,
  platforms,
} from "@/utils/firebase.utils.js";
import moment from "moment";
import DialogWorkspaceAdd from "../../components/ui/DialogWorkspaceAdd.vue";
import ProfileSelectfield from "../../components/ui/form/ProfileSelectfield.vue";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import countries from "i18n-iso-countries";
export default {
  components: {
    DialogWorkspaceAdd,
    ProfileSelectfield
  },
  data() {
    return {
          isLoading: false,
       isTextExpanded: false,
      isOnboardingVisible: true,
      dismissed: true,
      detailsDialog: false,
      selectedDayDetails: {
        day: null,
        date: "",
        holidayName: "",
        posts: [],
        canSchedule: false,
      },
      onboardingSteps: [
        // {
        //   name: "Complete  profile details",
        //   completed: false,
        //   route: "/edit-profile",
        //   key: "is_profile_completed",
        // },
        {
          name: "Connect your social handles",
          completed: false,
          route: "/connected-apps",
          key: "is_connected",
        },
        {
          name: "Publish your first post",
          completed: false,
          route: "/create",
          key: "is_posted",
        },
        {
          name: "Generate content ideas",
          completed: false,
          route: "/content-ai",
          key: "is_content_ai_generated",
        },
        // {
        //   name: "Generate content ideas using Fly",
        //   completed: false,
        //   route: "/create",
        //   key: "is_idea_generated",
        // },
      ],
      currentDate: new Date(),
      days: [],
      loadingStates: {}, 
      posts: {},
      currentView: "month",
      expandedDay: null,
      selectedPost: null,
      scheduleDialog: false,
      selectedDate: null,
      selectedTime: null,
      sidebarOpen: false,
      sidebarPosts: [],
      selectedDay: null,
      holidays: [], // Time selected by the user
      isMobile: window.innerWidth <= 768,
      expandedWeeklyDay: null,
      initialDialog: false,
      afterDialog:false,
      nameDialog: false,
      workspaceadd:false,
      workspaceName: "",
      website: "",
      businessDescription: "",
      primaryEmail: "",
      emailRules: [
      v => !!v || 'Email is required', // Check for non-empty input
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid' // Validate email format
    ],
      sendWeeklyReport: true,
      phone_number: "",
      phoneRules: [
      v => !!v || 'Phone number is required', // Checks for non-empty input
      v => (v && v.length >= 10) || 'Phone number must be valid' // Additional validation to ensure length
    ],
      sendWeeklyReportonphone: true,
      brandLogo: null,
      editableWorkspace: {},
      countryCode: "",
      countryOptions: null,
      timezone: "",
      timezones: moment.tz.names(),
      timezone: null, // This will hold the selected timezone
      timezoneOptions: this.getTimezoneOptions(),
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    monthDays() {
      const days = [];
      const year = this.currentDate.getUTCFullYear();
      const month = this.currentDate.getUTCMonth();

      const firstDay = new Date(Date.UTC(year, month, 1));
      const lastDay = new Date(Date.UTC(year, month + 1, 0));

      let startDay = new Date(
        Date.UTC(
          firstDay.getUTCFullYear(),
          firstDay.getUTCMonth(),
          firstDay.getUTCDate() - firstDay.getUTCDay()
        )
      );
      let endDay = new Date(
        Date.UTC(
          lastDay.getUTCFullYear(),
          lastDay.getUTCMonth(),
          lastDay.getUTCDate() + (6 - lastDay.getUTCDay())
        )
      );

      for (
        let day = new Date(startDay);
        day <= endDay;
        day.setUTCDate(day.getUTCDate() + 1)
      ) {
        days.push(new Date(day));
      }
      return days;
    },
    weekDays() {
      const days = [];
      const startOfWeek = new Date(
        Date.UTC(
          this.currentDate.getUTCFullYear(),
          this.currentDate.getUTCMonth(),
          this.currentDate.getUTCDate() - this.currentDate.getUTCDay()
        )
      );

      for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setUTCDate(day.getUTCDate() + i);
        days.push(day);
      }
      return days;
    },
    completedSteps() {
      return this.onboardingSteps.filter((step) => step.completed).length;
    },
    totalSteps() {
      return this.onboardingSteps.length;
    },
    progressPercentage() {
      return (this.completedSteps / this.totalSteps) * 100;
    },
    truncatedText() {
      if (this.selectedPost && this.selectedPost.text) {
        if (this.isTextExpanded) {
          return this.selectedPost.text;
        } else {
          return this.selectedPost.text.length > 200
            ? this.selectedPost.text.substring(0, 200) + "..."
            : this.selectedPost.text;
        }
      }
      return "";
    },
  },
  mounted() {
    this.fetchPosts();
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    this.fetchPostsByDateRange(firstDayOfMonth, lastDayOfMonth);
    window.addEventListener("resize", this.checkMobile);
    window.addEventListener("resize", () => {
      this.$forceUpdate(); // Re-render component on window resize to update the date format
    });
    document.body.classList.add("planner-page");

    this.fetchPosts();
    this.apiCall(); // Fetch posts when the component mounts
    this.initializeOnboardingSteps();
    this.fetchOnboardingSteps();
    if (localStorage.getItem('isNewUser') === 'true') {
    this.afterDialog = true;
    localStorage.removeItem('isNewUser'); // Remove the flag after showing the dialog
  } else {
    // If not a new user, call checkDefaultWorkspace
    setTimeout(() => {
      this.checkDefaultWorkspace();
    }, 3000);
  }
    this.initCountries();
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.$forceUpdate(); // Clean up the event listener
    });
    window.removeEventListener("resize", this.checkMobile);
    document.body.classList.remove("planner-page");
  },
  watch: {
    selectedPost(newPost, oldPost) {
      if (newPost) {
        this.closeSidebar();
      }
    },
    currentDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchPosts(); // Fetch posts again if date changes
      }
    },
    onboardingSteps: {
      handler(newSteps) {
        this.updateOnboardingStepsInFirestore(newSteps);
      },
      deep: true,
    },
  },
  methods: {
    openPostDetaild(post) {
    let uid = post.uid || this.$route.params.uid;
    let wid = post.wid || this.$route.params.wid;
    let id = post.id || this.$route.params.id;
    this.$router.push({
      name: 'post',
      params: {
        uid: uid,
        wid: wid,
        id: id,
      }
    });
  },
  getTimezoneOptions() {
  return moment.tz.names().map((tz) => {
    const tzOffset = moment.tz(tz).utcOffset(); // Get timezone offset in minutes
    const offsetHours = Math.floor(Math.abs(tzOffset) / 60);
    const offsetMinutes = Math.abs(tzOffset) % 60;
    const offsetSign = tzOffset >= 0 ? '+' : '-';
    const formattedOffset = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`; // Format as +hh:mm or -hh:mm

    return {
      label: `(UTC ${formattedOffset}) ${tz}`,
      value: tz,
    };
  });
},

    initCountries() {
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      this.countryOptions = Object.entries(countries.getNames("en")).map(
        ([code, name]) => ({
          name: name,
          value: code,
        })
      );
    },
    openEditWorkspace() {
    this.initialDialog = false; // Close the initial pop-up
    this.nameDialog = true;     // Open the Edit Workspace dialog
  },
  openWorkspace() {
    this.afterDialog = false; // Close the initial pop-up
     this.populateWorkspaceFields();
    this.workspaceadd = true;     // Open the Edit Workspace dialog
  },
    showDialogAfterSignUp() {
      this.initialDialog = true;
    localStorage.removeItem('isNewUser'); // Clear the flag after showing the dialog
  },
  closeNameDialog() {
    this.nameDialog = false; 
    this.clearEditFields();
  },
  triggerFileInput() {
    this.$refs.fileInput.click();
  },
  handleLogoChange(event) {
    const file = event.target.files[0];
    if (file) {
      this.brandLogo = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.editableWorkspace.logo_original = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  },
  isYouTube(url) {
    return url.match(/youtube\.com|youtu\.be/);
  },
  getYoutubeEmbedUrl(url) {
    let id = '';
    if (url.includes('youtu.be')) {
      id = url.split('/').pop();
    } else if (url.includes('youtube.com/shorts/')) {
      id = url.split('/shorts/')[1];
    } else if (url.includes('youtube.com')) {
      const urlParams = new URLSearchParams(new URL(url).search);
      id = urlParams.get('v');
    }
    return `https://www.youtube.com/embed/${id}?playsinline=1`; // Adding playsinline parameter for proper mobile behavior
  },
  handleVideoError(event) {
    console.error('Video loading error:', event);
  },
  handleImageError(event) {
    console.error('Image loading error:', event);
    event.target.src = 'https://via.placeholder.com/150';
  },
  async populateWorkspaceFields() {
  try {
    // Assuming vm.uid is the user's UID
    const userRef = doc(db, "users", auth.currentUser.uid);
    const userDoc = await getDoc(userRef);
console.log("userrr", userDoc)
    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log("SDS", userData)
      // Check if user document has the fields 'primaryEmail' and 'phoneNumber'
      this.primaryEmail = userData.email || '';
      console.log("emu",userData.email)
      this.phone_number = userData.phone_number || '';

      const workspaceRef = doc(db, "users", auth.currentUser.uid, "fly_workspaces", "default");
      const workspaceDoc = await getDoc(workspaceRef);

      if (workspaceDoc.exists()) {
        const workspaceData = workspaceDoc.data();

        // Populate the fields with data from Firestore
        this.workspaceName = workspaceData.name || '';
        this.website = workspaceData.websiteLink || '';
        this.businessDescription = workspaceData.businessDescription || '';
        this.countryCode = workspaceData.countryCode || '';
        this.timezone = workspaceData.timezone || '';
        this.sendWeeklyReport = workspaceData.on_primary_email_send || false;
        this.sendWeeklyReportonphone = workspaceData.on_phone_number_send || false;

        // Use user data if not defined in the workspace
        this.primaryEmail = this.primaryEmail || workspaceData.primary_email || '';
        this.phone_number = this.phone_number || workspaceData.phone_number || '';
      } else {
        console.log("Default workspace does not exist.");
      }
      // Open the dialog
      this.workspaceadd = true;
    } else {
      console.log("No user data found.");
      this.workspaceadd = true; // Show the dialog if the user data does not exist
    }
  } catch (error) {
    console.error("Error fetching workspace/user data: ", error);
    this.$snackbar.show("Failed to fetch workspace/user data. Please try again.");
  }
},
async checkDefaultWorkspace() {
        try {
            const workspaceRef = doc(db, "users", auth.currentUser.uid, "fly_workspaces", "default");
            const workspaceDoc = await getDoc(workspaceRef);

            if (workspaceDoc.exists()) {
                const workspaceData = workspaceDoc.data();

                // Check if the websiteLink and businessDescription are missing
                if (!workspaceData.websiteLink || !workspaceData.businessDescription) {
                    this.afterDialog = true; // Show the dialog if any of the fields are missing
                }
            } else {
                console.log("Default workspace does not exist.");
                this.afterDialog = true; // Show the dialog if the workspace does not exist
            }
        } catch (error) {
            console.error("Error fetching default workspace: ", error);
            this.$snackbar.show("Failed to fetch default workspace data. Please try again.");
        }
    },
  async editWorkspace() {
    try {
      const workspaceRef = doc(db, "users", auth.currentUser.uid, "fly_workspaces", "default");
      let logoURL = this.editableWorkspace.logo_original;

      const updateData = {};

      if (this.brandLogo && typeof this.brandLogo !== 'string') {
        const storageRef = getStorage();
        const logoRef = ref(storageRef, `logos/${auth.currentUser.uid}/${this.brandLogo.name}`);
        const snapshot = await uploadBytes(logoRef, this.brandLogo);
        logoURL = await getDownloadURL(snapshot.ref);
        updateData.logo_original = logoURL;
      }

      if (this.workspaceName) {
        updateData.name = this.workspaceName;
        updateData.brandName = this.workspaceName;
      }
      if (this.website) updateData.websiteLink = this.website;
      if (this.businessDescription) updateData.businessDescription = this.businessDescription;
      if (this.primaryEmail) updateData.primary_email = this.primaryEmail;
      if (typeof this.sendWeeklyReport === 'boolean') updateData.on_primary_email_send = this.sendWeeklyReport;
      if (this.phone_number) updateData.phone_number = this.phone_number;
      if (typeof this.sendWeeklyReportonphone === 'boolean') updateData.on_phone_number_send = this.sendWeeklyReportonphone;
      if (this.countryCode) updateData.countryCode = this.countryCode;
      if (this.timezone) updateData.timezone = this.timezone;

      if (Object.keys(updateData).length > 0) {
        await updateDoc(workspaceRef, updateData);
        this.$snackbar.show("Workspace Added successfully");
      } this.$store.commit('setBrandName', this.workspaceName);
      
      this.nameDialog = false;
      this.clearEditFields();
      window.location.reload();
    } catch (error) {
      console.error("Error updating workspace: ", error);
      this.$snackbar.show("Failed to update workspace. Please try again.");
    }
  },
  async saveWorkspace() {
    try {
      const workspaceRef = doc(db, "users", auth.currentUser.uid, "fly_workspaces", "default");
      let logoURL = this.brandLogo;

      const updateData = {};

      if (this.brandLogo && typeof this.brandLogo !== 'string') {
        const storageRef = getStorage();
        const logoRef = ref(storageRef, `logos/${auth.currentUser.uid}/${this.brandLogo.name}`);
        const snapshot = await uploadBytes(logoRef, this.brandLogo);
        logoURL = await getDownloadURL(snapshot.ref);
        updateData.logo_original = logoURL;
      }

      if (this.workspaceName) updateData.name = this.workspaceName;
      if (this.website) updateData.websiteLink = this.website;
      if (this.businessDescription) updateData.businessDescription = this.businessDescription;
      if (this.primaryEmail) updateData.primary_email = this.primaryEmail;
      if (typeof this.sendWeeklyReport === 'boolean') updateData.on_primary_email_send = this.sendWeeklyReport;
      if (this.phone_number) updateData.phone_number = this.phone_number;
      if (typeof this.sendWeeklyReportonphone === 'boolean') updateData.on_phone_number_send = this.sendWeeklyReportonphone;
      if (this.countryCode) updateData.countryCode = this.countryCode;
      if (this.timezone) updateData.timezone = this.timezone;

      if (Object.keys(updateData).length > 0) {
        await updateDoc(workspaceRef, updateData);
        this.$snackbar.show("Workspace updated successfully");
      }

      this.workspaceadd = false;
      this.clearEditFields();
      window.location.reload();
    } catch (error) {
      console.error("Error updating workspace: ", error);
      this.$snackbar.show("Failed to update workspace. Please try again.");
    }
  },

  // Clears the fields after saving
  clearEditFields() {
    this.workspaceName = "";
    this.website = "";
    this.businessDescription = "";
    this.primaryEmail = "";
    this.sendWeeklyReport = false;
    this.phone_number = "";
    this.sendWeeklyReportonphone = false;
    this.brandLogo = null;
  },
  clearEditFields() {
    this.workspaceName = "";
    this.website = "";
    this.businessDescription = "";
    this.primaryEmail = "";
    this.sendWeeklyReport = false;
    this.phone_number = "";
    this.sendWeeklyReportonphone = false;
    this.brandLogo = null;
  },
    getProfileImageUrl(post) {
    if (post.profile_image_url && typeof post.profile_image_url === 'string') {
      return post.profile_image_url;
    } else {
      return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi1p8_WaFcY_wt5qn3Vd0_VpkTtyo9AFeSB0PT3iVz1A&s";
    }
  },
  handleImageError(event) {
    event.target.src = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi1p8_WaFcY_wt5qn3Vd0_VpkTtyo9AFeSB0PT3iVz1A&s";
  },
    formatDay(day) {
      if (day.getDate() === 1) {
        return `1 ${day.toLocaleString('default', { month: 'short' })}`;
      }
      return day.getDate();
    },
    isDatePassed(day) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset hours to compare dates only
      return day < today;
    },
    toggleTextExpansion() {
      this.isTextExpanded = !this.isTextExpanded;
    },
    formatselectpost(dateInput) {
  // Define options for displaying the date in "August 6, 2024 11:45" format
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false // Use 24-hour format if desired, or remove for 12-hour format
  };

  let date;

  // Check if input is a Firestore Timestamp object
  if (dateInput && typeof dateInput === 'object' && dateInput.seconds !== undefined) {
    // Convert Firestore Timestamp to JavaScript Date object
    date = new Date(dateInput.seconds * 1000 + dateInput.nanoseconds / 1000000);
    console.log("Converted Firestore Timestamp to Date:", date);
  } else if (typeof dateInput === 'string') {
    // Handle string input as before
    if (dateInput.includes('T')) {
      date = new Date(dateInput);
    } else {
      const regex = /^(\d+)\s(\w+)\s(\d+)\sat\s(\d+):(\d+):(\d+)\sUTC([+-]\d+:\d+)$/;
      const parts = dateInput.match(regex);
      if (parts) {
        const [_, day, month, year, hour, minute, second, timezone] = parts;
        date = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}${timezone}`);
      } else {
        console.error("Date format does not match expected patterns:", dateInput);
      }
    }
  } else {
    console.error("Invalid or missing date input:", dateInput);
    return "Invalid date format";
  }

  // Format and return the date if valid
  if (date && !isNaN(date.getTime())) {
    return date.toLocaleDateString("en-US", options);
  } else {
    console.error("Failed to parse date, resulting date is invalid:", date);
    return "Invalid date";
  }
}

,
    closeSidebar() {
      this.sidebarOpen = false;
    },
    truncateText(text, maxLength) {
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    formatDatepost(date) {
      if (!date) return "Date not provided";

      try {
        let parsedDate;

        // If date is a Firestore Timestamp object, convert it to a JS Date object
        if (
          typeof date === "object" &&
          date.seconds !== undefined &&
          date.nanoseconds !== undefined
        ) {
          date = new Date(
            date.seconds * 1000 + date.nanoseconds / 1000000
          ).toISOString();
        }

        // Check if the date is in ISO format
        if (
          typeof date === "string" &&
          date.includes("T") &&
          date.includes("Z")
        ) {
          parsedDate = moment(date); // ISO string is directly parsable by moment
        } else if (typeof date === "string") {
          // Preprocess the custom date format to convert it into a parsable format
          const customFormatRegex =
            /(\d{1,2} \w+ \d{4} at \d{2}:\d{2}:\d{2}) UTC([+-]\d{1,2}:\d{2})/;
          const match = date.match(customFormatRegex);
          if (match) {
            const dateTimePart = match[1]; // "26 July 2024 at 12:18:34"
            const timeZonePart = match[2]; // "+5:30" or "-5:30"
            const processedDate = `${dateTimePart}${timeZonePart}`;
            parsedDate = moment(processedDate, "D MMMM YYYY at HH:mm:ssZ");
          } else {
            throw new Error("Invalid date format");
          }
        } else {
          throw new Error("Invalid date type");
        }

        if (!parsedDate.isValid()) throw new Error("Invalid date");

        // Format the time to "HH:mm"
        return parsedDate.format("HH:mm");
      } catch (error) {
        console.error("Error formatting date:", date, error);
        return "Invalid date";
      }
    },
    formatTime(dateInput) {
  let date;

  // Check if input is a Firestore Timestamp object
  if (dateInput && typeof dateInput === 'object' && dateInput.seconds !== undefined) {
    // Convert Firestore Timestamp to JavaScript Date object
    date = new Date(dateInput.seconds * 1000 + dateInput.nanoseconds / 1000000);
  } else if (typeof dateInput === 'string') {
    // Handle string input
    if (dateInput.includes('T')) {
      date = new Date(dateInput);
    } else {
      const regex = /^(\d+)\s(\w+)\s(\d+)\sat\s(\d+):(\d+):(\d+)\sUTC([+-]\d+:\d+)$/;
      const parts = dateInput.match(regex);
      if (parts) {
        const [_, day, month, year, hour, minute, second, timezone] = parts;
        date = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}${timezone}`);
      } else {
        console.error("Date format does not match expected patterns:", dateInput);
      }
    }
  } else {
    console.error("Invalid or missing date input:", dateInput);
    return "Invalid date format";
  }

  // Format and return the time if valid
  if (date && !isNaN(date.getTime())) {
    return date.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false });
  } else {
    console.error("Failed to parse date, resulting date is invalid:", date);
    return "Invalid date";
  }
},
    handleDayClick(day) {
      if (this.isTodayOrAfter(day)) {
        this.schedule(day); // Perform scheduling action
      }
    },
    shouldShowDot(day) {
      return (
        this.hasActivePosts(day.toISOString().split("T")[0]) ||
        this.getHolidayName(day)
      );
    },
    dotClass(day) {
      const dateStr = day.toISOString().split("T")[0];
      if (this.hasActivePosts(dateStr)) {
        return "dot-blue";
      } else if (this.getHolidayName(day)) {
        return "dot-orange";
      }
      return "";
    },
    hasActivePosts(dateStr) {
      // Check if there are any non-deleted posts for the given date string
      const postsForDay = this.posts[dateStr] || [];
      return postsForDay.some((post) => post.post_status !== "deleted");
    },
    openDayDetails(day) {
      this.selectedDayDetails.day = day;
      this.selectedDayDetails.date = moment(day).format("MMMM Do, YYYY");
      this.selectedDayDetails.holidayName = this.getHolidayName(day);
      this.selectedDayDetails.posts =
        this.posts[day.toISOString().split("T")[0]] || [];
      this.selectedDayDetails.canSchedule = this.isTodayOrAfter(day);
      this.detailsDialog = true;
    },
    openPostDetails(post) {
      this.selectedPost = post;
      this.detailsDialog = false; // Close the details dialog when opening the post
    },
    isTodayOrAfter(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset hours to compare dates only
      return date >= today;
    },
    schedule(day) {
      console.log("Scheduling for:", day); // Implement your scheduling logic or trigger a modal for scheduling
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    editPost(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "edit-post",
        params: {
          postId: id,
        },
      });
    },
    isPostLive(post) {
      console.log("inside");
      if (!post.scheduled_at) return false; // Return false if scheduled_at is not present

      // Convert Firestore Timestamp to JavaScript Date
      let scheduledDate;
      if (
        post.scheduled_at.seconds !== undefined &&
        post.scheduled_at.nanoseconds !== undefined
      ) {
        scheduledDate = new Date(post.scheduled_at.seconds * 1000);
      } else {
        scheduledDate = new Date(post.scheduled_at);
      }

      // if (isNaN(scheduledDate.getTime())) {
      //   console.error("Invalid date format for scheduled_at:", post.scheduled_at);
      //   return false; // Return false if the date is invalid
      // }

      const currentDate = new Date();
      // console.log("scheduledDate.getTime() < currentDate.getTime():", scheduledDate, scheduledDate.getTime(), scheduledDate.getTime() < currentDate.getTime());

      return scheduledDate.getTime() < currentDate.getTime();
    },
    async deletePost(post) {
      let postId = post.id ? post.id : this.$route.params.id;
      let data = {
        uid: post.uid,
        wid: post.wid,
        postId: postId,
        deleted_at: new Date(),
      };
      try {
        this.$confirm.show({
          message: `Are you sure you want to delete this post?`,
          onConfirm: async () => {
            const postRef = doc(
              colUsers,
              post.uid,
              "fly_workspaces",
              post.wid,
              "social-posts",
              postId
            );

            // Convert Firestore Timestamp to JavaScript Date
            let scheduledDate;
            if (
              post.scheduled_at.seconds !== undefined &&
              post.scheduled_at.nanoseconds !== undefined
            ) {
              scheduledDate = new Date(post.scheduled_at.seconds * 1000);
              scheduledDate = moment(scheduledDate).format("YYYY-MM-DD");
            } else {
              scheduledDate = new Date(post.scheduled_at);
              scheduledDate = moment(scheduledDate).format("YYYY-MM-DD");
            }

            //   if (isNaN(scheduledDate.getTime())) {
            //     console.error("Invalid date format for scheduled_at:", post.scheduled_at);
            //     this.$alert.show("Invalid scheduled date");
            //     return;
            //   }
            console.log("SJ", scheduledDate);

            const calendarPostRef = doc(
              colUsers,
              post.uid,
              "fly_workspaces",
              post.wid,
              "calendar",
              scheduledDate,
              "posts",
              postId
            );

            let isPostPublished = this.isPostLive(post);
            try {
              if (post.post_status === "draft") {
                this.$loader.show(`Deletion in progress`);
                await updateDoc(postRef, {
                  post_status: "deleted",
                });
                await updateDoc(calendarPostRef, {
                  post_status: "deleted",
                });
                this.$snackbar.show("Post deleted successfully!");
                this.$emit("onPostDelete");
                this.$loader.hide();
              } else if (post.scheduled_at && !isPostPublished) {
                data.was_scheduled_for = scheduledDate;
                data.action = "delete";
                this.$snackbar.show(
                  "Your scheduled posts have been marked for deletion."
                );
                await updateDoc(postRef, {
                  post_status: "deleted",
                });
                await updateDoc(calendarPostRef, {
                  post_status: "deleted",
                });
                await setDoc(doc(colScheduledPostsDelete, postId), data);
              } else {
                this.$snackbar.show(
                  "Your published posts have been marked for deletion."
                );
                await updateDoc(postRef, {
                  post_status: "deleted",
                });
                await updateDoc(calendarPostRef, {
                  post_status: "deleted",
                });
                await setDoc(doc(colSocialPostsDelete, postId), data);
              }
              const dateStr = scheduledDate;
              const updatedPosts = this.posts[dateStr].filter(
                (p) => p.id !== postId
              );
              this.$set(this.posts, dateStr, [...updatedPosts]);
              this.selectedPost = null;
            } catch (error) {
              this.$loader.hide();
              console.error("Error calling Cloud Function:", error);
              this.$alert.show("Something went wrong");
            }
          },
        });
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    },
    truncateName(name) {
      return name.length > 20 ? name.substring(0, 20) + "..." : name;
    },
    truncateText(text, maxLength) {
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    async apiCall() {
      const axios = require("axios");
      const currentYear = new Date().getFullYear(); // Get the current year dynamically

      try {
        const response = await axios.get(
          `https://holidays.abstractapi.com/v1/?api_key=188459ed78f24764b46365877c70ee5c&country=${this.authUser.country_code}&year=${currentYear}`
        );
        this.holidays = response.data; // Store the holiday data in the component's data
        console.log(this.holidays);
      } catch (error) {
        console.log(error);
      }
    },
    getHolidayName(date) {
      const dateString = moment(date).format("MM/DD/YYYY");
      const holiday = this.holidays.find(
        (holiday) => holiday.date === dateString
      );
      return holiday ? holiday.name : null;
    },
    isTodayOrAfter(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset hours to compare dates only
      return date >= today;
    },
    logSelectedDateTime() {
      let type = ["social"];
      this.$store.commit("SET_FLY_PLATFORMS_TYPE", type);
      // Assuming this.selectedTime contains the time in the format "HH:mm"
      const timeParts = this.selectedTime.split(":");
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);

      // Combine date and time using moment.js
      const combinedDateTime = moment(this.selectedDate).set({
        hour: hours,
        minute: minutes,
      });

      // Format the combined date and time
      const formattedDateTime = combinedDateTime.format(
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );

      console.log(`Selected Date and Time: ${formattedDateTime}`);
      // console.log(`Selected Date and Time: ${this.selectedDate}, ${this.selectedTime}`);
      this.$router.push({
        name: "create-post",
        params: {
          scheduledTime: formattedDateTime,
        },
      });
    },
    schedule(date) {
      this.selectedDate = date;
      this.scheduleDialog = true;
    },
    displayedPosts(day) {
  const dateStr = day.toISOString().split("T")[0];
  const postsForDay = this.posts[dateStr] || [];
  const hasHoliday = this.getHolidayName(day);

  if (
    this.expandedDay &&
    this.expandedDay.toISOString() === day.toISOString()
  ) {
    return postsForDay; // Return all posts if the day is expanded
  }

  if (hasHoliday && postsForDay.length > 1) {
    return postsForDay.slice(0, 1); // Show only one post if there's a holiday and more than one post
  }

  return postsForDay.slice(0, 2); // Show up to two posts otherwise
},

    toggleDay(day) {
      if (
        this.expandedDay &&
        this.expandedDay.toISOString() === day.toISOString()
      ) {
        this.expandedDay = null; // Collapse the day if it is already expanded
      } else {
        this.expandedDay = day; // Expand the selected day
      }
    },
    async fetchAllPlatforms() {
      const platformsRef = collection(db, "fly_platforms"); // Adjust the collection path if necessary
      const snapshot = await getDocs(platformsRef);
      let platforms = [];
      snapshot.forEach((doc) => {
        platforms.push({ id: doc.id, ...doc.data() });
      });
      return platforms;
    },
    async fetchPosts() {
  const userId = auth.currentUser.uid;

  // Get the current date and set it to the start of today
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset hours to compare dates only

  // Get the start of tomorrow
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Set a future limit, e.g., 6 months from today
  const futureLimit = new Date();
  futureLimit.setMonth(futureLimit.getMonth() + 6);

  // Fetch platform data once and store it
  const allPlatforms = await this.fetchAllPlatforms();

  this.posts = {};

  // Start the loop from today or tomorrow depending on the condition
  for (
    let d = new Date(today);
    d <= futureLimit;
    d.setDate(d.getDate() + 1)
  ) {
    const dateStr = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(
      -2
    )}-${("0" + d.getDate()).slice(-2)}`; // Ensuring proper ISO string format
    const postsRef = collection(
      db,
      "users",
      userId,
      "fly_workspaces",
      this.WID,
      "calendar",
      dateStr,
      "posts"
    );

    try {
      const querySnapshot = await getDocs(postsRef);
      if (!querySnapshot.empty) {
        const postsWithPlatformData = querySnapshot.docs.map((doc) => {
          let postData = { id: doc.id, ...doc.data() };

          // Enrich posts with platform details
          if (postData.social_media) {
            postData.platformDetails = postData.social_media.map(
              (media) => {
                const platform = allPlatforms.find(
                  (p) => p.alias === media
                );
                return platform
                  ? {
                      name: media,
                      label: platform.name,
                      logo_url: platform.logo_url,
                    }
                  : { name: media, label: "Unknown", logo_url: "" };
              }
            );
          }

          // Determine the start date for fetching
          const fetchStartDate = postData.when_to_post === "schedule" ? today : tomorrow;

          // Only include the post if the date matches the fetch start date
          if (d >= fetchStartDate) {
            return postData;
          }

          return null;
        }).filter(post => post !== null);

        this.posts[dateStr] = postsWithPlatformData;
      }
    } catch (error) {
      console.error("Error fetching posts for", dateStr, ":", error);
    }
  }
},
async fetchPostsByDateRange(startDate, endDate) {
  const fetchSocialPosts = httpsCallable(functions, "fetchSocialPosts"); // Define the callable function
  const payload = {
    uid: this.$store.getters.currentUID, // Get the user ID from Vuex store
    wid: this.WID, // Workspace ID from your component data or Vuex store
    operation: "fetchDateRangePosts",
    startDate: moment(startDate).startOf('day').format('YYYY-MM-DD'), // Ensures the whole day is covered
    endDate: moment(endDate).endOf('day').format('YYYY-MM-DD'), // Ensures the whole day is covered
  };

  console.log("payload month", payload);

  try {
    const response = await fetchSocialPosts(payload);
    console.log("Response from fetchSocialPosts:", response);
    const data = response.data.result;
    console.log("Data received:", data);

    const allPlatforms = await this.fetchAllPlatforms(); // Fetch platform data once

    if (Array.isArray(data)) {
      for (const post of data) {
        post.when_to_post = "immediate"; // Add when_to_post field to each post
        let dateStr = post.created_time || post.created_at;

        // Convert dateStr to 'YYYY-MM-DD' format if it is not already in that format
        if (dateStr && !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
          const dateObj = new Date(dateStr);
          const year = dateObj.getFullYear();
          const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
          const day = ("0" + dateObj.getDate()).slice(-2);
          dateStr = `${year}-${month}-${day}`;
        }

        // Set loading state for this date
        if (!this.loadingStates[dateStr]) {
          this.$set(this.loadingStates, dateStr, true); // Set loading state to true
        }

        // Enrich posts with platform details
        const platform = allPlatforms.find((p) => p.alias === post.platform);
        post.platformDetails = platform
          ? [
              {
                name: post.platform,
                label: platform.name,
                logo_url: platform.logo_url,
              },
            ]
          : [
              {
                name: post.platform,
                label: "Unknown",
                logo_url: "",
              },
            ];

        // Fetch profile picture
        const profilePicturePayload = {
          uid: this.currentUID,
          wid: this.WID,
          platform: post.platform,
          profile: post.profile_id,
        };

        const fetchProfilePicture = httpsCallable(functions, "fetchProfilePicture");
        const pictureResult = await fetchProfilePicture(profilePicturePayload);

        // Ensure the profile image URL exists
        post.profile_image_url = pictureResult && pictureResult.data ? pictureResult.data : ''; // Default or placeholder image if no URL is found

        if (!this.posts[dateStr]) {
          this.$set(this.posts, dateStr, []); // Initialize array if not present
        }
        this.posts[dateStr].push(post); // Append the post to the corresponding date array

        // Set loading state for this date to false
        this.$set(this.loadingStates, dateStr, false);
      }
    } else {
      console.error("Data is not an array:", data);
    }
  } catch (error) {
    console.error("Error fetching posts by date range:", error);
    // Handle any errors by setting all relevant loading states to false
    Object.keys(this.loadingStates).forEach(dateStr => {
      this.$set(this.loadingStates, dateStr, false);
    });
  }
}

,
    setView(view) {
      this.currentView = view;
    },
    nextMonth() {
      if (this.currentView === "week") {
        // Advance by one week
        let newDate = new Date(this.currentDate.getTime());
        newDate.setDate(this.currentDate.getDate() + 7);
        this.currentDate = newDate;
      } else {
        // Advance by one month
        let newDate = new Date(this.currentDate.getTime());
        newDate.setMonth(this.currentDate.getMonth() + 1);
        this.currentDate = newDate;
      }
      this.fetchPosts();

      const startOfNextMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endOfNextMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      this.fetchPostsByDateRange(startOfNextMonth, endOfNextMonth);
    },
    previousMonth() {
      if (this.currentView === "week") {
        // Move back by one week
        let newDate = new Date(this.currentDate.getTime());
        newDate.setDate(this.currentDate.getDate() - 7);
        this.currentDate = newDate;
      } else {
        let newDate = new Date(this.currentDate.getTime());
        newDate.setMonth(this.currentDate.getMonth() - 1);
        this.currentDate = newDate;
      }
      const startOfPrevMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endOfPrevMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      this.fetchPosts();
      this.fetchPostsByDateRange(startOfPrevMonth, endOfPrevMonth);
    },
    goToToday() {
      this.currentDate = new Date(); // Reset to the current date
      const startOfCurrentMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endOfCurrentMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      this.fetchPostsByDateRange(startOfCurrentMonth, endOfCurrentMonth);
    },
    formatDate(date, formatType = "default") {
      const isMobile = window.innerWidth <= 768;
      let options;

      if (formatType === "sidebar") {
        // Format for the sidebar
        options = { year: "numeric", month: "short", day: "numeric" };
      } else {
        // Default or mobile format
        options = isMobile
          ? { month: "short", year: "numeric" } // Short month format for mobile
          : { month: "long", year: "numeric" }; // Full month format otherwise
      }

      return date.toLocaleDateString("en-US", options);
    },
    isToday(day) {
      const today = new Date();
      return (
        day.getDate() === today.getDate() &&
        day.getMonth() === today.getMonth() &&
        day.getFullYear() === today.getFullYear()
      );
    },
    showPostsInSidebar(day) {
      this.selectedDay = day;
      this.sidebarOpen = true;
    },
    toggleOnboardingVisibility() {
      this.isOnboardingVisible = !this.isOnboardingVisible;
    },
    async fetchOnboardingSteps() {
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.onboarding_steps) {
            const stepsData = data.onboarding_steps;
            this.onboardingSteps = this.onboardingSteps.map((step) => ({
              ...step,
              completed: stepsData[step.key] || false,
            }));
          }
          this.dismissed = data.dismissed !== undefined ? data.dismissed : false;
        } else {
          console.log("No user data found in Firestore, using defaults.");
        }
      } catch (error) {
        console.error("Error fetching onboarding data from Firestore: ", error);
      }
    },
    async dismissOnboarding() {
      this.dismissed = true;
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { dismissed: this.dismissed });
        console.log("Dismiss state updated in Firestore!");
      } catch (error) {
        console.error("Error updating dismiss state in Firestore: ", error);
      }
    },
    async initializeOnboardingSteps() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || !docSnap.data().onboarding_steps) {
        await setDoc(docRef, { onboarding_steps: stepsData }, { merge: true });
        console.log("Onboarding steps initialized in Firestore.");
      } else {
        console.log("Firestore already has onboarding steps.");
      }
    },
    async updateOnboardingStepsInFirestore() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      try {
        const docRef = doc(db, "users",auth.currentUser.uid);
        await updateDoc(docRef, { onboarding_steps: stepsData });
        console.log("Onboarding steps updated in Firestore successfully!");
      } catch (error) {
        console.error("Error updating onboarding steps in Firestore: ", error);
      }
    },
    displayedWeeklyPosts(day) {
      const dateStr = day.toISOString().split("T")[0];
      if (
        this.expandedWeeklyDay &&
        this.expandedWeeklyDay.toISOString() === day.toISOString()
      ) {
        return this.posts[dateStr]; // Return all posts if the day is expanded
      }
      return this.posts[dateStr].slice(0, 15); // Return only the first 10 posts otherwise
    },
    toggleWeeklyDay(day) {
      if (
        this.expandedWeeklyDay &&
        this.expandedWeeklyDay.toISOString() === day.toISOString()
      ) {
        this.expandedWeeklyDay = null; // Collapse the day if it is already expanded
      } else {
        this.expandedWeeklyDay = day; // Expand the selected day
      }
    },
  },
};
</script>


<style scoped>
.date-cell {
  height: 150px; /* Adjust the height as needed */
  width: 100%; /* Ensure full width within the grid cell */
  position: relative; /* Ensure relative positioning for absolute child elements */
}
.date-week{
  height: 580px; /* Adjust the height as needed */
  width: 100%; /* Ensure full width within the grid cell */
  position: relative; /* Ensure relative positioning for absolute child elements */
}
.plus-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  display: none; /* Hide the plus button by default */
}

.date-cell:hover .plus-btn-container {
  display: block; /* Show the plus button when the date cell is hovered */
}

.plusbtn {
  border: 1px solid gray; /* Add border here */
  padding: 1px 8px;
  border-radius: 10%;
  background-color: white;
}

.today-highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #8050de;
  color: white;
  border-radius: 50%;
  font-weight: bold;
}
.ghg {
  flex: 1 0 auto;
  max-width: 100%;
}
.post-container {
  border: 1px solid #ccc; /* Change color as needed */
  border-radius: 10px; /* Adjust radius as needed for desired roundness */
  padding: 2px; /* Add padding to prevent content from touching the edges */
  margin-bottom: 2px; /* Add some space between posts */
  background-color: white; /* Optional: Change background color */
}
.plusbtn {
  border: 1px solid gray;
  padding: 1px 8px;
  border-radius: 10%;
  background-color: white;
}

button.text-lg {
  font-size: 20px; /* Increase button size */
}
.day-names-row {
  margin-bottom: 0.5rem;
}
.day-name-cell {
  height: 24px;
  font-size: 0.875rem; /* Adjust the font size as needed */
}

.date-container {
  width: 150px; /* Adjust width as needed */
}
.info-btn {
  background: none;
  border: none;
  color: #3182ce;
  cursor: pointer;
}
.dot-indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.dot-blue {
  background-color: #3182ce; /* Blue for posts */
}
.dot-orange {
  background-color: #ffa500; /* Orange for holidays */
}
.sidebar {
  width: 300px;
  position: relative; /* Ensure sidebar is positioned next to the calendar */
  flex-shrink: 0; /* Prevent sidebar from shrinking */
}
.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .lg\:grid-rows-6 {
    grid-template-rows: repeat(
      3,
      minmax(0, 1fr)
    ); /* Adjusted to create 6 rows */
  }
}
.sidebar {
  width: 300px;
  position: relative; /* Ensure sidebar is positioned next to the calendar */
  flex-shrink: 0; /* Prevent sidebar from shrinking */
}
.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .lg\:grid-rows-6 {
    grid-template-rows: repeat(
      3,
      minmax(0, 1fr)
    ); /* Adjusted to create 6 rows */
  }
}
.custom-hover-gradient {
  transition: background 0.3s ease;
}
.custom-hover-gradient:hover {
  background: linear-gradient(to top left, #aea3a3, #f8fafc);
}
.date-passed {
  background-color: #fafcff; /* Gray background color */
  color: #9e9e9e; /* Gray text color */
}
.platform-logo {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  object-fit: cover;
}

.post-image {
  width: 100%; /* Ensure it takes up the full container width */
  height: auto; /* Maintain aspect ratio */
  max-height: 100px; /* Limit image height */
}
.sidebar {
  position: absolute; /* Position sidebar over the calendar */
  top: 0.5; /* Align to the top of the relative container */
  right: 0; /* Align to the right side of the container */
  width: 300px; /* Fixed width for the sidebar */
  height: 50rem; /* Maximum height of the sidebar */
  overflow-y: auto; /* Enable scrolling inside the sidebar */
  z-index: 1000; /* High z-index to ensure it appears above other content */
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1); /* Optional: Adds left shadow for depth */
  background-color: #fff; /* Background color for visibility */
  transition: transform 0.3s ease-in-out; /* Smooth transition for toggling visibility */
  transform: translateX(100%); /* Start offscreen */
  padding: 10px;
}
.platform-logo img {
  display: inline-block;
}
img {
  max-height: 20rem;
  object-fit: contain;
}
.break-all {
  word-break: break-all;
}
.onboarding-popup {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 300px;
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: white;
}
.onboarding-popup h2 {
  margin-top: 0;
  color: white;
}
.checkbox-item {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #565454;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #333;
  color: white;
}
.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
}
.progress-text {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}
.progress-bar {
  width: 100%;
  background-color: #555;
  border-radius: 5px;
  margin-top: 5px;
  height: 10px;
}
.progress-bar-fill {
  height: 100%;
  background-color: #8056de;
  width: 0%;
  border-radius: 5px;
}
.custom-checkbox {
  display: none;
}
.custom-checkbox + label {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}
.custom-checkbox + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: white;
}
.custom-checkbox + label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4caf50;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}
.custom-checkbox:checked + label:after {
  transform: scale(1);
}
.dismiss-button {
  display: block;
  width: 100%;
  padding: 5px 20px;
  margin-top: 1px;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}
.dismiss-button:hover {
  background-color: #8056de;
}
.need-help-link {
  color: white;
  text-decoration: none;
}
.need-help-link:hover {
  text-decoration: underline;
}
.onboarding-link {
  color: white !important;
  text-decoration: none !important;
}
.onboarding-link:hover {
  text-decoration: underline !important;
}
.strikethrough {
  text-decoration: line-through;
  color: #ccc;
}
.icon-color {
  color: white;
}
.video-container {
  max-height: 400px; /* Adjust the maximum height as needed */
  width: 100%;
  object-fit: contain; /* Ensures the video fits within the container without cropping */
}

/* Additional styles to maintain aspect ratio */
.video-container::before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  position: relative;
}
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* Additional styles to maintain aspect ratio */
.video-container::before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  position: relative;
}
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.half-img {
  width: 50%; /* Each image takes up half the width */
  height: 100%;
  object-fit: cover; /* Ensure the image covers the half without distortion */
  position: absolute; /* Position the images absolutely within their container */
  top: 0; /* Align the top edge */
}

.half-img:first-child {
  left: 0; /* Align the first image to the left */
  border-top-left-radius: 50%; /* Round the left corners */
  border-bottom-left-radius: 50%;
}

.half-img:last-child {
  right: 0; /* Align the second image to the right */
  border-top-right-radius: 50%; /* Round the right corners */
  border-bottom-right-radius: 50%;
}

.platform-logo {
  display: flex;
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  object-fit: cover;
}

.flex-container {
  display: flex;
  width: 48px; /* Total width of the combined image container */
  height: 24px; /* Height of the combined image container */
  position: relative; /* Ensure the images can be absolutely positioned */
}

.post-container {
  border: 1px solid #ccc; /* Change color as needed */
  border-radius: 10px; /* Adjust radius as needed for desired roundness */
  padding: 2px; /* Add padding to prevent content from touching the edges */
  margin-bottom: 2px; /* Add some space between posts */
  background-color: white; /* Optional: Change background color */
}

.plusbtn {
  border: 1px solid gray;
  padding: 1px 8px;
  border-radius: 10%;
  background-color: white;
}
.post-card-container {
  max-width: 800px; /* Adjust the width as needed */
  width: 100%; /* Ensure full width */
  margin: 0 auto; /* Center the container */
  margin-top:66px;
}

.md\:w-full {
  width: 100%;
}

@media (max-width: 768px) {
  .date-week {
    height: 150px; /* Adjusted height for small screens */
  }
}
.skeleton-loader .skeleton-post-container {
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-loader .skeleton-platform-logo,
.skeleton-loader .skeleton-text {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}



</style>
