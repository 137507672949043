<template>
  <div v-if="!isActiveDomain && !item.connection">
    <div class="mt-4 max-md:max-w-full">
      <div class="gap max-md:flex-col max-md:gap-0">
        <div class="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
          <div
            class="flex-col grow pt-5 pb-2 mx-auto bg-white rounded-2xl max-md:mt-3.5 p-1"
          >
            <div
              class="flex gap-5 justify-between self-center max-w-full w-[334px]"
            >
              <div
                class="flex gap-1 justify-between items-center text-sm font-medium whitespace-nowrap text-neutral-900"
              >
                <img
                  loading="lazy"
                  :src="item.logo_url"
                  :alt="item.name"
                  class=" aspect-square w-[33px]"
                />
                <!-- <img
                  loading="lazy"
                  src="@/assets/img/right.svg"
                  class="self-stretch my-auto w-2.5 aspect-square fill-lime-600"
                /> -->
                <div class="grow self-stretch my-auto">{{ item.name }}</div>
              </div>
              <div class="flex gap-2 justify-end">
                <template>
                  <connection-info-dialog :item="item" :profile="profile" />
                </template>
              </div>
            </div>
            <div class="shrink-0 mt-4 h-px bg-slate-100"></div>
            <div
              class="flex flex-col mt-5 ml-5 max-w-full font-medium whitespace-nowrap w-[154px] max-md:ml-2.5"
            >
              <div class="flex gap-1.5 justify-between text-xs text-violet-500">
                <div class="grow">{{ platform }}</div>
              </div>
              <div class="mt-1.5 text-xs text-slate-500" v-if="expiryDate">
                Connection Expires on {{ expiryDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { auth } from "@/utils/firebase.utils";
import ConnectionInfoDialog from "./ConnectionInfoDialog.vue";
import ToolTip from "../ui/ToolTip.vue";
import "hopscotch/dist/css/hopscotch.min.css"; // Import Hopscotch CSS
import hopscotch from "hopscotch";
export default {
  components: { ConnectionInfoDialog, ToolTip },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: String,
      default: "personal",
    },
  },
  data() {
    return {
      showDisconnectModal: false,
      isLoading: false,
      isDisconnectLoading: false,
      expiryDate: "",
      show: false,
    };
  },
  async created() {
    const expiryDate = await this.connectedHandle(this.item.alias, this.WID);
    this.expiryDate = expiryDate;
  },
  computed: {
    isHelpCMS() {
      return this.$route.name == "help-cms";
    },
    platform() {
      //if (this.item.alias == "around") return "Connected";
      if (this.item.connection) return "Reconnect";
      if (this.isActiveDomain) return "Connection required";
      return "Coming Soon";
    },
    isActiveDomain() {
      const currentHostname = window.location.hostname;
      return this.item.active_domains?.some(
        (domain) => new URL(domain).hostname === currentHostname
      );
    },
    connectionName() {
      let name = this.authUser.display_name || auth.currentUser.email;
      if (this.currentUser) {
        name = this.currentUser.display_name || this.currentUser.email;
      }
      //if (this.item.alias == "around") return name;
      if (
        this.item.connection &&
        this.item.connection.name &&
        this.item.connection.name.length > 0
      )
        return this.item.connection.name;
      if (
        this.item.connection &&
        this.item.connection.user_name &&
        this.item.connection.user_name.length > 0
      )
        return this.item.connection.user_name;
      return null;
    },
    isManualPosting() {
      let whitelist = ["Whatsapp"];
      return whitelist.indexOf(this.item.name) != -1;
    },
  },
  mixins: [connectedAppsMixins],
  methods: {
    openVideo({ intro_video }) {
      window.open(intro_video, "_blank");
    },
    showDisconnectConfirmation() {
      this.showDisconnectModal = true;
    },

    // Method to hide the disconnect modal
    hideDisconnectModal() {
      this.showDisconnectModal = false;
    },

    // Method to handle disconnection confirmed
    disconnectConnectionConfirmed() {
      this.disconnectConnection(this.item.alias, this.profile);
      this.hideDisconnectModal();
    },

    // startTour() {
    //   const tour = {
    //     id: "my-awesome",
    //     steps: [
    //       {
    //         title: "Step 1",
    //         content:
    //           "Click on this link to connect to a social handle ,You’d see green connect button next to all supported platforms",
    //         target: ".target-1",
    //         placement: "left",
    //         arrowOffset: 60,
    //         yOffset: -80,
    //       },
    //       {
    //         title: "Step 2",
    //         content: "See a demo of how to connect to the platform here.",
    //         target: ".target-2",
    //         placement: "top",
    //         xOffset: "center",
    //         arrowOffset: "center",
    //       },
    //     ],
    //     onClose: () => {
    //       console.log("Tour completed or closed");
    //     },
    //   };

    //   hopscotch.startTour(tour);
    // },
  },
};
</script>

<style></style>
