<template>
  <!-- <div
    class="lg:w-[400px] xl:w-[560px] py-2 md:py-4 px-2 lg:px-5 relative z-[99] bg-white rounded-3xl"
  >
    <please-wait v-if="loading"></please-wait>
    <div
      v-for="(post, i) in postedList"
      :key="i"
      class="xl:w-[528px] w-full mb-2 md:mb-4 bg-slate-100 py-2 md:py-4 px-2 md:px-4 rounded-xl"
    >
      <div class="flex items-center gap-2 md:gap-4 red--text" v-if="post.error">
        <img :src="post.icon" alt="fb" class="w-[49px] rounded-[4px]" />
        <div>
          <b>Error Publishing:</b>
          <br />
          <div
            v-if="post.res_type && post.res_name"
            class="mb-1 text-capitalize"
          >
            <span class="font-medium">{{ post.res_type }}</span
            >: {{ post.res_name }}
          </div>
          {{
            showErrorDetails ? post.error : post.error.substring(0, 100) + "..."
          }}
          <br />
          <button @click="toggleErrorDetails">
            {{ showErrorDetails ? "Show Less" : "Show More" }}
          </button>
        </div>
      </div>
      <v-list-item-subtitle
        v-if="post.post_status && post.post_status === 'draft'"
        class="flex items-center gap-2"
      >
        <img :src="post.icon" alt="fb" class="w-[49px] rounded-[4px]" />
        Updated at
        {{
          post.updated_at.seconds
            ? formatDate(post.updated_at.seconds * 1000)
            : formatDate(post.updated_at)
        }}
      </v-list-item-subtitle>
      <div v-else>
        <div
          v-if="post.url && post.posted_at"
          class="flex items-center gap-2 md:gap-6"
        >
          <img
            :src="post.icon"
            alt="fb"
            class="w-[49px] rounded-[4px] mt-[14px]"
          />
          <div class="flex items-start justify-between">
            <div>
              <p class="text-sm font-medium capitalize text-neutral-900">
                <span class="text-slate-500 text-[12px] font-normal capitalize">
                  {{ post.res_type }}:
                </span>
                {{ post.res_name }}
              </p>

              <p
                v-if="post.posted_at"
                class="w-[131px] text-slate-500 text-[8px] font-normal"
              >
                <span class="text-slate-500 text-[8px] font-normal capitalize">
                  Posted at:
                </span>
                {{ formatDate(post.posted_at.seconds * 1000) }}
              </p>
              <div class="w-[221px] font-medium text-violet-500 text-xs">
                <div
                  class="cursor-pointer text-decoration-underline"
                  @click="openURL(post.url)"
                >
                  <div class="d-sm-none">
                    {{ post.url ? post.url.substring(0, 25) : "" }}
                  </div>
                  <div class="d-none d-sm-block">
                    {{ post.url ? post.url.substring(0, 32) + "..." : "" }}
                  </div>
                </div>
              </div>
              <div class="flex items-center gap-1.5">
                <p class="w-[39px] text-slate-500 text-[8px] font-normal">
                  Share on:
                </p>
                <div class="flex items-center mt-1 gap-1.5">
                  <v-avatar
                    tile
                    size="16"
                    v-for="(item, i) in shareBtns"
                    :key="i"
                    class="ml-1 cursor-pointer"
                    @click="openLink(item, post.url)"
                  >
                    <v-img :src="item.icon"></v-img>
                  </v-avatar>
                </div>
              </div>
              <div class="flex w-[131px]">
                <div class="flex items-center justify-center w-full mt-2">
                  <div class="flex gap-1">
                    <img
                      src="..//..//assets/img/gallery-icons/view.svg"
                      alt="views"
                    />
                    <span class="text-[#636B8C] font-semibold text-[8px]">
                      {{ getCount(post, "views") }}
                    </span>
                  </div>
                  <div class="flex gap-1">
                    <img
                      src="..//..//assets/img/gallery-icons/like.svg"
                      alt="like"
                    />
                    <span class="text-[#636B8C] font-semibold text-[8px]">
                      {{ getCount(post, "likes") }}
                    </span>
                  </div>
                  <div class="flex gap-1">
                    <img
                      src="..//..//assets/img/gallery-icons/comments.svg"
                      alt="comments"
                    />
                    <span class="text-[#636B8C] font-semibold text-[8px]">
                      {{ getCount(post, "comments") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <v-list-item-subtitle v-if="!scheduled_at">
              Scheduled at {{ formatDate(scheduled_at) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else> Publishing... </v-list-item-subtitle>
          </div>
        </div>
        <div class="p-6 rounded-md cursor-pointer">
          <img
            src="..//..//assets/img/post-icons/copylink.svg"
            alt="copylink"
            @click="copyLink(post.url)"
            class="w-5"
          />
        </div>
      </div>
    </div>
  </div> -->
  <div
    class="flex flex-col items-center justify-center px-4"
    :class="isOutputPage ? 'mt-10' : ''"
  >
    <please-wait v-if="loading"></please-wait>
    <div
      v-for="(post, i) in postedList"
      :key="i"
      class=" w-full relative mb-2 md:mb-4 bg-slate-100 py-2 md:py-4 px-2 md:px-4 rounded-xl hh"
      :loading="!post.url && !post.error && !scheduled_at && !isOlx"
    >
      <v-list-item class="">
        <v-list-item-avatar>
          <img :src="post.icon" alt="fb" class="rounded-[4px]" />
        </v-list-item-avatar>
        <v-list-item-content>
          <div class="flex justify-end">
            <button v-if="post.retry" @click="retryPost(post)">
              <v-icon color="error">mdi mdi-refresh</v-icon>
            </button>
            <div
              v-if="isAdmin && (!isLiveEnv || isLiveEnv) && post.error"
              class="cursor-pointer"
            >
              <v-icon
                class="w-7"
                color="#8056DD"
                size="18"
                @click="tracePost(post)"
                >mdi-file-document-alert-outline</v-icon
              >
            </div>
          </div>
          <div class="red--text" v-if="post.error">
            <b>Error Publishing</b>
            <!-- {{ post }} -->
            <br />
            <div v-if="post.res_type && post.res_name" class="text-capitalize">
              <span class="text-sm font-medium text-neutral-900">{{
                post.res_type
              }}</span
              >: {{ post.res_name }}
            </div>
            <!-- {{
              showErrorDetails
                ? post.error
                : post.error.substring(0, 100) + "..."
            }}
            <br />
            <button @click="toggleErrorDetails">
              {{ showErrorDetails ? "Show Less" : "Show More" }}
            </button> -->
          </div>
          <template v-else>
            <template v-if="post.url && post.posted_at">
              <v-list-item-subtitle>
                <div
                  v-if="post.res_type && post.res_name"
                  class="text-capitalize"
                >
                  <span class="text-sm text-[#636B8C]">{{ post.res_type }}</span
                  >:
                  <span class="text-sm font-medium text-neutral-900">{{
                    post.res_name
                  }}</span>
                </div>
                <span
                  v-if="post.posted_at"
                  class="text-sm font-normal text-slate-500"
                >
                  Posted at:
                  {{ formatDate(post.posted_at.seconds * 1000) }}
                </span>
                <div v-if="post.social === 'olx'" class="primary--text d-flex align-center">
  <div class="text-xs font-normal cursor-pointer">
    <div class="d-sm-none">
      <a href="https://www.olx.in/myads" target="_blank" class="violet-text">https://www.olx.in/myads</a>
    </div>
    <div class="d-none d-sm-block">
      <a href="https://www.olx.in/myads" target="_blank" class="violet-text">https://www.olx.in/myads</a>
    </div>
  </div>
</div>
                <div
                  v-if="post.social != 'olx'"
                  class="primary--text d-flex align-center"
                >
                  <div
                    class="text-xs font-normal cursor-pointer text-violet-500"
                    @mousedown="openURL($event, post.url)" 
                  >
                    <div class="d-sm-none">
                      {{ post.url ? post.url.substring(0, 25) : "" }}
                    </div>
                    <div class="d-none d-sm-block">
                      {{ post.url ? post.url.substring(0, 42) + "..." : "" }}
                    </div>
                  </div>
                  <v-avatar
                    v-if="post.url"
                    size="32"
                    class="absolute bg-white rounded-md top-4 right-4 -mt-4 md:mt-0.5"
                    tile
                    style="border-radius: 10px !important"
                  >
                    <img
                      src="..//..//assets/img/post-icons/copylink.svg"
                      alt="copylink"
                      @click="copyLink(post.url)"
                      class="w-[19px] p-[6px]"
                    />
                  </v-avatar>
                </div>
                <div
                  class="text-sm font-normal d-flex align-center text-slate-500"
                  v-if="post.social != 'olx'"
                >
                  Share on:
                  <v-avatar
                    tile
                    v-for="(item, i) in shareBtns"
                    :key="i"
                    size="16"
                    class="ml-1 cursor-pointer"
                    @click="openLink(item, post.url)"
                  >
                    <v-img :src="item.icon"></v-img>
                  </v-avatar>
                </div>

                <div class="flex w-[131px]" v-if="post.social != 'olx'">
                  <div class="flex items-center justify-center w-full mt-2">
                    <div class="flex items-center gap-1">
                      <img
                        src="..//..//assets/img/gallery-icons/view.svg"
                        alt="views"
                      />
                      <span class="text-[#636B8C] font-semibold text-[14px]">
                        {{ getCount(post, "views") }}
                      </span>
                    </div>
                    <div class="flex items-center gap-1">
                      <img
                        src="..//..//assets/img/gallery-icons/like.svg"
                        alt="like"
                      />
                      <span class="text-[#636B8C] font-semibold text-[14px]">
                        {{ getCount(post, "likes") }}
                      </span>
                    </div>
                    <div class="flex items-center gap-1">
                      <img
                        src="..//..//assets/img/gallery-icons/comments.svg"
                        alt="comments"
                      />
                      <span class="text-[#636B8C] font-semibold text-[14px]">
                        {{ getCount(post, "comments") }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-list-item-subtitle>
            </template>
            <v-list-item-subtitle
              v-else-if="
                scheduled_at &&
                (!post.post_status ||
                  (post.post_status && post.post_status !== 'draft'))
              "
              class="scheduled-text"
            >
              <div>Scheduled at</div>
              <div>
                {{
                  scheduled_at.seconds
                    ? formatDate(scheduled_at.seconds * 1000)
                    : formatDate(scheduled_at)
                }}
              </div>
            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-else-if="post.post_status && post.post_status === 'draft'"
            >
              Updated at
              {{
                post.updated_at.seconds
                  ? formatDate(post.updated_at.seconds * 1000)
                  : formatDate(post.updated_at)
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else> Publishing... </v-list-item-subtitle>
          </template>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import { doc, getDocs, onSnapshot, getDoc } from "firebase/firestore";
import { colUsers, functions, platforms } from "@/utils/firebase.utils";
import PleaseWait from "../ui/PleaseWait.vue";
import { mapDocs } from "@/utils/common.utils";
import { httpsCallable } from "firebase/functions";
import _ from "lodash";
export default {
  components: { PleaseWait },
  props: {
    postId: {
      type: String,
      default: "",
    },
    post: {
      type: Object,
      default: function () {
        return {};
      },
    },
    postUID: {
      type: String,
      default: "",
    },
    postwid: {
      type: String,
      default: "",
    },
    scheduled_at: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showErrorDetails: false,
      statistics: {},
      loading: true,
      isStatisticsLoading: false,
      postedList: [],
      shareBtns: [
        {
          icon: "https://img.icons8.com/water-color/50/whatsapp.png",
          url: "https://api.whatsapp.com/send?text={link}",
        },
        {
          icon: "https://img.icons8.com/color/48/pinterest--v1.png",
          url: "https://pinterest.com/pin/create/button/?url={link}",
        },
        {
          icon: "https://img.icons8.com/color-glass/48/reddit.png",
          url: "https://www.reddit.com/submit?url={link}",
        },
        {
          icon: "https://img.icons8.com/fluency/48/telegram-app.png",
          url: "https://t.me/share/url?url={link}",
        },
      ],
    };
  },
  computed: {
    isOutputPage() {
      return this.$route.name == "publish-output";
    },
    isPosts() {
      return this.$route.name == "fly";
    },
    isOlx() {
      return (
        this.post.social_media?.includes("olx") ||
        this.post.social_media?.some((social) => social.name === "olx")
      );
    },
  },
  methods: {
    async tracePost(post) {
      let vm = this;
      if (this.isAdmin) {
        let uid = vm.postUID || vm.$route.params.uid;
        let id = vm.postId || vm.$route.params.id;
        let wid = vm.postwid || vm.$rout.params.wid || vm.WID;
        let platform = post.social;

        const postDoc = (
          await getDoc(
            doc(colUsers, uid, "fly_workspaces", wid, "social-posts", id)
          )
        ).data();
        // console.log(
        //   "postDoc.output[platform]",
        //   postDoc.output[platform].data[0].retry_payload.trace_id
        // );
        // let trace_id = '0d2375e8-a7c0-47ca-a90d-86e07c6919ff'
        if (
          postDoc &&
          postDoc.output &&
          postDoc.output[platform] &&
          postDoc.output[platform].data[0] &&
          postDoc.output[platform].data[0].retry_payload &&
          postDoc.output[platform].data[0].retry_payload.trace_id
        ) {
          let trace_id =
            postDoc.output[platform].data[0].retry_payload.trace_id;

          let postedAt = postDoc.output[platform].data[0].posted_at.seconds;
          let postedDate = new Date(postedAt * 1000);
          let formattedDate = postedDate.toISOString().split("T")[0];
          // console.log("formattedDate:", formattedDate);

          let url = `https://console.cloud.google.com/logs/query;query=resource.type%20%3D%20%22cloud_run_revision%22%0Aresource.labels.service_name%20%3D%20%22fly-media-publisher%22%0Aresource.labels.location%20%3D%20%22asia-south1%22%0Aseverity%3E%3DDEFAULT%20${trace_id};cursorTimestamp=${formattedDate}T00:00:00.000Z;aroundTime=${formattedDate}T12:00:00.000Z;duration=PT24H?chat=true&project=${process.env.VUE_APP_PROJECT_ID}`;
          window.open(url, "_blank");
        }
      }
    },
    async retryPost(post) {
      try {
        let post_id = this.$route.params.id || this.postId;
        // console.log("hii", this.postId);
        if (post.social && post.social == "google_business_profile") {
          this.$loader.show(`Retrying for Google Business Profile`);
        } else {
          this.$loader.show(`Retrying for ${post.social}...`);
        }
        const postDoc = await getDoc(
          doc(
            colUsers,
            this.currentUID,
            "fly_workspaces",
            this.WID,
            "social-posts",
            post_id
          )
        );
        const postData = postDoc.exists ? postDoc.data() : {};
        console.log("postData", postData);
        if (postData.output[post.social].data[0].retry_payload) {
          const retryPayload =
            postData.output[post.social].data[0].retry_payload;
          // console.log("retry payload", retryPayload);
          // this.publishPost(retryPayload);

          let retryPayloadData = {
            wid: this.WID,
            uid: this.currentUID,
            retry_payload: retryPayload,
          };
          const retrySocialPost = httpsCallable(functions, "retrySocialPost");
          const { postId } = (await retrySocialPost(retryPayloadData)).data;
          console.log("postId", postId);
          // this.$router.replace(`/post/${this.currentUID}/${postId}`);
          // this.postId = postId;
          this.step = 4;
          this.$loader.hide();
        }
      } catch (error) {
        post.error = "Retry failed.";
      } finally {
        post.loading = false;
      }
    },

    toggleErrorDetails() {
      this.showErrorDetails = !this.showErrorDetails;
    },
    // openURL(link) {
    //   if (this.postedList[0]?.url?.startsWith("https")) {
    //     window.open(link, "_blank");
    //   }
    // },
    openURL(event, link) {
    if ((event.button === 1 || event.button === 0) && link.startsWith("https")) { 
      window.open(link, '_blank');
      event.preventDefault(); 
    }
  },
    getCount({ social, id }, type) {
      if (!id) return 0;
      return _.get(this.statistics, `${social}.${id}.${type}`, 0);
    },
    async listenPost() {
      const vm = this;
      vm.loading = true;
      let socialPlatforms = this.$store.getters.flyPlatforms;
      if (socialPlatforms.length == 0) {
        const snap = await getDocs(platforms);
        socialPlatforms = mapDocs(snap.docs);
      }
      let postUID = vm.postUID || vm.$route.params.uid;
      let postId = vm.postId || vm.$route.params.id;
      let postRef = doc(
        colUsers,
        postUID || vm.uid,
        "fly_workspaces",
        vm.WID,
        "social-posts",
        postId
      );
      onSnapshot(postRef, (snap) => {
        if (snap.data()) {
          let postData = snap.data();
          let output = snap.data().output || {};
          let socialMedia = snap.data().social_media || [];
          let list = [];
          for (let social of socialMedia) {
            let socialItem = socialPlatforms.find((i) => i.alias == social);
            let detail = output[social] || {};
            console.log("detail", detail);

            if (social === 'olx') {
              setTimeout(() => {
                const olxPost = vm.postedList.find(p => p.social === 'olx' && !p.url && !p.error);
                if (olxPost) {
                  olxPost.error = "Something went wrong. Please retry.";
                  console.log("Error for time Exceed.")
                  vm.$forceUpdate();
                }
              }, 360000); // 4 minutes
            }  
          // if (this.isOlx) {
            //   list.push({
            //     icon: socialItem.logo_url,
            //     social,
            //     id: "",
            //     post_status: detail.status,
            //     url: social == 'olx' ? 'Undefined': ,
            //     posted_at: detail.data[0]?.posted_at ? detail.data[0]?.posted_at : detail.posted_at ? detail.posted_at : "",
            //     error:
            //       typeof detail.data[0].error === "string"
            //         ? detail.data[0].error.substring(0, 100) + "..."
            //         : JSON.stringify(detail.data[0].error),
            //       retry: detail.data[0].retry_payload?.retry || false,

            //   });
            // } 
             if (detail.data && detail.data.length) {
              detail.data.forEach((i) => {
                // console.log("i", i);
                list.push({
                  url:
                    i.post_link == null ? "Currently unavailable" : i.post_link,
                  icon: socialItem.logo_url,
                  id: i.post_id,
                  posted_at: i.posted_at,
                  social,
                  res_type: i.res_type || "",
                  res_name: i.res_name || "",
                  error:
                    typeof i.error === "string"
                      ? i.error.substring(0, 100) + "..."
                      : JSON.stringify(i.error),
                  retry: i.retry_payload?.retry || false,
                  post_status: postData.post_status || "",
                });
              });
            } else if (
              postData.post_status &&
              postData.post_status === "draft"
            ) {
              list.push({
                icon: socialItem.logo_url,
                created_at: postData.created_at,
                updated_at: postData.updated_at,
                social,
                id: "",
                post_status: postData.post_status,
              });
            } else {
              list.push({
                url: "",
                icon: socialItem.logo_url,
                posted_at: "",
                social,
                id: "",
                error: detail.error_message,
              });
            }
          }
          vm.postedList = list;
          console.log("postlist", this.postedList);
          vm.loading = false;
        }
      });
    },
    openLink(item, link) {
      let url = item.url.replace("{link}", link);
      window.open(url, "_blank");
    },
    copyLink(url) {
      navigator.clipboard.writeText(url);
      this.$snackbar.show("URL Copied.");
    },
    async fetchPostStatistics() {
      const vm = this;
      try {
        vm.isStatisticsLoading = true;
        let fetchPostStatistics = httpsCallable(
          functions,
          "fetchPostStatistics"
        );
        let uid = vm.postUID || vm.$route.params.uid;
        let id = vm.postId || vm.$route.params.id;
        let result = (
          await fetchPostStatistics({ uid, post_id: id, wid: vm.WID })
        ).data;
        if (!result.status) {
          vm.$alert.show(result.message);
        } else {
          vm.statistics = result.result;
        }
        vm.isStatisticsLoading = false;
      } catch (error) {
        vm.isStatisticsLoading = false;
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.listenPost();
    if (this.isOutputPage || this.isPosts) {
      this.fetchPostStatistics();
    }
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .d-flex.align-center {
    display: block;
    margin: 10px 0;
  }
}
@media (max-width: 768px) {
  .v-avatar-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.scheduled-text div {
  display: inline;
}

@media (max-width: 768px) {
  .scheduled-text div {
    display: block;
  }
}
.violet-text {
  color: #7c3aed;
}

@media (min-width: 1280px) {

    .hh{
        width: 100%;
    }
}
</style>
