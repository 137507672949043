<template>
  <div class="child" v-if="item.connection">
    <div>
      <div class="mt-4 max-md:max-w-full">
        <div class="gap max-md:flex-col max-md:gap-0">
          <div class="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <div
              class="flex-col grow pt-5 pb-2 mx-auto bg-white rounded-2xl max-md:mt-3. p-1"
            >
              <div class="flex gap-5 justify-between self-center w-[334px]">
                <div
                  class="flex items-center justify-between gap-1 text-sm font-medium whitespace-nowrap text-neutral-900"
                >
                  <img
                    loading="lazy"
                    :src="item.logo_url"
                    :alt="item.name"
                    class="aspect-square w-[33px]"
                  /><img
                    loading="lazy"
                    src="@/assets/img/right.svg"
                    class="self-stretch my-auto w-2.5 aspect-square fill-lime-600"
                  />
                  <div class="self-stretch my-auto grow">{{ item.name }}</div>
                </div>
                <div class="flex justify-end gap-2">
                  <tool-tip message="Disconnect">
                    <img
                      loading="lazy"
                      src="@/assets/img/disconnect.svg"
                      class="my-auto aspect-square w-[17px] cursor-pointer mt-2"
                      v-if="item.connection"
                      :loading="isDisconnectLoading"
                      @onDisconnect="$emit('onDisconnect')"
                      @click="showDisconnectConfirmation()"
                      color="red"
                    />
                  </tool-tip>
                  <template>
                    <v-btn
                      v-if="!item.connection && item.intro_video"
                      @click="openVideo(item)"
                      class="mr-3"
                      depressed
                    >
                      <v-icon color="red" size="26px" left class="target-2">
                        mdi-youtube
                      </v-icon>
                      Demo
                    </v-btn>

                    <connection-info-dialog :item="item" :profile="profile" />
                  </template>
                </div>
              </div>
              <div class="h-px mt-4 shrink-0 bg-slate-100"></div>
              <div
                class="flex flex-col mt-5 ml-5 max-w-full font-medium whitespace-nowrap w-[154px] max-md:ml-2.5"
              >
                <div
                  class="flex gap-1.5 justify-between text-xs text-violet-500"
                >
                  <div
                    v-if="item.connection?.connection_status == 'in progress'"
                  >
                    Connecting...
                  </div>
                  <div class="grow" v-if="igUsers && (!item.connection?.connection_status || item.connection?.connection_status == 'connected')">Connected As:</div>

                  <div class="grow text-red-500" v-if="!igUsers">
                    Issue connecting
                  </div>
                  <div class="grow" v-else>{{ connectionName }}</div>
                  <!-- <div class="grow" :class="igUsers ? 'text-red-500' : ''">{{ igUsers }} ? {{ connectionName }} : Issue connecting</div> -->
                </div>
                <div class="mt-1.5 text-xs text-slate-500" v-if="expiryDate && !item.connection?.connection_status || item.connection?.connection_status == 'connected'">
                  Connection Expires on {{ expiryDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showDisconnectModal"
      max-width="400px"
      class="w-[387px] h-[175px] bg-white rounded-[20px]"
    >
      <v-card>
        <v-card-title
          ><img
            loading="lazy"
            src="@/assets/img/disconnect.svg"
            class="w-[17px] h-[17px] relative mr-2"
          />
          <span> </span>Disconnect</v-card-title
        >
        <v-card-text>
          <div>Are you sure you want to disconnect?</div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            large
            @click="hideDisconnectModal"
            text
            class="w-[119px] h-12 bg-white rounded-3xl"
            >Cancel</v-btn
          >
          <v-btn
            type="submit"
            large
            :loading="isDisconnectLoading"
            @click="disconnectConnectionConfirmed"
            text
            class="w-[119px] h-12 bg-red-600 rounded-3xl"
            color="white"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { auth } from "@/utils/firebase.utils";
import ConnectionInfoDialog from "./ConnectionInfoDialog.vue";
import ToolTip from "../ui/ToolTip.vue";
import "hopscotch/dist/css/hopscotch.min.css"; // Import Hopscotch CSS
import hopscotch from "hopscotch";
export default {
  components: { ConnectionInfoDialog, ToolTip },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: String,
      default: "personal",
    },
  },
  data() {
    return {
      showDisconnectModal: false,
      isLoading: false,
      isDisconnectLoading: false,
      expiryDate: "",
      show: false,
    };
  },
  async created() {
    const expiryDate = await this.connectedHandle(this.item.alias, this.WID);
    this.expiryDate = expiryDate;
  },
  computed: {
    isHelpCMS() {
      return this.$route.name == "help-cms";
    },
    platform() {
      // if (this.item.alias == "around") return "Connected";
      if (this.item.connection) return "Reconnect";
      if (this.isActiveDomain) return "Connection required";
      return "Coming Soon";
    },
    isActiveDomain() {
      const currentHostname = window.location.hostname;
      return this.item.active_domains?.some(
        (domain) => new URL(domain).hostname === currentHostname
      );
    },
    igUsers() {
      if (this.item.connection.id == "instagram") {
        if (
          this.item.connection.users &&
          (this.item.connection.users.length == 0 ||
            !this.item.connection.users[0])
        ) {
          // console.log('this.item.connect.name:', this.item.connection)
          return false;
        }
        return true;
      }
      return true;
    },
    connectionName() {
      if (this.item.type === "marketplace") {
        if (this.item.connection && this.item.connection.phone_number) {
          return this.item.connection.phone_number;
        }
      }

      let name = this.authUser.display_name || auth.currentUser.email;
      if (this.currentUser) {
        name = this.currentUser.display_name || this.currentUser.email;
      }
      //if (this.item.alias == "around") return name;
      if (
        this.item.connection &&
        this.item.connection.name &&
        this.item.connection.name.length > 0
      )
        return this.item.connection.name;
      if (
        this.item.connection &&
        this.item.connection.user_name &&
        this.item.connection.user_name.length > 0
      )
        return this.item.connection.user_name;
      return null;
    },
    isManualPosting() {
      let whitelist = ["Whatsapp"];
      return whitelist.indexOf(this.item.name) != -1;
    },
  },
  mixins: [connectedAppsMixins],
  methods: {
    showDisconnectConfirmation() {
      this.$mixpanel.track("Disconnect App Initiated");
      this.showDisconnectModal = true;
    },

    // Method to hide the disconnect modal
    hideDisconnectModal() {
      this.showDisconnectModal = false;
    },

    // Method to handle disconnection confirmed
    disconnectConnectionConfirmed() {
      this.$mixpanel.track("Disconnected App Successfully");
      this.disconnectConnection(this.item.alias, this.profile);
      this.hideDisconnectModal();
    },
    openVideo({ intro_video }) {
      window.open(intro_video, "_blank");
    },
  },
};
</script>

<style></style>
