<template>
  <v-container>
    <v-card class="mx-auto" max-width="600">
      <v-card-title class="headline text-center">Invite to Workspace</v-card-title>
      <v-card-text>
        <p><strong>Team Member Name:</strong> {{ addedUser.display_name }}</p>
        <p><strong>Team Member Email:</strong> {{ addedUser.email }}</p>
        <p class="mt-4">Select the workspaces you want to share with <strong>{{ addedUser.display_name }}</strong></p>
        <v-form>
          <v-row class="align-center mb-2" v-for="workspace in workspaces" :key="workspace.id">
            <v-col cols="4">
              <v-checkbox :label="workspace.name" v-model="selectedWorkspaces" :value="workspace.id"></v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-radio-group v-if="selectedWorkspaces.includes(workspace.id)" v-model="roles[workspace.id]"
                class="d-flex align-center" row>
                <v-radio label="Creator" :value="'Creator'"></v-radio>
                <v-radio label="Approver" :value="'Approver'"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="goback()">Back</v-btn>
        <v-btn :disabled="loading" color="primary" @click="shareWorkspaces">Share Workspaces</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="messageDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline">{{ messageTitle }}</v-card-title>
        <v-card-text><strong>{{ messageContent }}</strong></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeMessageDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { doc, getDoc, collection, getDocs, updateDoc, setDoc, arrayUnion } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import {auth, colUsers, db } from "@/utils/firebase.utils";

export default {
  data() {
    return {
      userEmail: this.$route.params.email || "",
      addedUser: {},
      workspaces: [],
      selectedWorkspaces: [],
      roles: {},
      messageDialog: false,
      messageTitle: "",
      messageContent: "",
      loading: false,
      redirect: false,
    };
  },
  async created() {
    await this.fetchAddedUser();
    await this.fetchCurrentUser();
    await this.fetchWorkspaces();
  },
  mounted(){
    if(!this.$store.state._teamMemberAdded){
      this.$snackbar.show("Direct access is prohibited");
      this.$router.push("/team")
    }
  },
  methods: {
    async goback() {
      this.$router.push({ name: 'team' });
    },
    async fetchAddedUser() {
      this.$loader.show();
      const addedUserDoc = await getDoc(doc(colUsers, this.$route.params.uid));
      this.addedUser = addedUserDoc.data();
      // const teamUserDoc = await getDoc(doc(db, "users", auth.currentUser.uid, "team", this.$route.params.uid));
      // if (teamUserDoc.exists()) {
      //   const teamData = teamUserDoc.data();
      //   this.selectedWorkspaces = teamData.workSpaceToBeAdded.workspaceData.map(ws => ws.wid);
      //   this.roles = teamData.workSpaceToBeAdded.workspaceData.reduce((acc, ws) => {
      //     acc[ws.wid] = ws.role;
      //     return acc;
      //   }, {});

      // }
    },
    async fetchCurrentUser() {
      const currentUserId = auth.currentUser.uid;
      const currentUserDoc = await getDoc(doc(colUsers, currentUserId));
      if (currentUserDoc.exists()) {
        this.currentUser = currentUserDoc.data();
      }

    },
    async fetchWorkspaces() {
      const currentUserId = auth.currentUser.uid;
      const workspaceRef = collection(db, "users", currentUserId, "fly_workspaces");
      const workspaceSnapshot = await getDocs(workspaceRef);
      this.workspaces = workspaceSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter(doc => doc.role !== 'Approver' && doc.role !== 'Creator'); // Adjusted filter condition
      this.$loader.hide();
    },
    async shareWorkspaces() {
      this.$loader.show()
      if (this.selectedWorkspaces.length === 0) {
        this.showMessageDialog("Required", "Please select at least one workspace.");
        this.$loader.hide()
        return;
      }

      for (let workspaceId of this.selectedWorkspaces) {
        if (!this.roles[workspaceId]) {
          const workspaceName = this.workspaces.find(w => w.id === workspaceId).name;
          this.showMessageDialog("Required", `Please select a role for ${workspaceName} workspace.`);
          this.$loader.hide()
          return;
        }
      }

      this.loading = true; // Set loading to true

      const teamMemberDocRef = doc(db, "users", auth.currentUser.uid, "team", this.$route.params.uid);
      const sendTeamInvite = httpsCallable(functions, "sendTeamInviteFunction");
      const teamMemberData = this.selectedWorkspaces.map(workspaceId => {
        const workspace = this.workspaces.find(w => w.id === workspaceId);
        return {
          wid: workspaceId,
          name: workspace ? workspace.name : '',
          role: this.roles[workspaceId],
        };
      });
      const workSpaceToBeAdded = {
        status: false,
        workspaceData: teamMemberData
      }



      await setDoc(teamMemberDocRef, { workSpaceToBeAdded }, { merge: true });
      console.log(this.selectedWorkspaces);
      try {
        const payload = {
          uid: this.$route.params.uid,
          wid: this.selectedWorkspaces[0], // Assuming you want to send invite for the first selected workspace
          email: this.addedUser.email,
          sender_id: auth.currentUser.uid,
        }

        const res = await sendTeamInvite(payload);
        const sender_id = payload.sender_id;
        const uid = payload.uid
        console.log(payload, "payload reached")

        this.$loader.hide();
        if (res.data.success) {
          await updateDoc(doc(colUsers, auth.currentUser.uid), {
            users: arrayUnion(this.$route.params.uid),
          });
          this.$pushNotification({
            uid: sender_id,
            wid: this.WID,
            payload: {
              message: "Notification sent to team member",
              title: "Invitation sent",
              token: sender_id,
            }
          });
          this.$pushNotification({
            uid: uid,
            payload: {
              message: "You have been invited to workspace",
              title: "Workspace Invitation",
              token: uid,
              url: `accept-invitation?sender_id=${sender_id}&uid=${uid}`
            }
          })
          this.showMessageDialog("Success", "Notification sent to your team member.", true);
        } else {
          this.showMessageDialog("Failed", "Some internal error occurred", true);
        }
      } catch (error) {
        this.showMessageDialog("Failed", "Unable to add a member", true);
      } finally {
        this.loading = false; // Set loading to false
      }
      this.$loader.hide();

    },
    showMessageDialog(title, message, redirect = false) {
      this.messageTitle = title;
      this.messageContent = message;
      this.messageDialog = true;
      this.redirect = redirect;
    },
    closeMessageDialog() {
      this.messageDialog = false;
      if (this.redirect) {
        this.$router.push({ name: 'team' });
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>